<template>
    <div id="one-screen-stage-member" class="stage-inner-container-padded mt-2">
        <b-row class="mb-2">
            <b-col cols="12">
                
                    <b-button 
                        class="float-right"
                        variant="info"
                        @click="switchToBeta"
                    >
                        Desktop Work Order
                    </b-button>
                
            </b-col>
        </b-row>

        <b-row class="mb-2">
            <b-col cols="12">
                <b-card
                    :header-bg-variant="(technicianSelected) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(technicianSelected) ? 'vai-success-lighter' : 'warning'"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Select A Technician</h6>
                    </template>

                    <tech-select
                        :parentFormID="entryID"
                        :callOnSelect="selectTech"
                    ></tech-select>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <b-card
                    :header-bg-variant="(eventTStampCompleted) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(eventTStampCompleted) ? 'vai-success-lighter' : 'warning'"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Event Date & Time</h6>
                    </template>
                    <b-row>
                        <b-col sm="12" lg="6">
                            <!-- Date Selection -->
                            <date-picker
                                :dataName="'event_date'"
                                :inputLabel="'Event Date'"
                                :required="true"
                                :parentFormID="entryID"
                                @onTouch="fieldTouched"
                                :showTodayButton="true"
                                :disableFutureDates="true"
                            ></date-picker>
                        </b-col>
                        <b-col sm="12" lg="6">
                            <!-- Time Selection -->
                            <time-input
                                :dataName="'event_time'"
                                :inputLabel="'Event Time'"
                                :required="true"
                                :parentFormID="entryID"
                                @onTouch="fieldTouched"
                                :showNowButton="true"
                            ></time-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="readyForDeviceSelection && device.fetchedDevice == false">
            <b-col cols="12">
                <b-card
                    :header-bg-variant="(device.fetchedDevice != false) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(device.fetchedDevice != false) ? 'vai-success-lighter' : 'warning'"
                    header="Device Selection"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Select A Device</h6>
                    </template>

                    <dev-find-edit
                        :callOnSelect="deviceSelected"
                        :showDeviceForm="showDeviceForm"
                    ></dev-find-edit>

                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="readyForDeviceSelection && device.fetchedDevice != false">
            <b-col cols="12">
                <b-card
                    :header-bg-variant="(device.fetchedDevice != false) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(device.fetchedDevice != false) ? 'vai-success-lighter' : 'warning'"
                    header="Device Selection"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Selected Device</h6>
                    </template>
                    <b-row class="mb-1">
                        <b-col cols="12">
                            <b-button @click="clearDeviceSelection" variant="info"><b-icon-arrow-left></b-icon-arrow-left> Change Device Selection</b-button>
                        </b-col>
                    </b-row>
                    <b-row no-gutters>
                        <b-col :class="(device.modelFlagged) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                            <b>Model:</b> {{device.manufacturer + ' ' + device.type + ' ' + device.model}} 
                        </b-col>
                        <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                            <b>Serial:</b> {{device.serial}} 
                        </b-col>
                        <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                            <b>Size:</b> {{device.size}} 
                        </b-col>
                        <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                            <b>Location:</b> {{device.location}} 
                        </b-col>
                        <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                            <b>Protection Type:</b> {{device.protectionType}} 
                        </b-col>
                        <b-col sm="12" md="12" lg="6" xl="6" class="text-left">
                            <b>Hazard Type:</b> {{device.hazardType}} 
                        </b-col>
                        <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                            <b>Hazard Level:</b> {{device.hazardLevel}} 
                        </b-col>
                        <b-col sm="12" md="12" lg="12" xl="12" class="text-left">
                            <b>Notes:</b> {{device.notes}} 
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="device.fetchedDevice != false && eventTStampCompleted">
            <b-col cols="6">
                <b-button
                    class="d-none d-md-block"
                    block
                    size="lg"
                    :variant="(this.tasks.replacement == false && this.replacementSelectionCompleted) ? 'vai-olive' : 'info'"
                    @click="setIsReplacement(false)"
                >
                    No Replacement
                </b-button>
                <b-button
                    class="d-md-none"
                    block
                    size="md"
                    :variant="(this.tasks.replacement == false && this.replacementSelectionCompleted) ? 'vai-olive' : 'info'"
                    @click="setIsReplacement(false)"
                >
                    No Replacement
                </b-button>
            </b-col>
            <b-col cols="6">
                <b-button
                    class="d-none d-md-block"
                    block
                    size="lg"
                    :variant="(this.tasks.replacement && this.replacementSelectionCompleted) ? 'vai-olive' : 'vai-secondary-darker' "
                    @click="setIsReplacement(true)"
                >
                    Replacement
                </b-button>
                <b-button
                    class="d-md-none"
                    block
                    size="md"
                    :variant="(this.tasks.replacement && this.replacementSelectionCompleted) ? 'vai-olive' : 'vai-secondary-darker' "
                    @click="setIsReplacement(true)"
                >
                    Replacement
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="device.fetchedDevice != false && tasks.replacement && eventTStampCompleted">
            <b-col cols="12">
                <!-- Replacment Applet Goes Here -->
                <b-card
                    :header-bg-variant="(tasks.replacement && tasksCompleted.replacement) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(tasks.replacement && tasksCompleted.replacement) ? 'vai-success-lighter' : 'warning'"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Replacement Entry</h6>
                    </template>
                    <b-card-body>
                        <b-row>
                            <b-col sm="12" md="6" class="mb-3">
                                <h3>Overall Photo Before (Recommended)</h3>
                                <camera-capture-or-file-upload
                                    :knownFileID="repValues.overallBefore"
                                    :whenDone="replacementOverallBeforePhotoCompleted"
                                ></camera-capture-or-file-upload>
                            </b-col>
                            <b-col sm="0" md="6" class="mb-3">
                            </b-col>
                            <b-col sm="12" md="6">
                                <!-- Curbstop Condition -->
                                <bool-select
                                    :dataName="'curbstopCondition'"
                                    :inputLabel="'Curbstop Condition'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false}]"
                                    :value="repValues.curbstopCondition"
                                    @onTouch="fieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col cols="12">
                                <!-- Device GPS -->
                                <gps-input
                                    :dataName="'gps'"
                                    :latitudeLabel="'New Device Latitude'"
                                    :longitudeLabel="'New Device Longitude'"
                                    :latitude="device.gps.lat"
                                    :longitude="device.gps.lng"
                                    :required="false"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched" 
                                ></gps-input>
                            </b-col>
                            <b-col sm="12" md="6" class="mb-3">
                                <h3>Close Up Before Replacement (Recommended)</h3>
                                <camera-capture-or-file-upload
                                    :knownFileID="repValues.closeUpBefore"
                                    :whenDone="replacementCloseUpBeforePhotoCompleted"
                                ></camera-capture-or-file-upload>
                            </b-col>
                            <b-col cols="12">
                                <!-- Device Model -->
                                <api-select
                                    :dataName="'modelID'"
                                    :inputLabel="'Model'"
                                    :apiEndpoint="'/device_models/model_choices.json?choices=true'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched" 
                                ></api-select>
                            </b-col>
                            <b-col md="12" lg="6" >
                                <!-- Device Size -->
                                <decimal-2x2-input
                                    :dataName="'size'"
                                    :inputLabel="'Size'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="(device.fetchedDevice) ? device.size : connection.meter_size"
                                    @onTouch="fieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6" v-if="newDeviceRequiresSerial">
                                <!-- Device Serial -->
                                <text-input
                                    :dataName="'a'"
                                    :inputLabel="'Serial'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched"
                                ></text-input>
                            </b-col>
                            <b-col cols="12">
                                <!-- Device Location -->
                                <text-input
                                    :dataName="'location'"
                                    :inputLabel="'Location'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched"
                                ></text-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <!-- Hazard Type -->
                                <api-select
                                    :dataName="'hazardTypeID'"
                                    :inputLabel="'Hazard Type'"
                                    :apiEndpoint="'/choices/hazard_types.json?choices=true'"
                                    :required="false"
                                    :value="(device.fetchedDevice) ? device.hazardTypeID : null"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched" 
                                ></api-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <!-- Hazard Level -->
                                <api-select
                                    :dataName="'hazardLevelID'"
                                    :inputLabel="'Hazard Level'"
                                    :apiEndpoint="'/choices/hazard_levels.json?choices=true'"
                                    :required="false"
                                    :value="(device.fetchedDevice) ? device.hazardLevelID : null"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched" 
                                ></api-select>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <!-- Protection Type -->
                                <api-select
                                    :dataName="'protectionTypeID'"
                                    :inputLabel="'Protection Type'"
                                    :apiEndpoint="'/choices/protection_types.json?choices=true'"
                                    :required="false"
                                    :value="(device.fetchedDevice) ? device.protectionTypeID : null"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched" 
                                ></api-select>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <!-- Grade Type -->
                                <api-select
                                    :dataName="'gradeTypeID'"
                                    :inputLabel="'Grade Types'"
                                    :apiEndpoint="'/choices/grade_types.json?choices=true'"
                                    :required="false"
                                    :value="(device.fetchedDevice) ? device.gradeTypeID : null"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched" 
                                ></api-select>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <!-- Device Elevation -->
                                <bool-select
                                    :dataName="'deviceElevation'"
                                    :inputLabel="'Device Elevation'"
                                    :required="false"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Above Grade', value:'1'},{text: 'Below Grade', value:'0'}]"
                                    :value="repValues.heightAboveGrade"
                                    @onTouch="fieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col cols="12">
                                <!-- Device Notes -->
                                <text-area
                                    :dataName="'notes'"
                                    :inputLabel="'Device Notes'"
                                    :required="false"
                                    :parentFormID="entryID"
                                    @onTouch="fieldTouched"
                                ></text-area>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <!-- Verified Pressure -->
                                <bool-select
                                    :dataName="'verifiedPressure'"
                                    :inputLabel="'Verified Pressure'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :threeState="true"
                                    :options="[{text: 'Yes', value:true},{text: 'No', value:false},{text: 'N/A', value:null}]"
                                    :value="repValues.verifiedPressure"
                                    @onTouch="fieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col sm="12" md="6" class="mb-3">
                                <h3>Photo Before Fill Dirt (Recommended)</h3>
                                <camera-capture-or-file-upload
                                    :knownFileID="repValues.beforeFillDirt"
                                    :whenDone="replacementBeforeFillDirtPhotoCompleted"
                                ></camera-capture-or-file-upload>
                            </b-col>
                        <hr>
                        <b-col sm="12" lg="6">
                            <!-- Replacement Notes -->
                            <passed-select 
                                :dataName="'outletConnectionInfo'"
                                :inputLabel="'Outlet Connection Info'"
                                :options="outletConInfoChoices"
                                :multiSelect="true"
                                :required="true"
                                :parentFormID="entryID"
                                :displayCount="9"
                                :value="[repValues.outletConnectionInfo]"
                                @onTouch="fieldTouched"
                            ></passed-select>
                        </b-col>
                        <b-col cols="12">
                            <!-- Device Notes -->
                            <text-area
                                :dataName="'repNotes'"
                                :inputLabel="'Replacement Notes'"
                                :required="false"
                                :parentFormID="entryID"
                                @onTouch="fieldTouched"
                            ></text-area>
                        </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="device.fetchedDevice != false && replacementSelectionCompleted && deviceIsTestable && eventTStampCompleted">
            <b-col cols="6">
                <b-button
                    class="d-none d-md-block"
                    block
                    size="lg"
                    :variant="(this.tasks.test == false && this.testSelectionCompleted) ? 'vai-olive' : 'info'"
                    @click="setTestPerformed(false)"
                >
                    Not Tested
                </b-button>
                <b-button
                    class="d-md-none"
                    block
                    size="md"
                    :variant="(this.tasks.test == false && this.testSelectionCompleted) ? 'vai-olive' : 'info'"
                    @click="setTestPerformed(false)"
                >
                    Not Tested
                </b-button>
            </b-col>
            <b-col cols="6">
                <b-button
                    class="d-none d-md-block"
                    block
                    size="lg"
                    :variant="(this.tasks.test && this.testSelectionCompleted) ? 'vai-olive' : 'vai-secondary-darker' "
                    @click="setTestPerformed(true)"
                >
                    Test Performed
                </b-button>
                <b-button
                    class="d-md-none"
                    block
                    size="md"
                    :variant="(this.tasks.test && this.testSelectionCompleted) ? 'vai-olive' : 'vai-secondary-darker' "
                    @click="setTestPerformed(true)"
                >
                    Test Performed
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="device.fetchedDevice != false && replacementSelectionCompleted && !deviceIsTestable && eventTStampCompleted">
            <b-col cols="12">
                <b-card>
                    New Device Not Testable
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3" ref="testentry" v-show="device.fetchedDevice != false && tasks.test && eventTStampCompleted && deviceIsTestable">
            <b-col cols="12">
                <!-- Test Applet Goes Here -->
                <b-card
                    :header-bg-variant="(tasks.test && tasksCompleted.test) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(tasks.test && tasksCompleted.test) ? 'vai-success-lighter' : 'warning'"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Test Entry</h6>
                    </template>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12">
                                <span class="h4">Initial Test</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <passed-select 
                                    v-if="formVisible['so_tc_issues']"
                                    :dataName="'so_tc_issues'"
                                    :inputLabel="'SO & TC Issues'"
                                    :options="so_tc_issues_options"
                                    :multiSelect="true"
                                    :required="formRequired['so_tc_issues']"
                                    :value="[null]"
                                    :parentFormID="entryID"
                                    :displayCount="10"
                                    @onTouch="testFieldTouched"
                                ></passed-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isDCRPClass(device) && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'ck1_psid'"
                                    :inputLabel="'Check 1 PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.initial.ck1_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="testValues.initial.ck1_psid != null  && testValues.initial.ck1_psid != '' && testValues.initial.ck1_psid == 0 && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'ck1_leak'"
                                    :inputLabel="'Check 1 Leak'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                    :value="testValues.initial.ck1_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <bool-select
                                    v-if="isRP(device) && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'rv_leak'"
                                    :inputLabel="'RV Leaking'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leak', value:true},{text: 'No Leak', value:false}]"
                                    :value="testValues.initial.rv_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <decimal-2x2-input
                                    v-if="isRP(device) && testValues.initial.rv_leak == false && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'rv_open_at'"
                                    :inputLabel="'RV Opened At'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.initial.rv_open_at"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <bool-select
                                    v-if="isRP(device) && testValues.initial.rv_open_at == 0 && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'rv_did_not_open'"
                                    :inputLabel="'RV Did Not Open?'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'RV Did Not Open', value:true},{text: 'RV Opened', value:false}]"
                                    :value="testValues.initial.rv_did_not_open"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isDCRPClass(device) && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'ck2_psid'"
                                    :inputLabel="'Check 2 PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.initial.ck2_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="testValues.initial.ck2_psid != null && testValues.initial.ck2_psid != '' && testValues.initial.ck2_psid == 0 && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'ck2_leak'"
                                    :inputLabel="'Check 2 Leak'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                    :value="testValues.initial.ck2_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="isPVB(device) && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'pvb_ag_open'"
                                    :inputLabel="'PVB AG Open'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'AG Opened', value:true},{text: 'AG Did Not Open', value:false}]"
                                    :value="testValues.initial.pvb_ag_open"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isPVB(device) && testValues.initial.pvb_ag_open == true && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'pvb_ag_psid'"
                                    :inputLabel="'PVB AG PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.initial.pvb_ag_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isPVB(device) && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'pvb_cv_psid'"
                                    :inputLabel="'PVB CV PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.initial.pvb_cv_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="isPVB(device) && testValues.initial.pvb_cv_psid == 0 && testValues.preliminary.so_tc_issues == null"
                                    :dataName="'pvb_cv_leak'"
                                    :inputLabel="'PVB CV Leak'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leak', value:true},{text: 'CV Closes Tight', value:false}]"
                                    :value="testValues.initial.pvb_cv_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="testValues.preliminary.so_tc_issues == null"
                                    :dataName="'so1_close'"
                                    :inputLabel="'SO1 Close'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :threeState="true"
                                    :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false},{text: 'N/A', value:null}]"
                                    :value="testValues.initial.so1_close"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6" >
                                <bool-select
                                    v-if="testValues.preliminary.so_tc_issues == null"
                                    :dataName="'so2_close'"
                                    :inputLabel="'SO2 Close'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false}]"
                                    :value="testValues.initial.so2_close"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <!-- Initial Test Result Block -->
                            <b-col cols="12" class="mt-3" v-if="testValues.initValid">
                                <b-card
                                    header="Initial Test Result" 
                                    body-text-variant="white"
                                    :body-bg-variant="(initialTestResult.value == 'PASS') ? 'success' : (initialTestResult.value == 'FAIL') ? 'danger': 'warning'"
                                >
                                    <b-card-text>
                                        <em>{{initialTestResult.value}}</em>
                                    </b-card-text>
                                    <template v-slot:footer v-if="initialTestResult.failureMode != null">
                                        <div class="explain-fail">{{initialTestResult.failureMode}}</div>
                                    </template>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3" v-if="initialTestResult.value == 'FAIL'">
                            <b-col cols="12">
                                <span class="h4">Repair Info</span>
                            </b-col>
                        </b-row>
                        <b-row v-if="initialTestResult.value == 'FAIL'">
                            <b-col cols="12">
                                <passed-select 
                                    :dataName="'quick_repair_notes'"
                                    :inputLabel="'Repairs Performed'"
                                    :options="quick_repair_notes_options"
                                    :multiSelect="true"
                                    :required="true"
                                    :value="testValues.quick_repair_notes || []"
                                    :parentFormID="entryID"
                                    :displayCount="14"
                                    @onTouch="testFieldTouched"
                                ></passed-select>
                            </b-col>
                            <b-col cols="12" v-if="enableQRNOther">
                                <text-area
                                    :dataName="'quick_repair_notes_other'"
                                    :inputLabel="'Custom Repair Notes'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    @onTouch="testFieldTouched"
                                ></text-area>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3" v-if="testValues.repair.quick_repair_notes != null && !testValues.repair.no_final">
                            <b-col cols="12">
                                <span class="h4">Final Test</span>
                            </b-col>
                        </b-row>
                        <b-row v-if="testValues.repair.quick_repair_notes != null && !testValues.repair.no_final">
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isDCRPClass(device)"
                                    :dataName="'fin_ck1_psid'"
                                    :inputLabel="'Check 1 PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.final.ck1_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="testValues.final.ck1_psid != null  && testValues.final.ck1_psid != '' && testValues.final.ck1_psid == 0"
                                    :dataName="'fin_ck1_leak'"
                                    :inputLabel="'Check 1 Leak'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                    :value="testValues.final.ck1_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <bool-select
                                    v-if="isRP(device)"
                                    :dataName="'fin_rv_leak'"
                                    :inputLabel="'RV Leaking'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leak', value:true},{text: 'No Leak', value:false}]"
                                    :value="testValues.final.rv_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <decimal-2x2-input
                                    v-if="isRP(device) && testValues.final.rv_leak == false"
                                    :dataName="'fin_rv_open_at'"
                                    :inputLabel="'RV Opened At'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.final.rv_open_at"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col sm="12" md="6" lg="4">
                                <bool-select
                                    v-if="isRP(device) && testValues.final.rv_open_at == 0"
                                    :dataName="'fin_rv_did_not_open'"
                                    :inputLabel="'RV Did Not Open?'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'RV Did Not Open', value:true},{text: 'RV Opened', value:false}]"
                                    :value="testValues.final.rv_did_not_open"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isDCRPClass(device)"
                                    :dataName="'fin_ck2_psid'"
                                    :inputLabel="'Check 2 PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.final.ck2_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="testValues.final.ck2_psid != null && testValues.final.ck2_psid != '' && testValues.final.ck2_psid == 0"
                                    :dataName="'fin_ck2_leak'"
                                    :inputLabel="'Check 2 Leak'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leaked', value:true},{text: 'Closed Tight', value:false}]"
                                    :value="testValues.final.ck2_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="isPVB(device)"
                                    :dataName="'fin_pvb_ag_open'"
                                    :inputLabel="'PVB AG Open'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'AG Opened', value:true},{text: 'AG Did Not Open', value:false}]"
                                    :value="testValues.final.pvb_ag_open"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isPVB(device) && testValues.final.pvb_ag_open == true"
                                    :dataName="'fin_pvb_ag_psid'"
                                    :inputLabel="'PVB AG PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.final.pvb_ag_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <decimal-2x2-input
                                    v-if="isPVB(device)"
                                    :dataName="'fin_pvb_cv_psid'"
                                    :inputLabel="'PVB CV PSID'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :value="testValues.final.pvb_cv_psid"
                                    @onTouch="testFieldTouched"
                                ></decimal-2x2-input>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    v-if="isPVB(device) && testValues.final.pvb_cv_psid == 0"
                                    :dataName="'fin_pvb_cv_leak'"
                                    :inputLabel="'PVB CV Leak'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Leak', value:true},{text: 'CV Closes Tight', value:false}]"
                                    :value="testValues.final.pvb_cv_leak"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <!-- TODO MAKE CALCULATED -->
                                <bool-select
                                    :dataName="'fin_so1_close'"
                                    :inputLabel="'SO1 Close'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :threeState="true"
                                    :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false},{text: 'N/A', value:null}]"
                                    :value="testValues.final.so1_close"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    :dataName="'fin_so2_close'"
                                    :inputLabel="'SO2 Close'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false}]"
                                    :value="testValues.final.so2_close"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                            <!-- Final Test Result Block -->
                            <b-col cols="12" v-if="testValues.finValid">
                                <b-card
                                    header="Final Test Result" 
                                    body-text-variant="white"
                                    :body-bg-variant="(finalTestResult.value == 'PASS' || finalTestResult.value == 'REPAIRED & PASSED') ? 'success' : (finalTestResult.value == 'FAIL') ? 'danger': 'warning'"
                                >
                                    <b-card-text>
                                        <em>{{finalTestResult.value}}</em>
                                    </b-card-text>
                                    <template v-slot:footer v-if="finalTestResult.failureMode != null">
                                        <div class="explain-fail">{{finalTestResult.failureMode}}</div>
                                    </template>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3" v-if="device.fetchedModel">
                            <b-col cols="12">
                                <span class="h4">Test Notes</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <passed-select 
                                    :dataName="'test_quick_notes'"
                                    :inputLabel="'Test Quick Notes'"
                                    :options="equipment_test_notes_options"
                                    :multiSelect="true"
                                    :required="false"
                                    :parentFormID="entryID"
                                    :displayCount="18"
                                    :value="testValues.addtl.test_quick_notes"
                                    @onTouch="testFieldTouched"
                                ></passed-select>
                            </b-col>
                            <b-col cols="12">
                                <text-area
                                    :dataName="'test_notes_other'"
                                    :inputLabel="'Custom Test Notes'"
                                    :required="false"
                                    :parentFormID="entryID"
                                    :value="testValues.addtl.test_notes_other"
                                    @onTouch="testFieldTouched"
                                ></text-area>
                            </b-col>
                            <b-col md="12" lg="6">
                                <bool-select
                                    :dataName="'verified_downstream_pressure'"
                                    :inputLabel="'Verified Downstream Pressure'"
                                    :required="true"
                                    :parentFormID="entryID"
                                    :threeState="true"
                                    :options="[{text: 'Yes', value:true},{text: 'No', value:false},{text: 'N/A', value:null}]"
                                    :size="'lg'"
                                    :value="testValues.addtl.verified_downstream_pressure"
                                    @onTouch="testFieldTouched"
                                ></bool-select>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-if="readyToPhotoAndSign">
            <b-col lg="6" cols="12" class="mt-3">
                <b-card
                    :header-bg-variant="(bfpaPhoto != null) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(bfpaPhoto != null) ? 'vai-success-lighter' : 'warning'"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Photo of BFPA (Required)</h6>
                    </template>
                    <b-row no-gutters>
                        <camera-capture-or-file-upload
                            :knownFileID="bfpaPhoto"
                            :whenDone="photoCompleted"
                        ></camera-capture-or-file-upload>
                    </b-row>
                </b-card>
            </b-col>
            <b-col lg="6" cols="12" class="mt-3">
                <b-card
                    :header-bg-variant="(techSignature != null) ? 'vai-success-lighter' : 'warning'"
                    :border-variant="(techSignature != null) ? 'vai-success-lighter' : 'warning'"
                >
                    <template #header>
                        <h6 class="mb-0 font-weight-bold">Technician Signature (Required)</h6>
                    </template>
                    <b-row no-gutters>
                        <signature-capture
                            :knownFileID="techSignature"
                            :whenDone="signatureCompleted"
                        ></signature-capture>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3 mb-5" v-show="readyToSubmit">
            <b-col cols="12">
                <b-button
                    block
                    variant="success"
                    size="lg"
                    @click="packAndSubmitWorkOrder"
                    :disabled="waiting"
                >
                    Submit Work Order
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-3">

        </b-row>
        <b-modal
            id="utility-recipient-selection"
            title="Submit This Work Order To A Utility"
            size="lg"
        >
            <div
                v-if="portalSubmitProcessing"
            >
                <div class="fixed-top">
                    <div class="float-right">
                        <i class="material-icons text-vai-danger-dark bg-vai-secondary-light" @click="closeModal">close</i>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-4">
                    <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                    <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                    <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                </div>
                <div class="d-flex justify-content-center h3 mt-4">
                    <span>Submitting</span>
                </div>
            </div>
            <b-form-group
                v-if="!portalSubmitProcessing"
                id="portal-recipient-select-label"
                description="Your Work Order Will Be Submitted To This Utility"
                label="Select A Utility"
                label-for="portal-recipient-select"
            >
                <b-form-select id="portal-recipient-select" :options="portalRecipients" v-model="selectedPortalID"></b-form-select>
            </b-form-group>

            <template #modal-footer>
                <b-button
                    v-if="!portalSubmitProcessing"
                    class="mr-3"
                    variant="warning"
                    @click="dontSubmitPortal"
                >
                Don't Submit Via Portal
                </b-button>

                <b-button
                    v-if="!portalSubmitProcessing"
                    :disabled="!portalRecipientOK"
                    variant="success"
                    @click="submitToPortal"
                >
                Submit Via Portal
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;

import findAndEditDevice from '@/components/subcomponents/SearchDeviceLarge.vue';
import techSelection from '@/components/subcomponents/TechnicianSelection.vue'
import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import FormArrayInput from '@/components/FormArrayEngine.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'
import NestedForm from '@/components/NestedChildrenForms.vue'
import EventList from '@/components/FormEventListHistory.vue'
import DateInput from '@/components/FormDateInput.vue'
import TimeInput from '@/components/FormTimeInput.vue'
import DatePicker from '@/components/FormDatePickerInput.vue'
import sigCapture from '@/components/subcomponents/SignatureCapture.vue'
import photoCapture from '@/components/subcomponents/PhotoCapture.vue'

export default {
    name: 'woentry',
    components:{
        'dev-find-edit': findAndEditDevice,
        'tech-select': techSelection,
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'date-input': DateInput,
        'time-input': TimeInput,
        'date-picker': DatePicker,
        'signature-capture': sigCapture,
        'camera-capture-or-file-upload': photoCapture
    },
    props:{
        deviceID:{
            type: String,
            default: () => { return null; }
        }
    },
    data(){
        return{
            waiting: false,
            portalRecipients: [],
            rawUtilityList: [],
            selectedPortalID: null,
            submittingToPortal: false,
            workOrderEntryResult: null,
            portalSubmitProcessing: false,
            entryID: null, 
            technicianSelected: false,
            selected: null,
            showDeviceForm: false,
            replacementSelectionCompleted: false,
            testSelectionCompleted: false,
            tasks:{
                replacement: false,
                test: false
            },
            tasksCompleted:{
                replacement: false,
                test: false,
            },
            formDef:{
                meta: {
                    name: 'Add Work Order',
                    title: 'Add Work Order',
                    formParentKey: null
                },
                fields: {}
            },
            eventDate: null,
            eventTime: null,
            eventTStampCompleted: false,
            newDeviceRequiresSerial: true,
            technician: {
                id: null,
                email: [],
                phone: [],
                first: null,
                last: null,
                testKit: {},
                certifications: {},
                certs: {
                    backflow_tester: {
                        num: null,
                        date: null
                    }
                },
            },
            connection: {
                fetched: false,
                location_id: null,
                utility_account_number: null,
                utility: null,
                meter_number: null,
                route: null,
                service_type: null,
                meter_size: null,
                amr_number: null
            },
            device: {
                id: null,
                connectionID: null,
                fetchedDevice: false,
                fetchedModel: false,
                modelFlagged: false,
                disabled: false,
                inactive: null,
                modelID: null,
                manufacturer: null,
                type: null,
                model: null,
                serial: null,
                size: null,
                gps:{
                    lat: null,
                    lng: null,
                    acc: null
                },
                location: null,
                notes: null,
                hazardType: null,
                hazardTypeID: null,
                hazardLevel: null,
                hazardLevelID: null,
                protectionType: null,
                protectionTypeID: null,
                gradeType: null,
                gradeTypeID: null,
                specialFlag: null,
                scheduleCode: null,
                connectionID: null,
                condition: [null],
                conditionNotes: null,
                assemblyID: null
            },
            repValues: {
                overallBefore: null,
                overallBeforeTakenInField: false,
                closeUpBefore: null,
                closeUpBeforeTakenInField: false,
                beforeFillDirt: null,
                beforeFillDirtTakenInField: false,
                meterReadingApplicable: true,
                meterReading: null,
                curbstopCondition: null,
                modelID: null,
                manufacturer: null,
                type: null,
                model: null,
                serial: null,
                size: null,
                gps:{
                    lat: null,
                    lng: null,
                    acc: null,
                    timestamp: null
                },
                specialFlag: null,
                location: null,
                notes: null,
                hazardTypeID: null,
                hazardLevelID: null,
                protectionTypeID: null,
                gradeTypeID: null,
                scheduleCode: null,
                deviceElevation: null,
                connectionID: null,
                outletConnectionInfo: null,
                repNotes: null,
                verifiedPressure: null
            },
            allReplacementFields: ['curbstopCondition','modelID','size','serial','location','hazardTypeID','hazardLevelID','protectionTypeID','gradeTypeID','notes','deviceElevation','verifiedPressure','outletConnectionInfo','repNotes'],
            nonTestableDeviceTypes: ['Dual Check', 'NONE', 'UNKNOWN', 'Single Check', 'SDC', 'AVB'],
            outletConInfoChoices: [
                {value: 'PVC', text: 'PVC'},
                {value: 'Copper', text: 'Copper'},
                {value: 'Steel', text: 'Steel'},
                {value: 'PEX', text: 'PEX'},
                {value: 'On re-setter', text: 'On re-setter'},
                {value: 'Reused existing adapter', text: 'Reused existing adapter'},
                {value: 'Glue work done', text: 'Glue work done'},
                {value: 'Compression fitting used', text: 'Compression fitting used'},
            ],
            // Test Fields
            enableQRNOther: false,
            testValues: {
                initValid: false,
                finValid: false,
                preliminary:{
                    so_tc_issues: null
                },
                initial: {
                    ck1_psid: null,
                    ck1_leak: null,
                    ck2_psid: null,
                    ck2_leak: null,
                    rv_leak: null,
                    rv_open_at: null,
                    rv_did_not_open: null,
                    pvb_ag_open: null,
                    pvb_ag_psid: null,
                    pvb_cv_psid: null,
                    pvb_cv_leak: null,
                    so1_close: null,
                    so2_close: null
                },
                repair: {
                    quick_repair_notes: null,
                    quick_repair_notes_other: null,
                    no_final: false
                },
                final:{
                    ck1_psid: null,
                    ck1_leak: null,
                    ck2_psid: null,
                    ck2_leak: null,
                    rv_leak: null,
                    rv_open_at: null,
                    rv_did_not_open: null,
                    pvb_ag_open: null,
                    pvb_ag_psid: null,
                    pvb_cv_psid: null,
                    pvb_cv_leak: null,
                    so1_close: null,
                    so2_close: null
                },
                addtl:{
                    test_quick_notes: null,
                    test_notes_other: null,
                    verified_downstream_pressure: null
                },
            },
            techSignature: null,
            bfpaPhoto: null,
            bfpaPhotoTakenInField: false,
            gps:{
                lat: null,
                lng: null,
                dop: null,
            },
            // Specific To The Test Entry
            formRequired: {
                so_tc_issues: false,
            },
            formVisible: {
                so_tc_issues: true,
            },
            so_tc_issues_options: [
                {value: 'Bad SO1 Shaft', text: 'Bad SO1 Shaft'},
                {value: 'Bad SO2 Shaft', text: 'Bad SO2 Shaft'},
                {value: 'Bad TC 1', text: 'Bad TC 1'},
                {value: 'Bad TC 2', text: 'Bad TC 2'},
                {value: 'Bad TC 3', text: 'Bad TC 3'},
                {value: 'Bad TC 4', text: 'Bad TC 4'},
                {value: 'PVB Air Gap leaking Upon Arrival', text: 'Air Gap leaking Upon Arrival.'},
                {value: 'RV Leaking', text: 'RV Leaking'},
                {value: 'Previously Failed', text: 'Previously Failed'}
            ],
            quick_repair_notes_options: [
                {value: 'Cleaned', text: 'Cleaned'},
                {value: 'Replaced Disk & O-ring', text: 'Replaced Disk & O-ring'},
                {value: 'Replaced AG Disc Assy', text: 'Replaced AG Disc Assy'},
                {value: 'Replaced TC1', text: 'Replaced TC1'},
                {value: 'Replaced TC2', text: 'Replaced TC2'},
                {value: 'Replaced TC3', text: 'Replaced TC3'},
                {value: 'Replaced TC4', text: 'Replaced TC4'},
                {value: 'Replaced SO Handle(s)', text: 'Replaced SO Handle(s)'},
                {value: 'Replaced RV Rubber Parts', text: 'Replaced RV Rubber Parts'},
                {value: 'Replaced Ck1 Ck2 SO1 and SO2 Packing and Bolts', text: 'Replaced Ck1 Ck2 SO1 and SO2 Packing and Bolts'},
                {value: 'Large BFPA - Repairs done as separate Job', text: 'Large BFPA - Repairs done as separate Job'},
                {value: 'BFPA needs to be Replaced', text: 'BFPA needs to be Replaced'},
                {value: 'Other', text: 'Other (Custom Note)'},
            ],
            equipment_test_notes_options: [
                {value: 'FBY', text: 'FBY'},
                {value: 'NOH', text: 'NOH'},
                {value: 'TTS', text: 'TTS'},
                {value: 'IINS', text: 'Imp Inst Not Serviceable'},
                {value: 'IIOMB', text: 'Imp Inst Outside Meter Box'},
                {value: 'IISP', text: 'Imp Inst Sweated Pipe'},
                {value: 'Storm Debris', text: 'Storm Debris'},
                {value: 'No Union(s)', text: 'No Union(s)'},
                {value: 'IIPVB', text: 'Imp Inst PVC'},
                {value: 'Damaged MB', text: 'Damaged MB'},
                {value: 'No SO1 handle', text: 'No SO1 handle'},
                {value: 'No SO2 handle', text: 'No SO2 handle'},
                {value: 'SO1 CUA', text: 'SO1 Closed On Arrival'},
                {value: 'SO2 CUA', text: 'SO2 Closed On Arrival'},
                {value: 'SO1 Open', text: 'SO1 Open'},
                {value: 'SO2 Open', text: 'SO2 Open'},
                {value: 'Tagged', text: 'Tagged'},
            ],
            initialTestFields: ['ck1_psid', 'ck1_leak', 'ck2_psid', 'ck2_leak', 'rv_open_at', 'rv_did_not_open', 'rv_leak', 'pvb_ag_open', 'pvb_ag_psid', 'pvb_cv_psid', 'pvb_cv_leak', 'so1_close', 'so2_close'],
            finalTestFields: ['quick_repair_notes', 'quick_repair_notes_other', 'fin_ck1_psid', 'fin_ck1_leak', 'fin_ck2_psid', 'fin_ck2_leak', 'fin_rv_open_at', 'fin_rv_did_not_open', 'fin_rv_leak', 'fin_pvb_ag_open', 'fin_pvb_ag_psid', 'fin_pvb_cv_psid', 'fin_pvb_cv_leak', 'fin_so1_close', 'fin_so2_close'],
            classDCRPClassTypes: ['DC','DCDA','DDC','RP','RPDA'],
            classDCTypes: ['DC','DCDA','DDC'],
            classRPTypes: ['RP','RPDA'],
            classPVBTypes: ['AVB','PVB'],
            classSingleTypes: ['SDC', 'Single Check'],
            visibleForDCRPClass: ['ck1_psid', 'ck1_leak', 'ck2_psid', 'ck2_leak'],
            visibleForRP: ['ck1_psid', 'ck1_leak', 'ck2_psid', 'ck2_leak', 'rv_open_at', 'rv_did_not_open', 'rv_leak'],
            visibleForPVB: ['pvb_ag_open', 'pvb_ag_psid', 'pvb_cv_psid', 'pvb_cv_leak'],
            visibleForAll: ['so1_close', 'so2_close'],
            preliminaryTestFields: ['so_tc_issues']
        }
    },
    methods:{
        goto(refName) {
            console.log("Scrolling With GoTo")
            this.$refs[refName].scrollIntoView({behavior: 'smooth'});
        },
        switchToBeta(){
            this.$router.push({path: '/home/workorder/' + this.deviceID });            
        },
        replacementOverallBeforePhotoCompleted(photoFileID, capturedInField){
            this.repValues.overallBefore = photoFileID;
            this.repValues.overallBeforeTakenInField = capturedInField;
        },
        replacementCloseUpBeforePhotoCompleted(photoFileID, capturedInField){
            this.repValues.closeUpBefore = photoFileID;
            this.repValues.closeUpBeforeTakenInField = capturedInField;
        },
        replacementBeforeFillDirtPhotoCompleted(photoFileID, capturedInField){
            this.repValues.beforeFillDirt = photoFileID;
            this.repValues.beforeFillDirtTakenInField = capturedInField;
        },
        startGPSCapture(){
            navigator.geolocation.getCurrentPosition((complete)=>{
                console.log(complete)
                this.gps.lat = complete.latitude;
                this.gps.lng = complete.longitude;
                this.gps.dop = complete.accuracy;
                butils.createToast(this,"Device Location Captured Successfully", "Great Job, these gps coordinates will help locate the device next time.", "info", 2)
            },(err)=>{
                console.log(err);
                this.bfpaPhotoTakenInField = false;
            },{
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 1000
            });
        },
        photoCompleted(photoFileID, capturedInField){
            this.bfpaPhoto = photoFileID;
            this.bfpaPhotoTakenInField = capturedInField;
            if(capturedInField){
                this.startGPSCapture();
            }
        },
        signatureCompleted(signatureFileID){
            this.techSignature = signatureFileID;
        },
        openUtilitySelectionModal(){
            this.$bvModal.show('utility-recipient-selection')
        },
        closeModal(){
            this.$bvModal.hide('utility-recipient-selection')
        },
        clearDeviceSelection(){
            this.selected = null;
            this.device.fetchedDevice = false;
        },
        beginEntry(){
            this.$root.$emit('bv::show::modal', 'modal-wo-entry', '#btnShow')
        },
        deviceSelected(device){
            // Consider that this can also be null, act accordingly
            this.selected = device;
            this.fetchDeviceInformation(device.device_id, true)
        },
        setIsReplacement(isReplacement){
            this.tasks.replacement = isReplacement;
            this.replacementSelectionCompleted = true;
        },
        setTestPerformed(isTest){
            this.tasks.test = isTest;
            this.testSelectionCompleted = true;
            this.goto("testentry");
        },
        selectTech(technicianData){
            this.technicianSelected = true;
            this.technician = technicianData
        },
        fieldTouched(dataName){
            if(dataName == 'gps'){
                var latVal = this.$store.getters.getFormValue({parentFormID: this.entryID, dataName: dataName + "_latitude"});
                var lngVal = this.$store.getters.getFormValue({parentFormID: this.entryID, dataName: dataName + "_longitude"});
                this.repValues.gps.lat = latVal;
                this.repValues.gps.lng = lngVal;
                this.repValues.gps.acc = 10;
                var date = new Date();
                this.repValues.gps.timestamp = date;
            }else{
                var fieldValue = this.$store.getters.getFormValue({parentFormID: this.entryID, dataName: dataName});
                if(this.allReplacementFields.includes(dataName)){
                    // New Device
                    if(dataName == 'modelID'){
                        // Fetch Model
                        this.fetchModelInformation(fieldValue, true);
                        this.repValues[dataName] = fieldValue;
                    }else{
                        this.repValues[dataName] = fieldValue;
                    }
                    this.tasksCompleted.replacement = this.checkReplacementValid();
                }else if(dataName == 'event_date'){
                    this.eventDate = fieldValue;
                    if(this.eventDate != null && this.eventTime != null){
                        this.eventTStampCompleted = true;
                    }
                }else if(dataName == 'event_time'){
                    this.eventTime = fieldValue;
                    if(this.eventDate != null && this.eventTime != null){
                        this.eventTStampCompleted = true;
                    }
                }else{
                    this.repValues[dataName] = fieldValue;
                }
            }
        },
        fetchModelInformation(mdlID, setReplacementModel){
            if(mdlID == null){
                butils.createToast(this, "Unable To Enter BFPA Test", "Device Does Not Have A Valid Model", 'danger');
                return;
            }
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + '/device_models/models/' + mdlID + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var mdl = response.data.result.records[0];
                        if(setReplacementModel){
                            this.repValues.manufacturer = mdl.manufacturer;
                            this.repValues.type = mdl.type;
                            this.repValues.model = mdl.model;
                            if(this.repValues.type != null && !this.nonTestableDeviceTypes.includes(this.repValues.type)){
                                this.newDeviceRequiresSerial = true;
                            }else{
                                this.newDeviceRequiresSerial = false;
                            }
                        }else{
                            this.device.fetchedModel = true;
                            this.device.manufacturer = mdl.manufacturer;
                            this.device.type = mdl.type;
                            this.device.model = mdl.model;
                        }
                    }else{
                        butils.createToast(this, "Failed To Fetch Device Model Information", "Device Model Info Could Not Be Retrieved Or Was Not Retrieved, Verify That This Device Has A Valid Model", 'danger');
                    }
                })
                .catch((error) => {
                    butils.createToast(this, "Failed To Fetch Device Model Information", "Device Model Info Could Not Be Retrieved Or Was Not Retrieved, Verify That This Device Has A Valid Model", 'danger');
                    console.log(error);
                });
        },
        fetchConnectionInformation(conID){
            this.connection.fetched = false;
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + '/connections/' + conID + '.json?dref=true')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var con = response.data.result.records[0];
                        this.connection.id = con.connection_id;
                        this.connection.fetched = true;
                        this.connection.location_id = con.location_id;
                        this.connection.utility_account_number = con.utility_account_number;
                        this.connection.utility = con.utility;
                        this.connection.meter_number = con.meter_number;
                        this.connection.route = con.route;
                        this.connection.service_type = con.service_type;
                        this.connection.meter_size = con.meter_size;
                        this.connection.amr_number = con.amr_number;
                        // Setup Meter Reading Initial State
                        if(this.connection.meter_number == 'NO METER' || this.connection.meter_number == 'Na'){
                            this.repValues.meterReadingApplicable = false;
                        }
                        console.log("Fetch Connection Completed")
                    }else{
                        butils.createToast(this, "Failed To Fetch Connection Info", "Connection Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    }
                })
                .catch((error) => {
                    butils.createToast(this, "Failed To Fetch Connection Info", "Connection Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    console.log(error);
                });
        },
        fetchDeviceInformation(devID, performConnectionUpdate){
            if(devID == null){
                butils.createToast(this, "Unable To Enter BFPA Test, Missing or Malformed Device ID", "Device ID is Null, Check The URL or Link and Attempt Again", 'danger');
                return;
            }
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + '/devices/' + devID + '.json?dref=true')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        this.device.fetchedDevice = true;
                        var dev = response.data.result.records[0];
                        this.device.id = dev.device_id;
                        this.device.connectionID = dev.connection_id;
                        this.device.disabled = dev.disabled;
                        this.device.inactive = dev.inactive;
                        this.device.modelID = dev.device_model_id;
                        this.device.serial = dev.device_serial;
                        this.device.size = dev.device_size;
                        this.device.gps = {
                            lat: dev.device_latitude,
                            lng: dev.device_longitude,
                            acc: dev.device_gps_accuracy
                        };
                        this.device.location = dev.device_location;
                        this.device.notes = dev.device_comments;
                        this.device.hazardType = dev.hazard_type;
                        this.device.hazardTypeID = dev.hazard_type_id;
                        this.device.hazardLevel = dev.hazard_level;
                        this.device.hazardLevelID = dev.hazard_level_id;
                        this.device.protectionType = dev.protection_type;
                        this.device.protectionTypeID = dev.protection_type_id;
                        this.device.gradeType = dev.grade_type;
                        this.device.gradeTypeID = dev.grade_type_id;
                        this.device.specialFlag = dev.special_flag;
                        this.device.scheduleCode = dev.schedule_code;
                        this.device.condition = (dev.condition == null) ? [null] : dev.condition;
                        this.device.conditionNotes = dev.condition_notes;
                        this.device.connectionID = dev.connection_id;
                        this.device.assemblyID = dev.assembly_id;
                        if(this.device.inactive == true){
                            this.formHasError = true;
                            butils.createToast(this, "INACTIVE BFPA!", "This Device is INACTIVE. Must be active to enter test event.", 'danger');
                        }else{
                            if(this.device.modelID == null){
                                this.formHasError = true;
                                this.device.modelFlagged = true;
                                butils.createToast(this, "Previous Device Model is Null", "WARNING: Verify that you are replacing the correct device, it is easy to confuse devices where the device model is unknown.", 'warning');
                            }else{
                                this.fetchModelInformation(this.device.modelID, false);
                            }
                            if(performConnectionUpdate){
                                this.fetchConnectionInformation(this.device.connectionID);
                            }
                        }
                    }else{
                        butils.createToast(this, "Failed To Fetch Device Info", "Device Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    }
                })
                .catch((error) => {
                    butils.createToast(this, "Failed To Fetch Device Info", "Device Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    console.log(error);
                });
        },
        isDCRPClass(){
            var inspecting = null;
            if(this.tasks.replacement){
                inspecting = this.repValues;
            }else{
                inspecting = this.device;
            }
            if(this.classDCRPClassTypes.includes(inspecting.type)){
                return true;
            }else{
                return false;
            }
        },
        isRP(){
            var inspecting = null;
            if(this.tasks.replacement){
                inspecting = this.repValues;
            }else{
                inspecting = this.device;
            }
            if(this.classRPTypes.includes(inspecting.type)){
                return true;
            }else{
                return false;
            }
        },
        isPVB(){
            var inspecting = null;
            if(this.tasks.replacement){
                inspecting = this.repValues;
            }else{
                inspecting = this.device;
            }
            if(this.classPVBTypes.includes(inspecting.type)){
                return true;
            }else{
                return false;
            }
        },
        isDC(){
            var inspecting = null;
            if(this.tasks.replacement){
                inspecting = this.repValues;
            }else{
                inspecting = this.device;
            }
            if(this.classDCTypes.includes(inspecting.type)){
                return true;
            }else{
                return false;
            }
        },
        isSDC(){
            var inspecting = null;
            if(this.tasks.replacement){
                inspecting = this.repValues;
            }else{
                inspecting = this.device;
            }
            if(this.classSingleTypes.includes(inspecting.type)){
                return true;
            }else{
                return false;
            }
        },
        getTestDeviceType(){
            var inspecting = null;
            if(this.tasks.replacement){
                inspecting = this.repValues;
            }else{
                inspecting = this.device;
            }
            return inspecting.type;
        },
        isInitialField(fieldDataName){
            if(this.initialTestFields.includes(fieldDataName)){
                return true;
            }else{
                return false;
            }
        },
        isPreliminaryField(fieldDataName){
            if(this.preliminaryTestFields.includes(fieldDataName)){
                return true;
            }else{
                return false;
            }
        },
        testFieldTouched(dataName){
            var fieldValue = this.$store.getters.getFormValue({parentFormID: this.entryID, dataName: dataName});
            // Update Validity
            if(this.isInitialField(dataName)){
                this.testValues.initial[dataName] = fieldValue;
                this.testValues.initValid = this.initialTestValid();
                this.testCompletionManager();
            }else if(this.isPreliminaryField(dataName)){
                // Treat SO TC Issues Special
                if(dataName == 'so_tc_issues'){
                    if(fieldValue.length == 1 && fieldValue[0] == null){
                        this.testValues.preliminary[dataName] = null;
                        this.testValues.initValid = false;
                        this.SOTCChangesMade(fieldValue);
                    }else{
                        this.testValues.preliminary[dataName] = fieldValue;
                        this.testValues.initValid = true;
                        // Set The Form Values With SOTC In Mind
                        this.SOTCChangesMade(fieldValue)
                    }
                }else{
                    // Everybody Else Is Just Boring
                    this.testValues.preliminary[dataName] = fieldValue;
                }
                this.testCompletionManager();
            }else if(dataName == 'quick_repair_notes'){
                this.testValues.repair.quick_repair_notes = fieldValue;
                if(fieldValue.includes('Large BFPA - Repairs done as separate Job') || fieldValue.includes('BFPA needs to be Replaced')){
                    this.testValues.repair.no_final = true;
                }else{
                    this.testValues.repair.no_final = false;
                }
                if(fieldValue.includes('Other')){
                    this.enableQRNOther = true;
                }else{
                    this.enableQRNOther = false;
                }
                this.testCompletionManager();
            }else if(dataName == 'quick_repair_notes_other'){
                this.testValues.repair.quick_repair_notes_other = fieldValue;
                this.testCompletionManager();
            }else if(dataName == 'test_quick_notes' || dataName == 'test_notes_other' || dataName == 'verified_downstream_pressure'){
                if(dataName == 'test_quick_notes'){
                    if(fieldValue.length == 1 && fieldValue[0] == null){
                        this.testValues.addtl[dataName] = null;
                    }else{
                        this.testValues.addtl[dataName] = fieldValue;
                    }
                }else{
                    this.testValues.addtl[dataName] = fieldValue;
                }
                this.testCompletionManager();
            }else if(dataName.substr(0,3) == 'fin'){
                // Part of Final Test
                this.testValues.final[dataName.split('fin_')[1]] = fieldValue;
                this.testValues.finValid = this.finalTestValid();
                this.testCompletionManager();
            }
            // Use fieldValue to determine the next stage of behavior
        },
        testCompletionManager(){
            if(this.initialTestResult.value == 'FAIL' && !this.testValues.repair.no_final && this.testValues.repair.quick_repair_notes != null){
                this.tasksCompleted.test = (this.initialTestValid() && this.finalTestValid())
            }else{
                this.tasksCompleted.test = this.initialTestValid();
            }
        },
        SOTCChangesMade(value){
            var sotcFailObj = {
                tcCausesCk1Fail: false,
                tcCausesCk2Fail: false,
                tcCausesCvFail: false,
                tcCausesAgFail:false,
                tcCausesRVLeak: false,
                tcPreviouslyFailed: false,
                soShaftCausesSO1Leak: false,
                soShaftCausesSO2Leak: false,
            };
            if(value.includes('Bad TC 1')){
                if(!this.isPVB()){
                    sotcFailObj.tcCausesCk1Fail = true;
                }else{
                    sotcFailObj.tcCausesCvFail = true;
                }
            }
            if(value.includes('Bad TC 2')){
                if(!this.isPVB()){ // Is Not PVB
                    sotcFailObj.tcCausesCk1Fail = true;
                }else{ // Is PVB
                    sotcFailObj.tcCausesAgFail = true;
                }
            }
            if(value.includes('Bad TC 3') || value.includes('Bad TC 4')){
                if(!this.isPVB()){ // Is Not PVB
                    sotcFailObj.tcCausesCk2Fail = true;
                }
            }
            if(value.includes('Bad SO1 Shaft')){
                sotcFailObj.soShaftCausesSO1Leak = true;
            }
            if(value.includes('Bad SO2 Shaft')){
                sotcFailObj.soShaftCausesSO2Leak = true;
            }
            if(value.includes('RV Leaking')){
                sotcFailObj.tcCausesRVLeak = true;
            }
            if(value.includes('PVB Air Gap leaking Upon Arrival')){
                sotcFailObj.tcCausesAgFail = true;
            }
            //Check for Previously Failed
            if(value.includes('Previously Failed')){
                sotcFailObj.tcPreviouslyFailed = true;
            }

            if(sotcFailObj.tcCausesCk1Fail){
                this.testValues.initial.ck1_leak = true;
            }else{
                this.testValues.initial.ck1_leak = null;
            }
            //Check 2
            if(sotcFailObj.tcCausesCk2Fail){
                this.testValues.initial.ck2_leak = true;
            }else{
                this.testValues.initial.ck2_leak = null;
            }
            //PVB CV
            if(sotcFailObj.tcCausesCvFail){
                this.testValues.initial.pvb_cv_leak = true;
            }else{
                this.testValues.initial.pvb_cv_leak = null;
            }
            //PVB AG
            if(sotcFailObj.tcCausesAgFail){
                this.testValues.initial.pvb_ag_open = false;
            }else{
                this.testValues.initial.pvb_ag_open = null;
            }
            //RV
            if(sotcFailObj.tcCausesRVLeak){
                this.testValues.initial.rv_leak = false;
            }else{
                this.testValues.initial.rv_leak = null;
            }
            //Previously Failed
            if(sotcFailObj.tcPreviouslyFailed){
                //Set CK1, CK2, and PVB CV leak.
                this.testValues.initial.ck1_leak = true;
                this.testValues.initial.ck2_leak = true;
                this.testValues.initial.pvb_cv_leak = true;
            }else{
                this.testValues.initial.ck1_leak = null;
                this.testValues.initial.ck2_leak = null;
                this.testValues.initial.pvb_cv_leak = null;
            }
            if(sotcFailObj.soShaftCausesSO1Leak){
                // SO1 Did Not Close Tight
                this.testValues.initial.so1_close = false;
            }else{
                this.testValues.initial.so1_close = null;
            }
            if(sotcFailObj.soShaftCausesSO2Leak){
                // SO2 Did Not Close Tight
                this.testValues.initial.so2_close = false;
            }else{
                this.testValues.initial.so2_close = null;
            }
            
        },
        setSOTCRequired(){
            this.formRequired.so_tc_issues = !this.formRequired.so_tc_issues;
        },
        initialTestValid(){
            //this.$store.getters.getEntireFormValid;
            var x = this.$store.getters.getFormValid({parentFormID: this.entryID, checkKeys: this.initialTestFields});
            // console.log(`Initial Test Valid Returns: ${x}`);
            return x;
        },
        finalTestValid(){
            //this.$store.getters.getEntireFormValid;
            var x = this.$store.getters.getFormValid({parentFormID: this.entryID, checkKeys: this.finalTestFields});
            // console.log(`Final Test Valid Returns: ${x}`);
            return x;
        },
        checkReplacementValid(){
            var x = this.$store.getters.getFormValid({parentFormID: this.entryID, checkKeys: this.allReplacementFields});
            return x;
        },
        openSubmitToUtilityModal(){

        },
        packAndSubmitWorkOrder(){
            
            var packed = {
                tasks: []
            };

            if(this.tasks.replacement){
                packed.tasks.push("Replacement");
                var fVal = this.$store.getters.getForm(this.entryID);
                var serial = null;
                if(fVal.serial != undefined){
                    serial = fVal.serial.value
                }
                var replacement = { 
                    connection_id: this.connection.id,
                    replaces_device: this.device.id,
                    new_device: {
                        device_latitude: fVal.gps_latitude.value,
                        device_longitude: fVal.gps_longitude.value,
                        device_model_id: fVal.modelID.value,
                        device_size: fVal.size.value,
                        device_serial: serial,
                        grade_type: fVal.gradeTypeID.value,
                        height_above_grade: fVal.deviceElevation.value,
                        protection_type: fVal.protectionTypeID.value,
                        device_location: fVal.location.value,
                        device_comments: fVal.notes.value,
                        device_gps_accuracy: this.repValues.gps.acc,
                        device_gps_timestamp: this.repValues.gps.timestamp,
                        hazard_level: fVal.hazardLevelID.value,
                        hazard_type: fVal.hazardTypeID.value,
                    },
                    technician_id: this.technician.id,
                    curbstop_condition: fVal.curbstopCondition.value,
                    outlet_connection_info: fVal.outletConnectionInfo.value,
                    event_notes: fVal.repNotes.value,
                    verified_pressure: fVal.verifiedPressure.value,
                    event_tstamp: this.eventDate + ' ' + this.eventTime,
                    overall_photo_before: this.repValues.overallBefore,
                    close_up_photo_before: this.repValues.closeUpBefore,
                    photo_of_installed_bfpa_before_fill_dirt: this.repValues.beforeFillDirt,
                    overall_photo_after: this.bfpaPhoto,
                };
                packed.replacement = replacement;
            }

            if(this.tasks.test){

                packed.tasks.push("BFPA Test");

                var repairNotes = this.testValues.repair.quick_repair_notes;
                if(repairNotes != null){
                    if(repairNotes.length != 0 && repairNotes[0] != null){
                        if( this.testValues.repair.quick_repair_notes.includes('Other')){
                            repairNotes.push(this.testValues.repair.quick_repair_notes_other)
                        }
                    }
                }

                var notes = this.testValues.addtl.test_quick_notes;
                if(notes != null){
                    if(notes.length != 0 && notes[0] != null){
                        if(this.testValues.addtl.test_notes_other != null){
                            notes.push(this.testValues.addtl.test_notes_other);
                        }
                    }
                }
                
                var test = {
                    device_id: this.device.id,
                    device_latitude: this.gps.lat,
                    device_longitude: this.gps.lng,
                    device_gps_accuracy: this.gps.dop,
                    technician_id: this.technician.id,
                    technician: this.technician.first + ' ' + this.technician.last,
                    cert_date: this.technician.certs.backflow_tester.date,
                    cert_number: this.technician.certs.backflow_tester.num,
                    test_kit_sn: this.technician.testKit.serial,
                    test_kit_cal_date: this.technician.testKit.calDate,
                    so_tc_issues: this.testValues.preliminary.so_tc_issues,
                    ck1_psi: this.testValues.initial.ck1_psid,
                    ck1_leak: this.testValues.initial.ck1_leak,
                    init_cv1_close: (this.testValues.initial.ck1_leak != null) ? !this.testValues.initial.ck1_leak : null,
                    rv_open_at: this.testValues.initial.rv_open_at,
                    rv_did_not_open: this.testValues.initial.rv_did_not_open,
                    ck2_psi: this.testValues.initial.ck2_psid,
                    ck2_leak: this.testValues.initial.ck2_leak,
                    init_cv2_close: (this.testValues.initial.ck2_leak != null) ? !this.testValues.initial.ck2_leak : null,
                    init_pvb_air_open: this.testValues.initial.pvb_ag_open,
                    ini_pvb_ag: this.testValues.initial.pvb_ag_psid,
                    ini_pvb_cv: this.testValues.initial.pvb_cv_psid,
                    pvb_cv_leak: this.testValues.initial.pvb_cv_leak,
                    init_so1_close: this.testValues.initial.so1_close,
                    init_so1_leak: (this.testValues.initial.so1_close != null) ? !this.testValues.initial.so1_close : null,
                    init_so2_close: this.testValues.initial.so2_close,
                    init_so2_leak: (this.testValues.initial.so2_close != null) ? !this.testValues.initial.so2_close : null,
                    initial_test_result: this.initialTestResult.value,
                    quick_repair_notes: repairNotes,
                    final_rpdc_ck1_psid: this.testValues.final.ck1_psid,
                    final_rpdc_ck1_close: (this.testValues.final.ck1_leak != null) ? !this.testValues.final.ck1_leak : null,
                    final_rpdc_cv2_psid: this.testValues.final.ck2_psid,
                    final_rpdc_cv2_close: (this.testValues.final.ck2_leak != null) ? !this.testValues.final.ck2_leak : null,
                    final_rp_rv_psid: this.testValues.final.rv_open_at,
                    fin_pvb_air_open: this.testValues.final.pvb_ag_open,
                    fin_pvb_ag_psid: this.testValues.final.pvb_ag_psid,
                    fin_pvb_cv_psid: this.testValues.final.pvb_cv_psid,
                    fin_pvb_cv_leak: this.testValues.final.pvb_cv_leak,
                    fin_so1_close: this.testValues.final.so1_close,
                    fin_so2_close: this.testValues.final.so2_close,
                    verified_downstream_pressure: this.testValues.addtl.verified_downstream_pressure,
                    event_notes: notes,
                    event_tstamp: this.eventDate + ' ' + this.eventTime,
                    final_test_result: (this.finalTestResult.value == "REPAIRED & PASSED") ? "REPAIRPASS" : this.finalTestResult.value,
                    device_latitude: this.device.gps.lat,
                    device_longitude: this.device.gps.lng,
                    device_gps_accuracy: this.device.gps.acc,
                    photo_of_bfpa: this.bfpaPhoto,
                    technician_signature: this.techSignature,
                    condition: (this.device.condition.length == 1 && this.device.condition[0] == null) ? null : this.device.condition
                };

                packed.bfpaTest = test;
            }
            
            if(!this.waiting){
                this.waiting = true;
                butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/events/workorder`, packed)
                .then((resp)=>{
                    console.log(resp.data.result)
                    this.workOrderEntryResult = resp.data.result;
                    if(!this.$store.getters.getAccountConfigPortalAvailable){
                        // Open B-Modal To Submit To Utility
                        this.openUtilitySelectionModal();
                    }else{
                        this.waiting = false;
                        butils.createToast(this, "Work Order Submitted", "Great Job", "success")
                        this.$router.go(-1);
                    }
                })
                .catch((err)=>{
                    this.waiting = false;
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to submit a work order, please contact your administrator to receive permissions to perform this action.', 'danger');
                    }else{
                        console.log(err)
                        butils.createToast(this, "Failed Durring Work Order Submission", "Something went wrong while submitting this work order, confirm the work order is filled out correctly, if the problem persists contact support.", "danger", 30);
                    }
                })
            }
            
        },
        submitToPortal(){
            this.portalSubmitProcessing = true;
            var packed = {
                eventID: [],
                recipientID: this.selectedPortalID
            }
            var eventKeysForThisWorkOrder = _.keys(this.workOrderEntryResult.eventIDs)
            eventKeysForThisWorkOrder.forEach((key)=>{
                packed.eventID.push(this.workOrderEntryResult.eventIDs[key]);
            })
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/portal/instant/submit`, packed)
            .then((resp)=>{
                this.waiting = false;
                this.portalSubmitProcessing = false;
                this.closeModal();
                butils.createToast(this, "Work Order Completed", "Submitted To Utility", "success");
                this.$router.go(-1);
            })
            .catch((err)=>{
                this.waiting = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to submit via the instant portal, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed Durring Portal Availability List Lookup", "Something went wrong while submitting this work order via instant portal, please contact support", "danger", 30);
                }
                this.portalSubmitProcessing = false;
                // TODO: Handle when submission to utility fails
            })
        },
        dontSubmitPortal(){
            this.waiting = false;
            butils.createToast(this, "Work Order Completed", "Not Submitted To Utility", "success")
            this.$router.go(-1);
        }
    },
    watch:{

    },
    computed:{
        portalRecipientOK: function(){
            if(this.selectedPortalID != null){
                return true;
            }else{
                return false;
            }
        },
        readyToSubmit: function(){
            if(this.tasks.replacement || this.tasks.test){
                if(this.techSignature != null && this.bfpaPhoto != null){
                    if(this.tasks.replacement){
                        if(this.tasks.test){
                            // Replacement & Test
                            if(this.tasksCompleted.replacement && this.tasksCompleted.test){
                                return true;
                            }else{
                                return false;
                            }
                        }else{
                            // Replacement Only
                            if(this.deviceIsTestable){
                                // Then this is not valid
                                return false;
                            }else{
                                // Not Testable, This is Okay If Replacement is Complete
                                if(this.tasksCompleted.replacement){
                                    return true;
                                }else{
                                    return false;
                                }
                            }
                        }
                    }else if(this.tasks.test){
                        // Test
                        if(this.tasksCompleted.test){
                            return true
                        }else{
                            return false
                        }
                    }
                }else{
                    // Awaiting Photo and Signature
                    return false;
                }
            }else{
                // No Tasks Are Selected, Not Ready
                return false;
            }
        },
        readyToPhotoAndSign: function(){
            if(this.tasks.replacement || this.tasks.test){
                if(this.tasks.replacement){
                    if(this.tasks.test){
                        // Replacement & Test
                        if(this.tasksCompleted.replacement && this.tasksCompleted.test){
                            return true;
                        }else{
                            return false;
                        }
                    }else{
                        // Replacement Only
                        if(this.deviceIsTestable){
                            // Then this is not valid
                            return false;
                        }else{
                            // Not Testable, This is Okay If Replacement is Complete
                            if(this.tasksCompleted.replacement){
                                return true;
                            }else{
                                return false;
                            }
                        }
                    }
                }else if(this.tasks.test){
                    // Test
                    if(this.tasksCompleted.test){
                        return true
                    }else{
                        return false
                    }
                }
            }else{
                // No Tasks Are Selected, Not Ready
                return false;
            }
        },
        deviceIsTestable: function(){
            if(this.device.fetchedDevice != true){
                return false;
            }
            if(this.tasks.replacement == true){
                if(this.repValues.type == null || this.nonTestableDeviceTypes.includes(this.repValues.type)){
                    return false;
                }
                return true;
            }else{
                if(this.device.type == null || this.nonTestableDeviceTypes.includes(this.device.type)){
                    return false;
                }
                return true;
            }
        },
        readyForDeviceSelection: function(){
            if(this.eventTStampCompleted && this.technicianSelected){
                return true;
            }else{
                return false;
            }
        },
        askMinorModorSurvey: function(){
            if(this.replacementSelectionCompleted){
                return this.tasks.replacement
            }else{
                return false;
            }
        },
        testKitModel: function(){
            var mdl = '';
            if(this.technician.testKit.manufacturer != null){
                mdl += this.technician.testKit.manufacturer + ' ';
            }
            if(this.technician.testKit.model != null){
                mdl += this.technician.testKit.model;
            }
            return mdl
        },
        testKitCalDate: function(){
            var date = null;
            if(this.technician.testKit.calDate != null){
                date = new Date(this.technician.testKit.calDate).toISOString().substr(0,10);
            }
            return date;
        },
        compInitValid: function(){
            return this.$store.getters.getFormValid({parentFormID: this.entryID, checkKeys: this.initialTestFields});
        },
        initialTestResult: function(){
            var result = {
                value: "PASS",
                failureMode: null,
                blame: {
                    module: null,
                    key: null
                }
            };
            // Check If SO TC Issues Filled
            if(this.testValues.preliminary.so_tc_issues != null){ 
                result = {
                    value: 'FAIL',
                    failureMode: `SO TC Issues: ${this.testValues.preliminary.so_tc_issues.join(', ')}`,
                    blame: {
                        module: "preliminary",
                        key:"so_tc_issues"
                    }
                };
                return result;
            }

            // fail when so1 or so2 not close
            if(this.testValues.initial.so1_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO1 Leak",
                    blame: {
                        module: "initial",
                        key:"so1_close"
                    }
                };
                return result;
            }
            if(this.testValues.initial.so2_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO2 Leak",
                    blame: {
                        module: "initial",
                        key:"so2_close"
                    }
                };
                return result;
            }
            if(this.testValues.repair.quick_repair_notes != null){ 
                result = {
                    value: 'FAIL',
                    failureMode: "Repairs Noted",
                    blame: {
                        module: "repair",
                        key:"quick_repair_notes"
                    }
                };
                return result;
            }
            // Device Type Dependent
            // All RP & DC Class Valves Are Subject To These Rules
            if(this.isDCRPClass()){
                if(this.testValues.initial.ck1_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "initial",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.ck2_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "initial",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.ck2_psid != null && this.testValues.initial.ck2_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK2 PSID < 1",
                        blame: {
                            module: "initial",
                            key:"ck2_psid"
                        }
                    };
                    return result;
                }
            }

            // More Specific Rules
            if(this.isRP()){ // RP Rules
                if(this.testValues.initial.ck1_psid != null && this.testValues.initial.ck1_psid < 5){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV CK1 PSID < 5",
                        blame: {
                            module: "initial",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.rv_open_at != null && this.testValues.initial.rv_open_at < 2){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Open At < 1",
                        blame: {
                            module: "initial",
                            key:"rv_open_at"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.rv_did_not_open == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Did Not Open",
                        blame: {
                            module: "initial",
                            key:"rv_did_not_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.rv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Leaking",
                        blame: {
                            module: "initial",
                            key:"rv_leak"
                        }
                    };
                    return result;
                }
                result = {
                    value: 'PASS',
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else if(this.isDC()){ // DC Rules
                if(this.testValues.initial.ck1_psid != null && this.testValues.initial.ck1_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "DC CK1 PSID < 1",
                        blame: {
                            module: "initial",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                result = {
                    value: 'PASS',
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else if(this.isPVB()){
                if(this.testValues.initial.pvb_ag_open == false){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG Did Not Open",
                        blame: {
                            module: "initial",
                            key:"pvb_ag_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.pvb_ag_psid != null && this.testValues.initial.pvb_ag_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG PSID < 1",
                        blame: {
                            module: "initial",
                            key:"pvb_ag_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.pvb_cv_psid != null && this.testValues.initial.pvb_cv_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV PSID < 1",
                        blame: {
                            module: "initial",
                            key:"pvb_cv_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.initial.pvb_cv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV Leaking",
                        blame: {
                            module: "initial",
                            key:"pvb_cv_leak"
                        }
                    };
                    return result;
                }
                result = {
                    value: 'PASS',
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else{
                result = {
                    value: 'Not a Testable Device',
                    failureMode: `Device Type: ${this.getTestDeviceType()} is not testable`,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }
        },
        finalTestResult: function(){
           var result = {
                value: "REPAIRED & PASSED",
                failureMode: null,
                blame: {
                    module: null,
                    key: null
                }
            };
            // Pass when initial test pass
            if(this.initialTestResult.value == 'PASS'){
                result = {
                    value: "PASS",
                    failureMode: null,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            }else if(this.testValues.repair.no_final){
                result = {
                    value: "FAIL",
                    failureMode: "Quick Repair Notes Indicate Failure Without Final Test",
                    blame: {
                        module: 'repair',
                        key: "quick_repair_notes"
                    }
                };
                return result;
            }
            // fail when so1 or so2 not close
            if(this.testValues.final.so1_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO1 Leak",
                    blame: {
                        module: "final",
                        key:"so1_close"
                    }
                };
                return result;
            }
            if(this.testValues.final.so2_close == false){ 
                result = {
                    value: 'FAIL',
                    failureMode: "SO2 Leak",
                    blame: {
                        module: "final",
                        key:"so2_close"
                    }
                };
                return result;
            }
            // Device Type Dependent
            // All RP & DC Class Valves Are Subject To These Rules
            if(this.isDCRPClass()){
                if(this.testValues.final.ck1_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "final",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.ck2_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK1 Leak",
                        blame: {
                            module: "final",
                            key:"ck1_leak"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.ck2_psid != null && this.testValues.final.ck2_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "CK2 PSID < 1",
                        blame: {
                            module: "final",
                            key:"ck2_psid"
                        }
                    };
                    return result;
                }
            }

            // More Specific Rules
            if(this.isRP()){ // RP Rules
                if(this.testValues.final.ck1_psid != null && this.testValues.final.ck1_psid < 5){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV CK1 PSID < 5",
                        blame: {
                            module: "final",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.rv_open_at != null && this.testValues.final.rv_open_at < 2){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Open At < 1",
                        blame: {
                            module: "final",
                            key:"rv_open_at"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.rv_did_not_open == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Did Not Open",
                        blame: {
                            module: "final",
                            key:"rv_did_not_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.rv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "RV Leaking",
                        blame: {
                            module: "final",
                            key:"rv_leak"
                        }
                    };
                    return result;
                }
                return result;
            }else if(this.isDC()){ // DC Rules
                if(this.testValues.final.ck1_psid != null && this.testValues.final.ck1_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "DC CK1 PSID < 1",
                        blame: {
                            module: "final",
                            key:"ck1_psid"
                        }
                    };
                    return result;
                }
                return result;
            }else if(this.isPVB()){
                if(this.testValues.final.pvb_ag_open == false){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG Did Not Open",
                        blame: {
                            module: "final",
                            key:"pvb_ag_open"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.pvb_ag_psid != null && this.testValues.final.pvb_ag_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB AG PSID < 1",
                        blame: {
                            module: "final",
                            key:"pvb_ag_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.pvb_cv_psid != null && this.testValues.final.pvb_cv_psid < 1){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV PSID < 1",
                        blame: {
                            module: "final",
                            key:"pvb_cv_psid"
                        }
                    };
                    return result;
                }
                if(this.testValues.final.pvb_cv_leak == true){ 
                    result = {
                        value: 'FAIL',
                        failureMode: "PVB CV Leaking",
                        blame: {
                            module: "final",
                            key:"pvb_cv_leak"
                        }
                    };
                    return result;
                }
                return result;
            }else{
                result = {
                    value: 'Not a Testable Device',
                    failureMode: `Device Type: ${this.getTestDeviceType()} is not testable`,
                    blame: {
                        module: null,
                        key: null
                    }
                };
                return result;
            } 
        },
    },
    beforeCreate(){

    },
    created(){
        this.entryID = butils.uuid();
        this.$store.commit('initForm',{ localFormID: this.entryID, meta: this.formDef.meta });
    },
    beforeMount (){

    },
    mounted(){
        if(this.deviceID != null){
            this.fetchDeviceInformation(this.deviceID, true);
        }
        butils.instance.get(process.env.VUE_APP_API_BASE_URL + '/portal/available')
        .then((response)=>{
            this.portalRecipients = []
            this.rawUtilityList = response.data.result;
            this.rawUtilityList.forEach((utility)=>{
                this.portalRecipients.push({
                    value: utility.portal_id,
                    text:  `${utility.name} - ${utility.description}`
                })
            })
        })
        .catch((err)=>{
            if(butils.isError401(err)){
                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
            }else if(butils.isError403(err)){
                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to fetch a list of portal available accounts, please contact your administrator to receive permissions to perform this action.', 'danger');
            }else{
                console.log(err)
                butils.createToast(this, "Failed Durring Portal Availability List Lookup", "Something went wrong while fetching the list of available utilities to submit to", "danger", 30);
            }
        })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.entryID});
    },
    destroyed(){

    }
}
</script>

<style scoped>
#one-screen-stage-member{
    height: calc(100vh - 56px - 0.5rem);
    overflow-y: scroll;
}
</style>