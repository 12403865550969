<template>
    <div>
        <b-row>
            <b-col cols="12" v-if="!fetchingList" class="d-flex flex-row d-flex align-items-center mb-2">
                <b-button
                    class="mr-3"
                    v-if="!optionsOpen"
                    @click="openOptions"
                >
                    <b-icon-gear-fill></b-icon-gear-fill>
                </b-button>
                <b-button
                    v-if="optionsOpen"
                    class="mr-3"
                    @click="closeOptions"
                    variant="danger"
                >
                    <b-icon-x></b-icon-x> Close Options
                </b-button>
                <div class="h3 my-auto">
                    Portal Submissions
                </div>
                
            </b-col>
            <b-col cols="12" v-if="optionsOpen" class="d-flex flex-row d-flex justify-content-between align-items-center py-2 border-top border-bottom">
                <div class="h4 my-auto">
                    Table Options
                </div>
                
            </b-col>
            <b-col cols="12" v-if="optionsOpen" class="d-flex flex-column d-flex py-2">
                <div class="d-flex flex-row">
                    <div class="w-50">
                        <b-form-group label="Statuses" label-for="tags-with-dropdown" label-class="h5 font-weight-bold" class="w-100">
                            <b-form-tags id="tags-with-dropdown" v-model="visibleStatuses" no-outer-focus class="mb-2">
                                <template v-slot="{ tags, disabled, addTag, removeTag }">
                                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                        <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                            <b-form-tag
                                                @remove="removeTag(tag);"
                                                :title="tag"
                                                :disabled="disabled"
                                                :variant="setVaraintForTag(tag)"
                                            >{{ tag }}</b-form-tag>
                                        </li>
                                    </ul>
                                <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                                    <template #button-content>
                                        <b-icon-tag-fill></b-icon-tag-fill> Add Statuses
                                    </template>
                                        <b-dropdown-item-button
                                            v-for="option in availableOptions"
                                            :key="option"
                                            @click="addTag(option);"
                                            >
                                            <b-badge
                                                :variant="setVaraintForTag(option)"
                                            >{{ option }}    
                                            </b-badge>
                                        </b-dropdown-item-button>
                                        <b-dropdown-text v-if="availableOptions.length === 0">
                                            <small>No Statuses Hidden</small>
                                        </b-dropdown-text>
                                    </b-dropdown>
                                </template>
                            </b-form-tags>
                        </b-form-group>
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <div class="pr-1 w-50 d-flex flex-column">
                        <!-- Submission Date -->
                        <span class="h5 font-weight-bold">Submission Date</span>
                        <div class="d-flex flex-row align-items-center mb-2">
                            <label for="submission-min" class="h6 font-weight-bold date-label-width text-right">From</label>
                            <b-form-datepicker id="submission-min" size="sm" class="ml-2" v-model="submissionDate.min"></b-form-datepicker>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            <label for="submission-max" class="h6 font-weight-bold date-label-width text-right">To</label>
                            <b-form-datepicker id="submission-max" size="sm" class="ml-2" v-model="submissionDate.max"></b-form-datepicker>
                        </div>
                    </div>
                    <div class="pl-1 w-50 d-flex flex-column">
                        <!-- Processing Date -->
                        <span class="h5 font-weight-bold">Processed Date</span>
                        <div class="d-flex flex-row align-items-center mb-2">
                            <label for="process-min" class="h6 font-weight-bold date-label-width text-right">From</label>
                            <b-form-datepicker id="process-min" size="sm" class="ml-2" v-model="processDate.min"></b-form-datepicker>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            <label for="process-max" class="h6 font-weight-bold date-label-width text-right">To</label>
                            <b-form-datepicker id="process-max" size="sm" class="ml-2" v-model="processDate.max"></b-form-datepicker>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-table
            id="portal-submission"
            :busy="fetchingList"
            :items="items"
            :fields="fields"
            outlined
            stacked="sm"
        >
            <template #table-busy>
                <waiting-dots title="Loading"></waiting-dots>
            </template>
            <template #cell(event_name)="data">
                <b-link
                    class="text-dark"
                    v-if="data.item.event_name == 'BFPA Test'"
                    v-b-popover.hover="'View This Test (Opens In New Tab)'" 
                    target="_blank" 
                    :to="`/home/test/${data.item.sender_event_id}`"
                >
                    <u>{{data.item.event_name}}</u>
                </b-link>
                <b-link
                    class="text-dark"
                    v-if="data.item.event_name == 'Survey' || data.item.event_name == 'Field Update'"
                    v-b-popover.hover="'View This Survey (Opens In New Tab)'" 
                    target="_blank" 
                    :to="`/home/survey/${data.item.sender_event_id}`"
                >
                    <u>{{data.item.event_name}}</u>
                </b-link>
                <b-link
                    class="text-dark"
                    v-if="data.item.event_name == 'BFPA Install or Replacement'"
                    v-b-popover.hover="'View This Install/Replacement (Opens In New Tab)'" 
                    target="_blank" 
                    :to="`/home/replacement/${data.item.sender_event_id}`"
                >
                    <u>{{data.item.event_name}}</u>
                </b-link>
            </template>
            <template #cell(status)="data">
                <b-badge
                    variant="success"
                    v-if="data.item.status == 'Successful'"
                    v-b-popover.hover="`Processed By: ${data.item.processed_by_user_name} On: ${data.item.processed_on}`"
                    :title="data.item.status"
                >
                    {{data.item.status}}
                </b-badge>
                <b-badge
                    variant="danger"
                    v-else-if="data.item.status == 'Rejected'"
                    v-b-popover.hover="`${data.item.note}`"
                    :title="data.item.status"
                >
                    {{data.item.status}}
                </b-badge>
                <b-badge 
                    v-else
                    variant="warning"
                >
                    {{data.item.status}}
                </b-badge>
                <span></span>
            </template>
        </b-table>
    </div>
</template>

<script>

const butils = require('../../libs/basicUtils.js');

const _ = butils.underscore;

const DateTime = butils.Luxon;

import dynForm from '@/components/DynamicForm.vue';
import Image from '@/components/subcomponents/Image.vue';
import WaitingDots from '@/components/subcomponents/WaitingDots.vue';

export default {
    name: 'PortalSubmissionList',
    components:{
        'dynForm' : dynForm,
        'hover-image-with-modal': Image,
        'waiting-dots': WaitingDots
    },
    props:{

    },
    data(){
        return{
            fetchingList: true,
            optionsOpen: false,
            allStatuses: ['Pending Review', 'Rejected', 'Successful'],
            visibleStatuses: ['Pending Review', 'Rejected', 'Successful'],
            hiddenStatuses: [],
            recordCount: 0,
            fields: [
                { key: 'event_name', label: 'Type'},
                { key: 'sent_to', label: 'Sent To' }, 
                { key: 'sent_on', label: 'Sent On' }, 
                { key: 'status', label: 'Status' }, 
                'actions'
                ],
            items: [],
            submissionDate: {
                min: null,
                max: null
            },
            processDate: {
                min: null,
                max: null
            },
            debouceUpdate: ()=>{}
        }
    },
    methods:{
        setVaraintForTag(tag){
            if(tag == 'Pending Review'){
                return 'vai-warning-darker';
            }else if(tag == 'Rejected'){
                return 'danger';
            }else if(tag == 'Successful'){
                return 'success';
            }
        },
        openOptions(){
            this.optionsOpen = true;
        },
        closeOptions(){
            this.optionsOpen = false;
        },
        loadSubmissionList(){
            this.items = [];
            this.fetchingList = true;
            var params = new URLSearchParams();
            // Select
            if(this.submissionDate.min != null){
                params.append('sent_after', this.submissionDate.min);
            }
            if(this.submissionDate.max != null){
                params.append('sent_before', this.submissionDate.max);
            }
            if(this.processDate.min != null){
                params.append('processed_after', this.processDate.min);
            }
            if(this.processDate.max != null){
                params.append('processed_before', this.processDate.max);
            }
            this.hiddenStatuses.forEach((status)=>{
                params.append('hide_status', status);
            })
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/portal/result/list`, {params})
            .then((response)=>{
                response.data.result.forEach(elm => {
                    elm.sent_on = butils.formatters.timestampToDateTime(elm.sent_on, true, false);
                    elm.processed_on = butils.formatters.timestampToDateTime(elm.processed_on, true, false);
                    switch (elm.status) {
                        case "Pending Review":
                            elm._rowVariant = "vai-warning-darker"
                            break;
                        case "Successful":
                            elm._rowVariant = "success"
                            break;
                        case "Rejected":
                            elm._rowVariant = "danger"
                            break;
                        default:
                            break;
                    }
                });
                this.items = response.data.result;
                this.fetchingList = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed To Fetch Portal Submissions", "Something went wrong while fetching the list of records submitted to your portal", "danger", 30);
                }
            })
        }
    },
    watch:{
        visibleStatuses(newValue, oldValue){
            this.hiddenStatuses = _.difference(this.allStatuses, newValue);
            this.loadSubmissionList();
        }
    },
    computed:{
        availableOptions() {
            const options = _.filter(this.allStatuses, opt => this.visibleStatuses.indexOf(opt) === -1)
            // Show all options available
            return options
        },
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        var lastMonth = DateTime.now().minus({months:1}).toISODate();
        this.processDate.min = lastMonth;
        this.loadSubmissionList();
    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
.date-label-width{
    width: 3.5rem;
}
</style>