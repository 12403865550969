<template>
    <div>
        <div v-if="!complianceLoading" @click="expandOverallCompliance" :class="((overallComplianceOpen) ? 'bg-vai-gray-400': 'bg-vai-gray-300')">
            <div class="p-2 d-flex flex-row">
                <div class="w-100 text-center align-self-center">
                    <div class="d-flex flex-column">
                        <div class="w-100 text-center font-weight-bold">
                            Overall System Compliance
                        </div>
                        <div v-if="radialValue <= 30" class="w-100 text-center small">
                            Let's Get To It
                        </div>
                        <div v-else-if="radialValue >= 80" class="w-100 text-center small">
                            Amazing Job
                        </div>
                        <div v-else-if="radialValue >= 70" class="w-100 text-center small">
                            Great Work
                        </div>
                        <div v-else-if="radialValue == 69" class="w-100 text-center small">
                            Nice Work
                        </div>
                        <div v-else-if="radialValue >= 60" class="w-100 text-center small">
                            Good Job (Click Anywhere To Gain Insight)
                        </div>
                        <div v-else class="w-100 text-center small">
                            Needs Some Improvement (Click Anywhere To Gain Insight)
                        </div>
                    </div>
                </div>
                <div class="flex-shrink-1">
                    <apexchart height="200px" type="radialBar" :options="chartOptions" :series="[radialValue]"></apexchart>
                </div>
            </div>
        </div>
        <div v-else class="bg-vai-gray-400">
            <div class="p-2 d-flex flex-row">
                <div class="w-100 text-center align-self-center">
                    <div class="d-flex flex-column">
                        <div class="w-100 text-center font-weight-bold">
                            Overall System Compliance
                        </div>
                        <div class="w-100 text-center small">
                            Vai Is Collecing Information - Please Stand By
                        </div>
                    </div>
                </div>
                <div class="flex-shrink-1 h-100 align-self-center text-center">
                    <div class="d-flex justify-content-center align-items-center my-2 mr-5">
                        <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                        <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                        <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                    </div>
                </div>
            </div>
        </div>
        <b-collapse id="overall-compliance">
            <b-row class="px-3" >
                <b-col xl="6" md="8" cols="8" class="bg-vai-gray-300">
                    Total System Devices <b-icon-question-circle id="total-system-devices"></b-icon-question-circle>
                    <b-popover target="total-system-devices" triggers="hover">
                        <template #title>Total System Devices</template>
                        The total number of <b>active</b>, <b>enabled</b> devices in Valve Ai.
                    </b-popover>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-300">
                    <b>{{compliance.totalDev}}</b>
                </b-col>
                <div class="w-100"></div>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_devices" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of All Testable Devices In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Total Testable Devices
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.testableDev}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/non_testable_devices" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of All Non-Testable Devices In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Total Non-Testable Devices
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.totalDev - compliance.testableDev}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/unnecessary_devices" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of All Unnecessary Devices In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Total Unnecessary Devices
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.removable}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_unnecessary_devices" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of All Testable Unnecessary Devices In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Testable Unnecessary Devices
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.removableTestable}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="bg-vai-gray-300">
                    Overall Compliance
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-300">
                    <b>{{`${radialValue}%`}}</b>
                </b-col>
                <div class="w-100"></div>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/compliant_devices_exclude_unnecessary" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of All Compliant Devices In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Compliant Devices <b-icon-question-circle id="compliant-devices-explain"></b-icon-question-circle>
                            <b-popover target="compliant-devices-explain" triggers="hover">
                                <template #title>Compliant Devices</template>
                                Devices may be considered compliant if it meets all the following criteria: <br>
                                <div class="ml-2">
                                    - The device provides sufficient protection against it's associated hazard<br>
                                    - The device is not due for maintenance (Duals and Air Gaps)<br>
                                    - The device is not overdue for testing (Testable Devices Only)
                                </div>
                                Note that dual checks for which no installation date is known are not considered compliant.
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.fullyCompliant}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/non_compliant_devices_exclude_unnecessary" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of All Compliant Devices In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Non-Compliant Devices <b-icon-question-circle id="non-compliant-devices-explain"></b-icon-question-circle>
                            <b-popover target="non-compliant-devices-explain" triggers="hover">
                                <template #title>Non-Compliant Devices</template>
                                Devices may be considered non-compliant for several reasons: (not mutually exclusive)<br>
                                <div class="ml-2">
                                    - Devices Known To Provide Insufficient Protection<br>
                                    - Devices Assumed To Provide Insufficient Protection (Survey Required)<br>
                                    - Test Overdue (Testable Devices Only)<br>
                                    - Most Recent Test Failed / No Tested (Testable Devices Only)<br>
                                    - Maintenance/Replacement Overdue (Dual Checks Only)<br>
                                    - Inspection Overdue (Air Gap Only)
                                </div>
                                Note that dual checks for which no installation date is known are not considered compliant.
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.allNonCompliant}}</b>
                </b-col>

                <b-col xl="6" md="8" cols="8" class="bg-vai-gray-300">
                    Device Protection Sufficiency Status <b-icon-question-circle id="sufficient-tutorial"></b-icon-question-circle>
                    <b-popover target="sufficient-tutorial" triggers="hover">
                        <template #title>Sufficient Protection</template>
                        A device provides sufficient protection if it's device type is listed as acceptable for the hazard type the device protects against.
                    </b-popover>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-300 d-flex align-items-center">
                    <small>{{`This category is responsible for ${(((compliance.requiresSurvey + compliance.insufficient)/compliance.allNonCompliant)*100).toFixed(0)}% of system non-compliance (${compliance.requiresSurvey + compliance.insufficient})`}}</small>
                </b-col>
                <div class="w-100"></div>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button
                                class="ml-auto" 
                                size="sm" 
                                href="/home/listbuilder/sufficient_protection" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devices Which Provide Sufficient Protection In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Sufficient<b-icon-question-circle id="sufficient-explain"></b-icon-question-circle>
                            <b-popover target="sufficient-explain" triggers="hover">
                                <template #title>Sufficient Protection</template>
                                The devices in this category have a type that is considered acceptable for the hazard they are protecting against.
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.sufficient}}</b> ({{`${((compliance.sufficient / compliance.totalDev) * 100).toFixed()}%`}})
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button
                                class="ml-auto" 
                                size="sm" 
                                href="/home/listbuilder/insufficient_protection_exclude_unnecessary" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devices Which Provide Insufficient Protection In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Insufficient <b-icon-question-circle id="insufficient-explain"></b-icon-question-circle>
                            <b-popover target="insufficient-explain" triggers="hover">
                                <template #title>Insufficient Protection</template>
                                The devices in this category have a type that is not considered acceptable for the hazard they are protecting against.
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.insufficient}}</b> ({{`${((compliance.insufficient / compliance.totalDev) * 100).toFixed()}%`}})
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-100">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3 pl-5">
                            <b-button
                                class="ml-auto" 
                                size="sm" 
                                href="/home/listbuilder/sub_optimal_exclude_unnecessary" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devices Which Sub Optimal Protection In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Protected Sub-Optimally
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-100 d-flex align-items-center">
                    <b>{{compliance.suboptimal}}</b> ({{`${((compliance.suboptimal / compliance.totalDev) * 100).toFixed()}%`}})
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-100">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3 pl-5">
                            <b-button
                                class="ml-auto" 
                                size="sm" 
                                href="/home/listbuilder/unprotected" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Unprotected Hazards In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Unprotected
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-100 d-flex align-items-center">
                    <b>{{compliance.insufficient - compliance.suboptimal}}</b> ({{`${(((compliance.insufficient - compliance.suboptimal) / compliance.totalDev) * 100).toFixed()}%`}})
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                class="ml-auto"
                                size="sm" 
                                href="/home/listbuilder/survey_required" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devices Which Require A Survey To Determine Protection Sufficency Status In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Survey Required <b-icon-question-circle id="why-survey-tutorial"></b-icon-question-circle>
                            <b-popover target="why-survey-tutorial" triggers="hover">
                                <template #title>What's Missing, Why Do We Need Surveys?</template>
                                To determine if a device provides sufficient protection, two pieces of information are required:<br>
                                <span class="ml-2">- Device Type</span><br>
                                <span class="ml-2">- Hazard Type</span><br>
                                If the device is missing either of these two peices of information, it is considered non-compliant by Valve Ai, and a survey is required to confirm compliance status.
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.requiresSurvey}}</b> ({{`${((compliance.requiresSurvey / compliance.totalDev) * 100).toFixed()}%`}})
                </b-col>
                <!-- Start Overdue -->
                <b-col xl="6" md="8" cols="8" class="bg-vai-gray-300">
                    Overdue
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-300 d-flex align-items-center">
                    <small>
                        {{`This category is responsible for 
                        ${(((compliance.nonTestableMaintContributes + compliance.lastTestFailedContributes + compliance.testableDeviceOverdueContributes)/compliance.allNonCompliant)*100).toFixed(0)}% of system non-compliance 
                        (${compliance.nonTestableMaintContributes + compliance.lastTestFailedContributes + compliance.testableDeviceOverdueContributes})`}}
                    </small>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/dual_checks_overdue" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Dual Checks That Require Maintenance or Replacement In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Dual Checks Overdue <b-icon-question-circle id="dual-checks-overdue-explain"></b-icon-question-circle>
                            <b-popover target="dual-checks-overdue-explain" triggers="hover">
                                <template #title>Dual Checks Overdue</template>
                                Dual checks in the system which are overdue for maintenance or replacement
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.dualRequireMaintenance}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/ag_overdue" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Air Gaps That Require Inspection In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Air Gaps Overdue <b-icon-question-circle id="air-gaps-overdue-explain"></b-icon-question-circle>
                            <b-popover target="air-gaps-overdue-explain" triggers="hover">
                                <template #title>Air Gaps Overdue</template>
                                Air gaps in the system which are overdue for inspection
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.agRequireInspection}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_failed_last_test" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devies That Didnt Pass Their Last Test In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Testable Devices Failed Last Test <b-icon-question-circle id="testable-devices-failure-explain"></b-icon-question-circle>
                            <b-popover target="testable-devices-failure-explain" triggers="hover">
                                <template #title>Testable Devices Failed Last Test</template>
                                Testable devices which failed or NOTESTED their most recent test.
                                <br>
                                This non-compliance modality ignores the existance of a passing test that occured 
                                before the failure or notest, even if that passing test is within allowable timeframe. 
                                A device which fails a test is a failing device, and will be considered non-compliant 
                                until a passing test is provided or the device is replaced by a compliant device.
                                <br>
                                <div class="ml-2">Note - NOTEST is considered a failure, regardless of timeframe. 
                                    This is because if a device was not testable the last time a technician attempted 
                                    to test the device, generally due to inaccessibility, or other external factors, 
                                    Valve Ai assumes this result to be a failure. 
                                    Generally this is fixed by testing the device successfully in the case of accessability issues, 
                                    however a large number of these can be indicative of other issues.
                                </div>
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.lastTestFailed}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_overdue_all" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devies That Are Overdue For Testing In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Testable Devices Overdue Total <b-icon-question-circle id="testable-devices-overdue-explain"></b-icon-question-circle>
                            <b-popover target="testable-devices-overdue-explain" triggers="hover">
                                <template #title>Testable Devices Overdue</template>
                                Testable devices which are at least one day overdue for the test they should have recieved.<br>
                                Expected test due date is calculated as: (most recent passing test date + the timeframe set per the site type (either residential / non-residential))<br>
                                If this day is calculated to be before today, the device is overdue for testing<br>
                                Excludes devices which failed/notested their most recent test
                            </b-popover>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.testOverdue}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_overdue_1_30" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devies That Are Overdue For Testing ( < 30 Days ) In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Testable Devices Overdue ( < 30 Days )
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.testableOverdueLessThan30}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_overdue_31_90" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devies That Are Overdue For Testing ( 31-90 Days ) In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Testable Devices Overdue ( 31-90 Days )
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.testableOverdue31to90}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_overdue_90_plus" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devies That Are Overdue For Testing ( > 90 Days ) In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Testable Devices Overdue ( > 90 Days )
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.testableOverdueMoreThan90}}</b>
                </b-col>
                <!-- Due Soon -->
                <b-col xl="6" md="8" cols="8" class="bg-vai-gray-300">
                    Due Soon
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-300 d-flex align-items-center">
                    <small>
                        {{`Based On Last Action Date And Schedule Set Per Utility CCC Manual`}}
                    </small>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_due_in_30" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devies That Are Due For Testing Within The Next 30 Days In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Tests Due Within The Next 30 Days
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.testDue30Days}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/testable_due_in_90" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Devies That Are Due For Testing Within The Next 90 Days In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Tests Due Within The Next 90 Days
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.testDue90Days}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/ag_due_in_30" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Air Gaps That Are Due For Inspection Within The Next 30 Days In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Air Gap Inspection Due Within 30 Days
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.agDue30Days}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/ag_due_in_90" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Air Gaps That Are Due For Inspection Within The Next 90 Days In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Air Gap Inspection Due Within 90 Days
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.agDue90Days}}</b>
                </b-col>
                <b-col xl="6" md="8" cols="8" class="pl-5 bg-vai-gray-200">
                    <div class="d-flex flex-row align-items-center py-1">
                        <div class="pr-3">
                            <b-button 
                                size="sm" 
                                href="/home/listbuilder/duals_due_within_1_year" 
                                variant="vai-olive"
                                v-b-popover.hover.right="'View List of Dual Checks Due For Maintanence or Replacement Within 1 Year In The List Builder'" 
                                title="View In List Builder"
                            >
                                <b-icon-eye-fill></b-icon-eye-fill>
                            </b-button>
                        </div>
                        <div>
                            Dual Check Maintenance/Replacement Due Within 1 Year
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" md="4" cols="4" class="bg-vai-gray-200 d-flex align-items-center">
                    <b>{{compliance.dualDueInAYear}}</b>
                </b-col>
            </b-row>
        </b-collapse>
    </div>
</template>

<script>
const butils = require('../libs/basicUtils.js');
const _ = butils.underscore;

export default {
    name: 'ComplianceOverview',
    components:{
        
    },
    props:{

    },
    data(){
        return{
            overallComplianceOpen: false,
            complianceLoading: true,
            compliance: {
                totalDev: 0,
                testableDev: 0,
                fullyCompliant: 0,
                allNonCompliant: 0,
                sufficient: 0,
                insufficient: 0,
                suboptimal: 0,
                removable: 0,
                removableTestable: 0,
                requiresSurvey: 0,
                testOverdue: 0,
                allReqMaint: 0,
                nonTestableRequireMaintenance: 0,
                nonTestableMaintContributes: 0,
                dualRequireMaintenance: 0,
                agRequireInspection: 0,
                testDue30Days: 0,
                testDue90Days: 0,
                dualDueInAYear: 0,
                agDue30Days: 0,
                agDue90Days: 0,
                lastTestFailed: 0,
                lastTestFailedContributes: 0,
                testableOverdueLessThan30: 0,
                testableOverdue31to90: 0,
                testableOverdueMoreThan90: 0,
                testableDeviceOverdueContributes: 0,
                totalTestableDevices: 0,
                testableDevicesSufficient: 0,
                testableDevicesInsufficient: 0,
                totalTestableFullyCompliant: 0
            },
            radialValue: 0,
            chartOptions: {
                chart: {
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 50,
                            size: "45%",
                        },
                        offsetY: -30,
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: '#333',
                            startAngle: -90,
                            endAngle: 0,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                fontSize: "1.5rem",
                                color: "#131E29",
                                show: true,
                                offsetY: 0
                            }
                        }
                    }
                },
                fill: {
                    colors: [
                        function({ value }) {
                            if(value < 30){
                                return '#9C0000'
                            }else if(value > 70){
                                return '#5C8001'
                            }else{
                                return '#E3B505'
                            }
                        }
                    ]
                },
                stroke: {
                    lineCap: "square"
                },
            }
        }
    },
    methods:{
        expandOverallCompliance(){
            this.overallComplianceOpen = !this.overallComplianceOpen;
            this.$root.$emit('bv::toggle::collapse', "overall-compliance")
        },
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.complianceLoading = true;
        butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/reporting/compliance`)
        .then((response)=>{
            var result = response.data.result
            this.compliance.totalDev = parseInt(result.all_device_count, 10);
            this.compliance.testableDev = parseInt(result.testable_device_count, 10);
            this.compliance.fullyCompliant = parseInt(result.all_fully_compliant, 10);
            this.compliance.allNonCompliant = parseInt(result.all_devices_non_compliant, 10);
            this.compliance.sufficient = parseInt(result.all_devices_sufficient, 10);
            this.compliance.insufficient = parseInt(result.all_devices_insufficient, 10);
            this.compliance.suboptimal = parseInt(result.all_devices_suboptimal, 10);
            this.compliance.removable = parseInt(result.all_devices_can_be_removed, 10);
            this.compliance.removableTestable = parseInt(result.testable_devices_can_be_removed, 10);
            this.compliance.requiresSurvey = parseInt(result.all_devices_require_hazard_survey, 10);
            this.compliance.testOverdue = parseInt(result.testable_device_test_overdue, 10);
            this.compliance.allReqMaint = parseInt(result.all_devices_require_maintenance, 10);
            this.compliance.nonTestableRequireMaintenance = parseInt(result.non_testable_devices_require_maintenance, 10);
            this.compliance.nonTestableMaintContributes = parseInt(result.non_testable_devices_require_maintenance_contributes, 10);
            this.compliance.dualRequireMaintenance = parseInt(result.dual_checks_require_maintenance, 10);
            this.compliance.agRequireInspection = parseInt(result.air_gap_require_inspection, 10);
            this.compliance.lastTestFailed = parseInt(result.testable_device_failed_last_test, 10);
            this.compliance.lastTestFailedContributes = parseInt(result.testable_device_failed_last_test_contributes, 10);
            this.compliance.testableDeviceOverdueContributes = parseInt(result.testable_device_test_overdue_contributes, 10);
            this.compliance.testableOverdueLessThan30 = parseInt(result.testable_device_test_overdue_less_than_30_days, 10);
            this.compliance.testableOverdue31to90 = parseInt(result.testable_device_test_overdue_31_90_days, 10);
            this.compliance.testableOverdueMoreThan90 = parseInt(result.testable_device_test_overdue_more_than_90_days, 10);
            this.compliance.totalTestableDevices = parseInt(result.testable_device_count, 10);
            this.compliance.testableDevicesSufficient = parseInt(result.testable_device_sufficient, 10);
            this.compliance.testableDevicesInsufficient = parseInt(result.testable_device_insufficient, 10);
            this.compliance.totalTestableFullyCompliant = parseInt(result.testable_device_fully_compliant, 10);
            this.compliance.testDue30Days = parseInt(result.tests_due_in_the_next_30_days, 10);
            this.compliance.testDue90Days = parseInt(result.tests_due_in_the_next_90_days, 10);
            this.compliance.dualDueInAYear = parseInt(result.dual_checks_due_in_the_next_1_year, 10);
            this.compliance.agDue30Days = parseInt(result.ag_due_in_the_next_30_days, 10);
            this.compliance.agDue90Days = parseInt(result.ag_due_in_the_next_90_days, 10);
            this.radialValue = ((this.compliance.fullyCompliant/this.compliance.totalDev)*100).toFixed(0)
            this.complianceLoading = false;
        })
        .catch((err)=>{
            if(butils.isError401(err)){
                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
            }else if(butils.isError403(err)){
                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
            }else{
                console.log(err)
                butils.createToast(this, "Failed Durring Dashboard Compliance Data Fetch", "Something went wrong while fetching the real time compliance data", "danger", 30);
            }
            this.complianceLoading = false;
        })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>

</style>