<template>
<b-form-group 
    :id="'fieldset-horizontal-label-photo-'+ parentFormID + '-' + index + '-' + value"
    label-cols-sm="4"
    label-cols-lg="3"
    description=""
    :label="inputLabel"
    :label-size="size"
>
    <b-input-group>
        <hover-image-with-modal 
            :imgID="value"
            :displaySize="(size == 'sm') ? 64 : (size == 'md') ? 128 : 256"
        ></hover-image-with-modal>
    </b-input-group>
</b-form-group > 
    
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
import Image from '@/components/subcomponents/Image.vue';

export default {
    name: 'formimagewithlabel',
    components:{
        'hover-image-with-modal': Image,
    },
    props:{
        parentFormID: String,
        inputLabel: String,
        value: {
            type: String,
            default: () => {return null}
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        },
    },
    data(){
        return{
        }
    },
    methods: {
        
    },
    computed: {
        
    },
    mounted(){
    },
    created(){
    },
    beforeDestroy(){
    },
    beforeMount (){
    },
}
</script>