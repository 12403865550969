<template>
    <div>
        <b-row>
            <b-col sm="4" lg="3" class="">
                <!-- Actions -->
                Flags
                <b-row no-gutters>
                    <b-col sm="12" md="6" lg="12" class="mt-2">
                        
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="8" lg="9">
                <b-row>
                    <b-col sm="6" lg="3" v-for="(flag, index) in items" :key="index">
                        <flag-card :flag="flag" :showParentLink="showFlagParents" :onRemoveFlag="removeFlag"></flag-card>
                    </b-col>
                    <b-col sm="6" lg="3">
                        <b-button class="vai-flat-btn" block variant="primary" v-if="$store.getters.checkUIPC({path: ['form', 'actions', 'addFlag'] })" @click="showModal" >Add Flag</b-button>
                    </b-col>
                </b-row>
                
            </b-col>
        </b-row>
        <b-modal :id="'add-flag-modal-' + parentID" title="Add Flag" hide-footer size="lg">
            <b-form v-on:submit.prevent>
                <b-form-group 
                    :id="'add-flag-modal-select-flag-label-' + parentID"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    :label="'Select Flag:'"
                    :label-for="'add-flag-modal-select-flag-value-' + parentID"
                >
                    <b-form-select
                        :id="'add-flag-modal-select-flag-value-' + parentID"
                        v-model="nFlagVal"
                        :options="flagTypesDataList"
                        :required="true"
                        :multiple="false"
                    >
                    </b-form-select>
                </b-form-group>
                <b-form-datalist :id="'flag-types-datalist-' + parentID" :options="flagTypesDataList"></b-form-datalist>
                <b-form-group 
                    :id="'add-flag-modal-note-label-' + parentID"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    :label="'Note:'"
                    :label-for="'add-flag-modal-note-value-' + parentID"
                >

                    <b-form-input
                        :id="'add-flag-modal-note-value-' + parentID"
                        v-model="nNoteVal"
                    >
                    </b-form-input>
                </b-form-group>
            </b-form>
            <b-button-toolbar justify>
                <b-button variant="warning" @click="hideModal">Cancel</b-button>
                <b-button variant="success" :disabled="sending" @click="addFlag">Add Flag</b-button>
            </b-button-toolbar>
        </b-modal>
    </div>
    <!-- <b-card header="Flags" header-class="larger-header">
        <b-card-body>
            
        </b-card-body>
        
    </b-card> -->
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

const butils = require('../libs/basicUtils.js');

import FlagCard from '@/components/subcomponents/FlagCard.vue'

export default {
    name: 'flaglist',
    props: {
        parentID: {
            type: String
        },
        showFlagParents: {
            type: Boolean,
            default: () => { return false}
        }
    },
    components: {
        'flag-card': FlagCard
    },
    data(){
        return{
            apiEndpoint: 'flags/parent/',
            items: [],
            flagTypesDataList: [],
            nFlagVal: null,
            nNoteVal: '',
            sending: false
        }
    },
    methods: {
        fetchFlagTypes(){
            instance.get(process.env.VUE_APP_API_BASE_URL + '/flags/types.json')
            .then(async (response) => {
                this.flagTypesDataList = [];
                let list = response.data.result;
                // Put the flag list in alphabetical order
                list.sort((a,b)=>{ return a.value.localeCompare(b.value)})
                if(list.length > 0){
                    let tmp = list[0];
                    let keys = _.keys(tmp)
                    // Select only the keys in the flag object in form "for_"
                    var forKeys = keys.filter((key)=>{return key.match(/for_/g) != null })
                    // This grabs the name of the parent from the for_[parent], mapping the [parent] portion with a capitialized first letter
                    var forNames = forKeys.map((fk)=>{ return butils.formatters.capitalizeFirstLetter(fk.substring(4)); })
                    list.forEach((ft)=>{
                        let useFor = [];
                        forKeys.forEach((fk, idx)=>{
                            // If this flag defaults to this parent, add the parent name to the useFor array
                            if(ft[fk]){ useFor.push(forNames[idx])}
                        })
                        // TODO: figure out how to handle html in the select list item.
                        // html: `<span class="flag-select-top-line">${butils.cleaners.stripHTML(ft.value)}</span><br><span class="flag-select-bottom-line">${useFor.join(', ')}</span>`,                           
                        var opt = {
                            text: `${butils.cleaners.stripHTML(ft.value)} - (${useFor.join(', ')})`,
                            value: ft.id
                        };
                        this.flagTypesDataList.push(opt)
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchData(){
            this.items = [];
            instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.apiEndpoint + this.parentID + '.json')
            .then(async (response) => {
                this.items = response.data.result.filter((itm)=>{ return !itm.disabled });
            })
            .catch((error) => {
                console.log(error);
            });
        },
        addFlag(){
            this.sending = true;
            var flagObj = {
                parent: this.parentID,
                flag: this.nFlagVal,
                note: this.nNoteVal
            };
            instance.post(process.env.VUE_APP_API_BASE_URL + '/flags/flag.json', flagObj)
            .then((response)=>{
                // console.log(response);
                this.fetchData();
                this.hideModal();
                this.sending = false;
            })
            .catch((error)=>{
                console.log(error);
            })
        },
        showModal(){
            this.$bvModal.show('add-flag-modal-' + this.parentID);
        },
        hideModal(){
            this.nFlagVal = null;
            this.nNoteVal = null;
            this.$bvModal.hide('add-flag-modal-' + this.parentID);
        },
        removeFlag(flagID){
            // console.log(`Remove Flag Called On FlagID: ${flagID}`);
            this.items = this.items.filter((itm)=>{ return (itm.id != flagID) })
        },
    },
    computed: {
    },
    mounted(){
    },
    beforeCreate(){
    },
    beforeDestroy(){
    },
    beforeMount (){
        this.fetchData();
        this.fetchFlagTypes();
    },
    beforeCreate(){
    },
}
</script>

<style scoped>
.flag-select-top-line{
    font-weight: bold;
}
.flag-select-bottom-line{
    font-style: italic;
}
.larger-header{
    font-size: 1.5em;
}
</style>