<template>
    <div>
        <b-row>
            <b-col v-if="!selectionCompleted" cols="12">
                <b-input-group
                    id="big-search-group"
                    size="lg"
                >
                    <b-form-input 
                        class="pill-search"
                        placeholder="Search"
                        v-model="term"
                        @keyup.enter.native="search()"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button @click="search()" class="pill-search" variant="vai-secondary-darker">
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col v-if="!selectionCompleted" cols="12">
                <b-table class="mt-1" stacked="sm" small :busy="searching" :items="displaySearchResults" :fields="fields">
                    <template #table-busy>
                        <div class="d-flex justify-content-center align-items-center mt-4">
                            <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                            <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                            <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                        </div>
                        <div class="d-flex justify-content-center h3 mt-4">
                            <span>Searching</span>
                        </div>
                    </template>
                    <template #cell(match)="data">
                        <table style="width:100%" class="table-no-styles">
                            <colgroup>
                                <col span="1" style="width: 30%;">
                                <col span="1" style="width: 70%;">
                            </colgroup>
                            <tr>
                                <td class="align-text-top border-0">
                                    <strong>Found:</strong>
                                </td>
                                <td class="align-text-top border-0">
                                    <span v-html="data.item.grp.match_content"></span> ({{data.item.grp.match_on}})
                                </td>
                            </tr>
                            <tr v-if="data.item.grp.status != null" class="bg-danger text-light">
                                <td class="align-text-top border-0">
                                    <strong>Status:</strong>
                                </td>
                                <td class="align-text-top border-0">
                                    {{data.item.grp.status}}
                                </td>
                            </tr>
                            <tr>
                                <td class="align-text-top border-0">
                                    <strong>Address:</strong>
                                </td>
                                <td class="align-text-top border-0">
                                    {{data.item.service_address_line_one}}
                                </td>
                            </tr>
                            <tr>
                                <td class="align-text-top border-0">
                                    <strong>Meter Number:</strong>
                                </td>
                                <td class="align-text-top border-0">
                                    {{data.item.meter_number}}
                                </td>
                            </tr>
                            <tr>
                                <td class="align-text-top border-0">
                                    <strong>Device Model:</strong>
                                </td>
                                <td class="align-text-top border-0">
                                    {{data.item.device_size}}" {{data.item.device_mfg}} {{data.item.device_type}}  {{data.item.device_model}}
                                </td>
                            </tr>
                            <tr>
                                <td class="align-text-top border-0">
                                    <strong>Device Serial:</strong>
                                </td>
                                <td class="align-text-top border-0">
                                    {{data.item.device_serial}} 
                                </td>
                            </tr>
                        </table>
                    </template>
                    <template #cell(match_content)="data">
                        <span v-html="data.item.grp.match_content"></span>
                    </template>
                    <template #cell(status)="data">
                        <div v-if="data.item.grp.status != null" variant="danger">
                            {{data.item.grp.status}}
                        </div>
                    </template>
                    <template #cell(actions)="data">
                        <b-button-group vertical>
                            <b-button
                                variant="vai-success-relaxed"
                                @click="selectDeviceID(data.item.grp.id)"
                            >
                                Use This Device
                            </b-button>
                            <b-button
                                variant="info"
                                target="_blank"
                                :href="getFormLink(data.item.grp.id, 'devices')"
                            >
                                View This Device (New Tab)
                            </b-button>
                        </b-button-group>
                    </template>
                </b-table>
            </b-col>
            <b-col cols="12" v-if="selectionCompleted">
                <div>
                    <b-button @click="backToResults" variant="info"><b-icon-arrow-left></b-icon-arrow-left> Change Device Selection</b-button>
                </div>
                
                <dyn-form 
                    v-if="showDeviceForm"
                    class="mb-1"
                    :formID="multiDisplayForm" 
                    :inspectionTarget="selection.device_id" 
                    :showTitle="false"
                    :showFlags="false"
                    :hideEventList="true"
                    :vertScroll="false"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');

import dynForm from '@/components/DynamicForm.vue';

export default {
    name: 'searchdevicelarge',
    components:{
        'dynForm' : dynForm,
    },
    props:{
        callOnSelect: Function,
        showDeviceForm: Boolean
    },
    data(){
        return{
            term: null,
            searching: false,
            rawSearchResults: [],
            displaySearchResults: [],
            selection: null,
            fields: ['match', 'actions'],
            selectionCompleted: false,
            multiDisplayForm: null,
        }
    },
    methods:{
        search(){
            this.searching = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/search/device/${this.term}`)
            .then((response)=>{
                var results = response.data.result;
                this.rawSearchResults = butils.underscore.clone(response.data.result);
                var displayableResults = [];
                results.forEach(result=>{
                    let contentOfInterest = null;
                    if(result.sim_max_is == "Service Address"){
                        contentOfInterest = result.service_address_line_one;
                    }else if(result.sim_max_is == "Serial"){
                        contentOfInterest = result.device_serial;
                    }else if(result.sim_max_is == "Meter"){
                        contentOfInterest = result.meter_number;
                    }
                    contentOfInterest = contentOfInterest.replace(new RegExp(this.term, "gi"), (match) => `<mark>${match}</mark>`);

                    var cont = {
                        grp:{
                            id: result.device_id,
                            status: null,
                            match_on: result.sim_max_is,
                            similarity: result.max_sim,
                            match_content: contentOfInterest,
                        },
                        ...result
                    }
                    if(result.inactive != false){
                        cont.grp.status = "Inactive"
                        cont._cellVariants = { status: 'danger' }
                    }
                    if(result.disabled != false){
                        cont.grp.status = "Disabled"
                        cont._cellVariants = { status: 'danger' }
                    }
                    displayableResults.push(cont);

                })
                this.displaySearchResults = displayableResults;
                this.searching = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed Durring Device Search", "Something went wrong while fetching the matches for your search term, reload the page. Make sure that the submission was not already processed by someone else.", "danger", 30);
                }
                this.searching = false;
            })
        },
        selectDeviceID(deviceID){
            var match = butils.underscore.find(this.rawSearchResults, (dev)=>{ return dev.device_id == deviceID; });
            if(match != undefined){
                this.selection = match;
                this.callOnSelect(match);
                this.selectionCompleted = true;
            }else{
                butils.createToast(this, "Failed Durring Device Selection", "Something went wrong while selecting that device, attempt again... If the problem continues contact support.", "danger", 30);
            }
        },
        getMultiDisplayForm(isType){
            var defaultFormForType = this.$store.getters.getFullStackForm({inspecting: isType});
            if(defaultFormForType == null){
                this.multiDisplayForm = null
            }else if(defaultFormForType.length == 0){
                this.multiDisplayForm = null
            }else{
                this.multiDisplayForm = defaultFormForType[0].id;
            }
        },
        getFormLink(inspectID, isType){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: isType});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + inspectID
            }
        },
        backToResults(){
            this.selection = null;
            this.selectionCompleted = false;
            this.callOnSelect(null);
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.getMultiDisplayForm('devices');
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>