<template>
    <div>
        <div v-if="!(collapseOnUpload && uploadCompleted)" 
            :class="(fileHover) ? `file-drop file-hovering ${(markInvalid) ? `flash-required` : ``}`:`file-drop ${(markInvalid) ? `flash-required` : ``} ${(collapseOnSmallScreen) ? 'd-none d-md-block' : 'd-block'}`" 
            @dragover.prevent @drop.prevent
        >
            <input type="file" ref="file" id="fileupload" style="display:none" @change="selectFile" :accept="acceptTypes" :multiple="multiple"/>
            <div class="d-md-flex align-content-center" @drop="dropFile" @dragover="hovering" @dragenter="hovering" @dragleave="notHovering">
                <div class="align-self-center">
                    <i class="material-icons big_icon ">upload_file</i>
                </div>
                <div class="upload-sides align-self-start flex-fill">

                </div>
                <div class="align-self-center text-center pr-2">
                    <div class="d-flex flex-column flex-xl-row">
                        <div class="fs-1-5">Upload {{title}}</div>
                        <div class="fs-1-25">{{(acceptsText != null && acceptsText != "") ? `(${acceptsText})` : ''}}</div>
                    </div>
                    <span>Drag & Drop or <span class="upload_link" @click="$refs.file.click()">Click Here</span></span>
                </div>
                <div class="upload-sides align-self-start flex-fill">
                </div>
            </div>
            <div v-for="curFile, index in files" :key="`${index}-${fileListUpdate}`" class="d-flex align-content-center" @drop="dropFile" @dragover="hovering" @dragenter="hovering" @dragleave="notHovering">
                <div class="align-self-start flex-fill">

                </div>
                <div class="align-self-center">
                    <i v-if="curFile.status == 'pending'" class="material-icons medium_icon icon_blue">pending</i> 
                    <b-spinner v-if="curFile.status == 'uploading'" variant="primary" label="Uploading"></b-spinner>
                    <i v-if="curFile.status == 'done'" class="material-icons medium_icon icon_green">check</i> 
                    <i v-if="curFile.status == 'error'" class="material-icons medium_icon icon_red">error</i> 
                    <span >{{curFile.name}}</span>
                </div>
                <div class="align-self-start flex-fill">

                </div>
            </div>
        </div>
        <div v-if="!(collapseOnUpload && uploadCompleted) && !uploading" :class="`${(collapseOnSmallScreen) ? 'd-block d-md-none d-lg-none d-xl-none' : 'd-none'}`">
            <b-button
                class="fix-width-100" 
                variant="info"
                @click="$refs.file.click()"
            >
                <div class="d-flex flex-row align-items-center justify-content-around mb-1">
                    <div v-if="files.length > 0"></div>
                    <b-icon-paperclip font-scale="3" class="text-dark"></b-icon-paperclip>
                    <b-badge v-if="files.length > 0" variant="vai-success-darker" pill >{{files.length}}</b-badge>
                </div>
                <span class="text-dark">Add</span><br>
                <span class="text-dark">Attachment</span>
            </b-button>
        </div>
        <div v-if="uploading" :class="`${(collapseOnSmallScreen) ? 'd-block d-md-none d-lg-none d-xl-none' : 'd-none'} px-2`">
            <wait-dots
                title="Uploading"
                titleSize="fs-1"
            ></wait-dots>
        </div>
        <div v-if="(collapseOnUpload && uploadCompleted) && !hideUploadConfirmation" class="d-flex flex-row"  @click="resetForUpload">
            <b-iconstack font-scale="4">
                <b-icon v-if="!allowReset" stacked icon="check-circle" variant="success"></b-icon>
                <b-icon v-else stacked icon="arrow-clockwise" variant="success"></b-icon>
            </b-iconstack>
            <div class="d-flex flex-column pl-5">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <div>
                        <span class="h5">{{title}} Uploaded</span>
                    </div>
                </div>
                <div class="h5">
                    <small>{{files[0].name}}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const butils = require('../libs/basicUtils.js');

import waitDots from './subcomponents/WaitingDots.vue'

export default {
    name: 'UploadFile', 
    components:{
        'wait-dots': waitDots
    },
    props:{
        acceptsText: String,
        acceptTypes: {
            type: String,
            default: ()=>{return "*/*";}
        },
        multiple: {
            type: Boolean,
            default: ()=>{return false;}
        },
        title: {
            type: String,
            default: ()=>{return "File";}
        },
        collapseOnUpload: {
            type: Boolean,
            default: ()=>{return false;}
        },
        useEmit: {
            type: Boolean,
            default: ()=>{return true;}
        },
        uploadCallback: {
            type: Function,
            default: ()=>{ return (input, response)=>{} }
        },
        markInvalid: {
            type: Boolean,
            default: ()=>{return false;}
        },
        includeFileAPIResponse:{
            type: Boolean,
            default: ()=>{return false;}
        },
        allowReset:{
            type: Boolean,
            default: ()=>{return false;}
        },
        collapseOnSmallScreen:{
            type: Boolean,
            default: ()=>{return false}
        },
        hideUploadConfirmation:{
            type: Boolean,
            default: ()=>{return false;}
        }
    },
    data(){
        return{
            fileListUpdate: 0,
            fileHover: false,
            files: [],
            uploadCompleted: false,
            uploading: false
        }
    },
    methods:{
        resetForUpload(){
            if(this.allowReset){
                this.uploadCompleted = false;
                if(!this.multiple){
                    this.files = [];
                }
                this.fileListUpdate++;
            }
        },
        reRenderFileList(){
            this.fileListUpdate++;
        },
        selectFile(event){
            if(this.multiple){
                for (let i = 0; i < event.target.files.length; i++) {
                    var file = event.target.files[i];
                    file.status = "pending";
                    this.files.push(file)
                    this.reRenderFileList();
                }
            }else{
                this.files = [];
                var tmpFile = event.target.files[0];
                tmpFile.status = "pending";
                this.files[0] = tmpFile;
                console.log(this.files[0])
                this.reRenderFileList();
                this.uploadFile(this.files[0], this.perFileCallback);
            }
            this.notHovering();
        },
        dropFile(event){
            if(this.multiple){
                for (let i = 0; i < event.dataTransfer.files.length; i++) {
                    var file = event.dataTransfer.files[i];
                    file.status = "pending";
                    this.files.push(file);
                    this.reRenderFileList();
                }
            }else{
                this.files = [];
                var tmpFile = event.dataTransfer.files[0];
                tmpFile.status = "pending";
                this.files[0] = tmpFile;
                console.log(this.files[0])
                this.reRenderFileList();
                this.uploadFile(this.files[0], this.perFileCallback);
            }
            this.notHovering();
        },
        hovering(){
            this.fileHover = true;
        },
        notHovering(){
            this.fileHover = false;
        },
        uploadFile(file, callback){
            this.uploading = true;
            this.uploadCompleted = false;
            file.status = 'uploading';
            file.keyID = butils.uuidv4();
            var formData = new FormData();
            formData.append("file", file);
            this.reRenderFileList();
            butils.customInstance.fileUpload(300000).post(`${process.env.VUE_APP_FILE_API_BASE_URL}/upload`, formData)
            .then((response)=>{
                this.uploading = false;
                file.status = 'done';
                file.fileID = response.data.result.fileID;
                this.reRenderFileList();
                if(this.useEmit){
                    this.$emit(`uploadCompleted`, response.data.result.fileID);
                }else{
                    if(this.includeFileAPIResponse){
                        this.uploadCallback(response.data.result.fileID, response.data.result);
                    }else{
                        this.uploadCallback(response.data.result.fileID);
                    }
                }
                this.uploadCompleted = true;
            })
            .catch((err)=>{
                this.uploading = false;
                file.status = 'error';
                this.reRenderFileList();
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Disallowed By Endpoint Control', 'Contact your administrator to receive permission to perform file upload', 'warning');
                }else{
                    console.log("An Unknown Error Occured Durring Upload");
                    console.log(err);
                    butils.createToast(this, 'An Error Occured Durring Upload', 'Try uploading again, if the problem persists, contact support', 'danger');
                }
            })
        },
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style lang="scss" scoped>
.fix-width-100{
    width: 100px;
}
.file-drop{
    width: 100%;
    height: 100%;
    min-height: 5rem;
    border-style: dotted;
}
.upload-sides{
    min-height: 5rem;
    height: 100%;
}
.big_icon{
    font-size: 3.5rem;
}
.medium_icon{
    font-size: 1.5rem;
}
.icon_green{
    color: #28a745;
}
.icon_blue{
    color: #17a2b8;
}
.icon_red{
    color: #dc3545;
}
.upload_link{
    text-decoration-line: underline;
    font-weight: bold;
    cursor: pointer;
    color:mediumturquoise;
}
.file-hovering{
    background-color:lightgreen;
}
 
@-webkit-keyframes flash {  
    from { background: #9C0000; }
    to   { background: #9c00003f; }
}

@keyframes flash {  
    from { background: #9C0000; }
    to   { background: #9c00003f; }
}

.flash-required{
    -webkit-animation-name: flash;
    -webkit-animation-duration: 0.9s;

    animation-name: flash;
    animation-duration: 0.9s;
    background-color: #9c00003f;
}
</style>