<template>
    <div>
        <b-modal 
            v-model="modalShow" 
            :id="'modal-change-parent-' + ownUniqueID"
            size="xl" 
            :title="`Move this ${singularTypeName} to a different ${parentSingularTypeName}`"
            @ok="handleOk"
        
        >
            <b-row class='mb-3'>
                <b-col>
                    This will move this {{singularTypeName}} along with any children and events.
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <parent-api-select
                        :dataName="'parentSearchAndSelection'"
                        :inputLabel="'Select the new parent ' + parentSingularTypeName"
                        :parentFormID="ownUniqueID"
                        :required="true"
                        :parentType="parentIDType"
                        :value="null"
                        :change="parentSelected"
                    ></parent-api-select>
                </b-col>
            </b-row>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="cancel()" variant="secondary">Cancel</b-button>
                <b-button @click="ok()" :disabled="newParentID == null" variant="primary">Move {{singularTypeName}}</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const _ = require('underscore');

import ParentSelect from '@/components/FormParentSelection.vue'


export default {
    name: 'changeparent',
    components:{
        'parent-api-select': ParentSelect
    },
    props:{
        attachedToType: String,
        attachedToID: String,
        requestChangeParent: Number
    },
    data(){
        return{ 
            ownUniqueID: uuidv4(),
            modalShow: false,
            singularTypeName: null,
            parentSingularTypeName: null,
            parentIDType: null,
            newParentID: null,
            ownIDdataName: null,
            parentIDdataName: null
        }
    },
    methods: {
        handleOk(){
            console.log("Closed Modal")
            if(this.newParentID != null){
                this.performUpdate();
            }
        },
        openMoveModal(){
            this.modalShow = true;
        },
        parentSelected(pid){
            console.log("Parent ID Selected")
            console.log(pid);
            this.newParentID = pid;
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        },
        performUpdate(){
            var updatePack = {};
            updatePack[this.parentIDdataName] = this.newParentID;
            updatePack[this.ownIDdataName] = this.attachedToID;
            console.log(updatePack);
            instance.post(process.env.VUE_APP_API_BASE_URL + `/${this.attachedToType}/${this.attachedToID}.json`, updatePack)
            .then((response)=>{
                console.log(response.data);
                this.makeToast('Parent Update Success', `The ${this.singularTypeName}'s parent was updated successfully`, 'success');
            })
            .catch((err)=>{
                console.log(err);
                this.makeToast('Failure', `Failed To Update ${this.singularTypeName} Parent`, 'danger');
            })
        }
    },
    watch: { 
        requestChangeParent: function (newVal, oldVal){
            console.log("Change Parent Receives Open Request")
            if(newVal != null){
                this.openMoveModal();
            }else{
                // Its Null Do Nothing
            }
        }
    },
    computed: {
    },
    beforeCreate(){
        
    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    beforeMount (){
        this.$store.commit('initForm',{ localFormID: this.ownUniqueID, meta: {} });
    },
    mounted(){
        if(this.attachedToType == 'devices'){
            this.singularTypeName = 'Device';
            this.parentIDType = 'connections';
            this.parentSingularTypeName = 'Connection';
            this.ownIDdataName = 'device_id';
            this.parentIDdataName = 'connection_id';
        }else if(this.attachedToType == 'connections'){
            this.singularTypeName = 'Connection';
            this.parentIDType = 'sites';
            this.parentSingularTypeName = 'Site';
            this.ownIDdataName = 'connection_id';
            this.parentIDdataName = 'site_id';
        }else if(this.attachedToType == 'sites'){
            this.singularTypeName = 'Site';
            this.parentIDType = 'customers';
            this.parentSingularTypeName = 'Customer';
            this.ownIDdataName = 'site_id';
            this.parentIDdataName = 'customer_id';
        }else if(this.attachedToType == 'customers'){
            this.singularTypeName = 'Customer';
            this.parentIDType = null;
            this.parentSingularTypeName = null;
        }
    }
}
</script>

<style scoped>
</style>