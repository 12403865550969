<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-select v-model="selectedYear" class="my-1" @change="loadYearActivities">
                    <b-form-select-option :value="2022">Year To Date 2022</b-form-select-option>
                    <b-form-select-option :value="2021">2021</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        
        <b-row class="border" no-gutters>
            <b-col cols="4" class="d-flex border" align-self="stretch" >
                <div class="align-self-center p-1 font-weight-bold w-100 text-center">Category of Service Connections</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center p-1 font-weight-bold w-100 text-center">Number Being Served Water</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center p-1 font-weight-bold w-100 text-center">Number with an AG at or for the Service Connection</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center p-1 font-weight-bold w-100 text-center">Number with a DC, DCDA, PVB, RP, or RPDA at or for the Service Connection</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center p-1 font-weight-bold w-100 text-center">Number with a DuC at or for the Service Connection</div>
            </b-col>
            <b-col cols="12" class="border ml-1">
                <span class="font-weight-bold"><span class="mr-2">A.</span> <u>Non-residential</u></span> service connections
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">1.</span> Standard service connections</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.dual}}</div>
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">2.</span> Dedicated irrigation service connections</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResIrr.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResIrr.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResIrr.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResIrr.dual}}</div>
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">3.</span> Dedicated fire service connections</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResFire.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResFire.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResFire.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResFire.dual}}</div>
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">4.</span> Total non-residential service connections<br>
                <span class="pl-4">(A.1. + A.2. + A.3.)</span></div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.total + nonResIrr.total + nonResFire.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.ag + nonResIrr.ag + nonResFire.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.assorted + nonResIrr.assorted + nonResFire.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{nonResStd.dual + nonResIrr.dual + nonResFire.dual}}</div>
            </b-col>
            <b-col cols="12" class="border ml-1">
                <span class="font-weight-bold"><span class="mr-2">B.</span> <u>Residential</u></span> service connections
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">1.</span> Standard service connections</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.dual}}</div>
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">2.</span> Dedicated irrigation service connections</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resIrr.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resIrr.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resIrr.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resIrr.dual}}</div>
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">3.</span> Dedicated fire service connections</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resFire.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resFire.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resFire.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resFire.dual}}</div>
            </b-col>
            <b-col cols="4" class="border" align-self="stretch" >
                <div class="align-self-center w-100 pl-3"><span class="mr-2">4.</span> Total non-residential service connections<br>
                <span class="pl-4">(A.1. + A.2. + A.3.)</span></div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.total + resIrr.total + resFire.total}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.ag + resIrr.ag + resFire.ag}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.assorted + resIrr.assorted + resFire.assorted}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{resStd.dual + resIrr.dual + resFire.dual}}</div>
            </b-col>
            <b-col cols="12" class="border">
                <span class="font-weight-bold ml-1">Testing & Inspection Activities</span>
            </b-col>
            <b-col cols="10" class="border">
                <div class="ml-1"><span class="mr-2 font-weight-bold">A.</span> AGs inspected in {{curYear}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{inspectionAndTesting.ag}}</div>
            </b-col>
            <b-col cols="10" class="border">
                <div class="ml-1"><span class="mr-2 font-weight-bold">B.</span> Non-Residential</span> DCs, DCDAs, PVBs, RPs, & RPDAs Tested in {{curYear}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{inspectionAndTesting.nonResTests}}</div>
            </b-col>
            <b-col cols="10" class="border">
                <div class="ml-1"><span class="mr-2 font-weight-bold">C.</span> Residential</span> DCs, DCDAs, PVBs, RPs, & RPDAs Tested in {{curYear}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{inspectionAndTesting.resTests}}</div>
            </b-col>
            <b-col cols="10" class="border">
                <div class="ml-1"><span class="mr-2 font-weight-bold">D.</span> Residential</span> DuCs replaced in {{curYear}}</div>
            </b-col>
            <b-col cols="2" class="d-flex border" align-self="stretch">
                <div class="align-self-center w-100 text-center">{{inspectionAndTesting.dualReplacements}}</div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;

export default {
    name: 'EXECUTIVEDASHBOARD',
    components:{

    },
    props:{

    },
    data(){
        return{
            curYear: null,
            selectedYear: 2022,
            loading: {
                nonResStd: false,
                resStd: false,
                nonResFire: false,
                resFire: false,
                nonResIrr: false,
                resIrr: false,
                inspectionAndTesting: false,
            },
            nonResStd:{
                total: 0,
                ag: 0,
                dual: 0,
                assorted: 0,
                none: 0
            },
            resStd:{
                total: 0,
                ag: 0,
                dual: 0,
                assorted: 0,
                none: 0
            },
            nonResFire:{
                total: 0,
                ag: 0,
                dual: 0,
                assorted: 0,
                none: 0
            },
            resFire:{
                total: 0,
                ag: 0,
                dual: 0,
                assorted: 0,
                none: 0
            },
            nonResIrr:{
                total: 0,
                ag: 0,
                dual: 0,
                assorted: 0,
                none: 0
            },
            resIrr:{
                total: 0,
                ag: 0,
                dual: 0,
                assorted: 0,
                none: 0
            },
            inspectionAndTesting:{
                ag: 0,
                resTests: 0,
                nonResTests: 0,
                dualReplacements: 0
            }
        }
    },
    methods:{
        loadYearActivities(){
            this.curYear = this.selectedYear;
            this.loading.inspectionAndTesting = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/reporting/insp_testing/${this.selectedYear}`)
            .then((response)=>{
                this.inspectionAndTesting = response.data.result;
                this.loading.inspectionAndTesting = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed Durring Dashboard Data Fetch", "Something went wrong while fetching the inspection and testing statistics", "danger", 30);
                }
                this.loading.inspectionAndTesting = false;
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.loading.nonResStd = true;
        this.loading.nonResFire = true;
        this.loading.nonResIrr = true;
        butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/reporting/non_residential`)
        .then((response)=>{
            this.nonResStd = response.data.result.standard;
            this.nonResFire = response.data.result.fire;
            this.nonResIrr = response.data.result.irrigation;
            this.loading.nonResStd = false;
            this.loading.nonResFire = false;
            this.loading.nonResIrr = false;
        })
        .catch((err)=>{
            if(butils.isError401(err)){
                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
            }else if(butils.isError403(err)){
                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
            }else{
                console.log(err)
                butils.createToast(this, "Failed Durring Dashboard Data Fetch", "Something went wrong while fetching the non residential service connection info", "danger", 30);
            }
            this.loading.nonResStd = false;
            this.loading.nonResFire = false;
            this.loading.nonResIrr = false;
        })

        this.loading.resStd = true;
        this.loading.resFire = true;
        this.loading.resIrr = true;
        butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/reporting/residential`)
        .then((response)=>{
            this.resStd = response.data.result.standard;
            this.resFire = response.data.result.fire;
            this.resIrr = response.data.result.irrigation;
            this.loading.resStd = false;
            this.loading.resFire = false;
            this.loading.resIrr = false;
        })
        .catch((err)=>{
            if(butils.isError401(err)){
                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
            }else if(butils.isError403(err)){
                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
            }else{
                console.log(err)
                butils.createToast(this, "Failed Durring Dashboard Data Fetch", "Something went wrong while fetching the residential service connection info", "danger", 30);
            }
            this.loading.resStd = false;
            this.loading.resFire = false;
            this.loading.resIrr = false;
        })

        this.loadYearActivities();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
</style>