<template>
    <div>
        <h5 v-if="showAnything"><b-badge :variant="badgeVariant">{{badgeContent}}</b-badge></h5>
        <em v-if="showAnything && showLower">{{badgeLower}}</em>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');

export default {
    name: 'OutstandingCompNotice',
    components:{

    },
    props:{
        deviceID: {
            type: String,
            default: ()=>{ return null; }
        }
    },
    data(){
        return{
            showAnything: false,
            showLower: false,
            badgeVariant: 'info',
            badgeContent: 'Loading Notice Info',
            badgeLower: ''
        }
    },
    methods:{
        getNoticeInfo(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/atils/outstanding_compliance_notice/device/${this.deviceID}`)
            .then((response)=>{
                if(response.status == 201){
                    // No Outstanding Complaince Notice
                    this.badgeVariant = 'success';
                    this.badgeContent = `No Outstanding Notice`
                    this.showAnything = true;
                }else{
                    var raw = response.data.result;
                    var sentOn = butils.formatters.timestampToDateTime(raw.added_on, false, false)
                    var responseDue = butils.formatters.timestampToDateTime(raw.expect_response_by, false, false)
                    if(raw.overdue){
                        this.badgeVariant = 'danger';
                        this.badgeContent = `Unresponded Notice`;
                        this.badgeLower = `'${raw.notice_name}' Notice Was Sent Via ${raw.proper_type} On ${sentOn}. Response was expected by: ${responseDue}.`
                    }else{
                        this.badgeVariant = 'warning';
                        this.badgeContent = `Outstanding Notice`;
                        this.badgeLower = `'${raw.notice_name}' Notice Was Sent Via ${raw.proper_type} On ${sentOn}. Response is expected by: ${responseDue}`
                    }
                    
                    this.showLower = true;
                    this.showAnything = true;
                }
            })
            .catch((err)=>{
                 if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permissions To Check Outstanding Compliance Notice Denied By Endpoint Control', 'Contact your administrator to receive permission to perform portal ruleset management', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Fetching Compliance Notice Status', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        if(this.deviceID != null){
            this.getNoticeInfo();
        }
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>