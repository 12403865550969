<template>
<b-form-group 
    :id="'fieldset-horizontal-label-' + parentFormID + '-' + index + '-' + dataName"
    label-cols-sm="4"
    label-cols-lg="3"
    description=""
    :label="inputLabel"
    :label-size="size"
    :label-for="'input-horizontal-input-' + parentFormID + '-' + index + '-' + dataName"
    :invalid-feedback="invalidFeedback"
    :state="validationResult.all"
>
    <b-input-group>
        <template v-if="(prependComponent != null)" v-slot:prepend>
            <component 
                :is="prependComponent"
                :touch="prependInput"
                :size="size"
            ></component>
        </template>

        <b-row no-gutters>

            <b-col cols="12">
                <!-- Number -->
                <b-form-group
                    id="fieldset-backflow-number"
                    description="Certification Number"
                    label-for="backflow-number"
                >
                    <b-form-input id="backflow-number" :size="size" v-model="inputValue.backflow_tester.num" @input="touch($event,'number')" type="text" trim></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="12" >
                <!-- Number -->
                <b-form-group
                    id="fieldset-backflow-date"
                    description="Certification Date"
                    label-for="backflow-date"
                >
                   
                    <b-form-input id="backflow-date" 
                        :size="size" 
                        v-model="certificationDate" 
                        @input="touch($event,'date')" 
                        
                        type="date" 
                        min="2000-01-01" max="2050-01-01"
                        trim></b-form-input>                

                </b-form-group>
            </b-col>


            <b-col cols="12">
                <!-- Number -->
                <b-form-group
                    id="fieldset-backflow-school"
                    description="School"
                    label-for="backflow-school"
                >
                    <b-form-input id="backflow-school" :size="size" v-model="inputValue.backflow_tester.school" @input="touch($event,'school')" type="text" trim></b-form-input>
                </b-form-group>
            </b-col>

        </b-row>

        <template v-if="(appendComponent != null)" v-slot:append>
            <component 
                :is="appendComponent"
                :touch="appendInput"
                :size="size"
            ></component>
        </template>
    </b-input-group>
    
</b-form-group > 
    
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
var dayjs = require('dayjs');
var customParseFormat = require("dayjs/plugin/customParseFormat");

import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'



export default {
    name: 'formcertinput',
    components:{
        'btn-add': MicroArrayAddButton,
        'btn-remove': MicroArrayRemoveButton,
    },
    props:{
        //bus: Object,
        dataName: String,
        inputLabel: String,
        parentFormID: String,
        value: {
            type: Object,
            default: () => {return ""}
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        appendComponent: {
            type: String,
            default: () => {return null} 
        },
        prependComponent: {
            type: String,
            default: () => {return null} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        }
    },
    data(){
        return{
            inputValue: {
                backflow_tester: {
                    num: '',
                    date: '',                    
                    school: ''
                }
            },           
            certificationDate: '',
            originalValue: '',
            isDirty: false,
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.cleanedInput, valid: this.validationResult.all, isDirty: this.isDirty} );
        },
        appendInput(){
            if(this.appendComponent == 'btn-add'){
                // we are adding an item
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
                this.inputValue = "";
                this.isDirty = false;
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.cleanedInput, valid: this.validationResult.all, isDirty: this.isDirty} );
            }else if(this.appendComponent == 'btn-remove'){
                // We are removing an item
                this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
            }else{
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.cleanedInput, valid: this.validationResult.all, isDirty: this.isDirty} );
            }
        },
        prependInput(){
            this.$store.commit('formPrepend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
        },
        inputFormat(input){
            return input;
        },
        cleanInput(input){
            return input;
        }
    },
    watch: { 
        index: function(newVal, oldVal) { // watch it
            console.log('Index Changed: ', newVal, ' | was: ', oldVal)
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {            
            var requiredRes = ( (this.required) ? false : true) ;
            return { requirementFilled: requiredRes};
        },
        invalidFeedback(){
            var res = this.validationResult;
            
            return 'OK';
        },
        cleanedInput(){

            try {
                this.inputValue.backflow_tester.date = dayjs(this.certificationDate).format("MM/DD/YYYY");
            } catch (error) {
                console.log(error);
            }

            return this.inputValue;
        }
    },
    mounted(){
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.cleanedInput, valid: this.validationResult.all} );
    },
    created(){
    },
    beforeDestroy(){
        if(this.index == -1){
            this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
        }
    },
    beforeMount (){
        this.originalValue = cloneDeep(this.value);
        this.inputValue = this.inputFormat(this.value);
     
        var parsedDate = dayjs(this.inputValue.backflow_tester.date, "MM/DD/YYYY");
        this.certificationDate = parsedDate.format("YYYY-MM-DD");  // the date input requires YYYY-MM-DD format
    },
}
</script>

<style>

</style>


