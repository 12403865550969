<template>
<b-row class="my-0">
    <b-col cols="12" class="my-0">
        <span v-if="value != '' && value !=  null" :class="`section_header_${size}`">{{value}}</span>
        <span v-else :class="`section_header_${size}`">{{inputLabel}}</span>
    </b-col>
</b-row>
    
</template>

<script>
export default {
    name: 'formsectionheader',
    components:{
    },
    props:{
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        },
        inputLabel: {
            type: String,
            default: () => {return ""}
        },
        value: {
            type: String,
            default: () => {return ""}
        }
    },
    data(){
        return{
            inputValue: '',
        }
    },
    methods: {
    },
    computed: {
        
    },
    mounted(){
    },
    created(){
    },
    beforeDestroy(){
    },
    beforeMount (){
    },
}
</script>

<style scoped>
span.section_header_sm{
    font-size: 1.25rem;
    font-weight: bold;
}
span.section_header_md{
    font-size: 1.5rem;
    font-weight: bold;
}
span.section_header_lg{
    font-size: 2.0rem;
    font-weight: bold;
}
</style>


