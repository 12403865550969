<template>
    <div>
        <b-sidebar id="matcher-sidebar" :title="`Portal Submission Management`" v-model="sidebarOpen" shadow width="100%">
            <b-overlay 
                :no-wrap="loading"
                :show="submitPending || loading"
                :opacity="0.85"
                :blur="'2px'"
                rounded="sm"
            >
                <template #overlay>
                    <div class="fixed-top">
                        <div class="float-right">
                            <i class="material-icons text-vai-danger-dark bg-vai-secondary-light" @click="closeSidebar">close</i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center mt-4">
                        <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                        <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                        <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                    </div>
                    <div class="d-flex justify-content-center h3 mt-4">
                        <span v-if="loading">Vai Processing...</span>
                        <span v-else>Importing</span>
                    </div>
                    <div class="d-flex justify-content-center h3 mt-4">
                        <span v-if="loading">{{vaiMessage}}</span>
                    </div>
                </template>
                <div class="px-3 py-2" v-if="!loading">
                    <b-row>
                        <b-col xl="4" lg="5" md="12">
                            <span class="h5">Submission From {{dataRep.sent_by_user_name}} at {{dataRep.sent_by_account_name}}</span>
                            <b-row class="mt-2 mb-3">
                                <b-col cols="6">
                                    <b-button block variant="danger" @click="openRejectModal();">Reject Submission</b-button>
                                </b-col>
                                <b-col cols="6">
                                    <b-button block variant="success" v-if="showAcceptButton" @click="openChangesModal();">Review Changes & Accept</b-button>
                                    <b-button block variant="success" v-else disabled>Resolve Issues To Continue</b-button>
                                </b-col>
                            </b-row>
                            <div v-for="(rec, index) in data" :key="`data-row-` + index + '-' + rec.hasPDF">
                                <b-card no-body class="overflow-hidden mb-1">
                                    <b-row no-gutters>
                                        <b-col sm="12" md="8">
                                            <b-card-body>
                                                <b-card-title>
                                                    {{rec.event_name}} 
                                                </b-card-title>
                                                <b-card-text v-if="rec.event_name == 'BFPA Test' && rec.event_json.data.testOf == 'Previous Device'">
                                                    <strong>On Old Device: </strong><br>{{dataRep.event_json.data.event_parent_data.device.size}}" {{dataRep.event_json.data.event_parent_data.device.manufacturer}} {{dataRep.event_json.data.event_parent_data.device.type}} {{dataRep.event_json.data.event_parent_data.device.model}} {{dataRep.event_json.data.event_parent_data.device.serial}}
                                                </b-card-text>
                                                <b-card-text v-if="hasRepOrInst && rec.event_name == 'BFPA Test' && rec.event_json.data.testOf == 'New Device'">
                                                    <strong>On New Device: </strong><br>{{dataRep.event_json.data.event_parent_data.newDevice.size}}" {{dataRep.event_json.data.event_parent_data.newDevice.manufacturer}} {{dataRep.event_json.data.event_parent_data.newDevice.type}} {{dataRep.event_json.data.event_parent_data.newDevice.model}} {{dataRep.event_json.data.event_parent_data.newDevice.serial}}
                                                </b-card-text>
                                                <b-card-text v-if="rec.event_name == 'BFPA Test'" class="h4">
                                                    <b-badge v-if="rec.event_json.data.final_test_result == 'PASS'" variant="vai-success-darker">{{rec.event_json.data.final_test_result}}</b-badge>
                                                    <b-badge v-if="rec.event_json.data.final_test_result == 'FAIL'" variant="danger">{{rec.event_json.data.final_test_result}}</b-badge>
                                                    <b-badge v-if="rec.event_json.data.final_test_result == 'REPAIRPASS'" variant="vai-warning-darker">{{rec.event_json.data.final_test_result}}</b-badge>
                                                    <b-badge v-if="rec.event_json.data.final_test_result == 'NOTEST'" variant="vai-secondary-medium">{{rec.event_json.data.final_test_result}}</b-badge> 
                                                </b-card-text>
                                                <b-card-text v-if="rec.event_name == 'BFPA Test'">
                                                    <b-button v-if="rec.hasPDF" class="badge badge-primary" size="sm" @click="displayPDF(rec)">{{(rec.pdfOpen) ? 'Hide' : 'View'}} PDF Report</b-button>
                                                    <b-badge v-if="!rec.hasPDF" variant="danger">No Official PDF Report</b-badge>
                                                </b-card-text>
                                                <b-card-text v-if="hasRepOrInst && rec.event_name == 'BFPA Replacement'">
                                                    <strong>Replaced Device: </strong><br>{{rec.event_json.data.event_parent_data.device.size}}" {{rec.event_json.data.event_parent_data.device.manufacturer}} {{rec.event_json.data.event_parent_data.device.type}} {{rec.event_json.data.event_parent_data.device.model}} {{rec.event_json.data.event_parent_data.device.serial}}
                                                    <br>
                                                    <br>
                                                    <strong>With Device: </strong><br>{{rec.event_json.data.event_parent_data.newDevice.size}}" {{rec.event_json.data.event_parent_data.newDevice.manufacturer}} {{rec.event_json.data.event_parent_data.newDevice.type}} {{rec.event_json.data.event_parent_data.newDevice.model}} {{rec.event_json.data.event_parent_data.newDevice.serial}}
                                                </b-card-text>
                                                <b-card-text v-if="rec.event_name == 'BFPA Install'">
                                                    <strong>Installed Device: </strong><br>{{rec.event_json.data.event_parent_data.device.size}}" {{rec.event_json.data.event_parent_data.device.manufacturer}} {{rec.event_json.data.event_parent_data.device.type}} {{rec.event_json.data.event_parent_data.device.model}} {{rec.event_json.data.event_parent_data.device.serial}}
                                                </b-card-text>
                                            </b-card-body>
                                        </b-col>
                                        <b-col sm="12" md="4" v-if="rec.featuredMedia.id != null">
                                            <b-card-img :src="rec.featuredMedia.url" alt="Image" class="rounded-0 featured-media"></b-card-img>
                                        </b-col>
                                    </b-row>
                                    <b-row no-gutters>
                                        <b-col cols="12">
                                            <b-collapse class="mt-2 pdf-viewer-collapse" v-model="rec.pdfOpen">
                                                <div class="pdf-viewer" :id="`pdf-viewer-${rec.id}`"></div>
                                            </b-collapse>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </div>
                        </b-col>
                        <b-col xl="8" lg="7" md="12">
                            <b-button-toolbar justify class="mt-3">
                                <b-button v-if="activeScreen == 'Main'" variant="warning" @click="backOrExit();">
                                    <b-icon-x></b-icon-x> Exit
                                </b-button>
                                <b-button v-else variant="warning" @click="backOrExit();">
                                    <b-icon-arrow-left></b-icon-arrow-left> Back
                                </b-button>
                            </b-button-toolbar>
                            <hr>
                            <div v-if="activeScreen=='Main'">
                                <span v-if="!showAcceptButton" class="h4">Issues</span>
                                <span v-if="showAcceptButton" class="h4">No Issues</span>
                                <b-list-group class="ml-5 mb-3">
                                    <b-list-group-item 
                                        v-if="!selectedTechnicianFetched && !createNewTech" 
                                        class="mb-1 d-flex justify-content-between align-items-center text-danger bg-light"
                                        button
                                        :active="technicianSectionOpen"
                                    >
                                        Matching Technician Not Found
                                        <b-badge variant="vai-secondary-darker" class="text-light" pill>Click To Resolve</b-badge>
                                    </b-list-group-item>
                                    <b-list-group-item 
                                        v-if="!selectedDeviceFetched" 
                                        class="mb-1 d-flex justify-content-between align-items-center text-danger bg-light"
                                        button
                                        @click="openDevice"
                                    >
                                        Matching Device Not Found
                                        <b-badge variant="vai-secondary-darker" class="text-light" pill>Click To Resolve</b-badge>
                                    </b-list-group-item>
                                </b-list-group>
                                <span v-if="(hazardMatch == null || hazardMatch.id == null) || (hasRepOrInst && (newDeviceHazardMatch == null || newDeviceHazardMatch.id == null))" class="h4">Non Critical Issues</span>
                                <b-list-group class="ml-5 mb-3" v-if="(hazardMatch == null || hazardMatch.id == null) || (hasRepOrInst && (newDeviceHazardMatch == null || newDeviceHazardMatch.id == null))">
                                    <b-list-group-item 
                                        v-if="hazardMatch == null || hazardMatch.id == null" 
                                        class="mb-1 d-flex justify-content-between align-items-center text-danger bg-light"
                                        button
                                        @click="openHazardMatch"
                                    >
                                        Unrecognized Hazard Type
                                        <b-badge variant="vai-secondary-darker" class="text-light" pill>Click To Resolve</b-badge>
                                    </b-list-group-item>
                                    <b-list-group-item 
                                        v-if="hasRepOrInst && (newDeviceHazardMatch == null || newDeviceHazardMatch.id == null)" 
                                        class="mb-1 d-flex justify-content-between align-items-center text-danger bg-light"
                                        button
                                        @click="openNewHazardMatch"
                                    >
                                        Unrecognized Hazard Type On Installed Device
                                        <b-badge variant="vai-secondary-darker" class="text-light" pill>Click To Resolve</b-badge>
                                    </b-list-group-item>
                                </b-list-group>
                                <span class="h4">Resolved</span>
                                <b-list-group class="ml-5">
                                    <b-card 
                                        no-body 
                                        class="mb-1 overflow-hidden"
                                        v-if="hasRepOrInst && newDeviceHazardMatch != null && newDeviceHazardMatch.id != null"
                                        header-bg-variant="vai-success-lighter"
                                        header-text-variant="black"
                                    >
                                        <template #header>
                                            <h5 class="mb-0">Installed Device Hazard Matched</h5>
                                        </template>
                                        <b-row no-gutters>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <table style="width:100%">
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Hazard Type:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{newDeviceHazardMatch.value}}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </b-card-body>
                                            </b-col>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <b-button-group vertical>
                                                        <b-button
                                                            variant="warning"
                                                            @click="openNewHazardMatch"
                                                        >
                                                            Change Selection
                                                        </b-button>
                                                    </b-button-group>
                                                </b-card-body>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                    <b-card 
                                        no-body 
                                        class="mb-1 overflow-hidden"
                                        v-if="hazardMatch != null && hazardMatch.id != null"
                                        header-bg-variant="vai-success-lighter"
                                        header-text-variant="black"
                                    >
                                        <template #header>
                                            <h5 class="mb-0">Device Hazard Matched</h5>
                                        </template>
                                        <b-row no-gutters>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <table style="width:100%">
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Hazard Type:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{hazardMatch.value}}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </b-card-body>
                                            </b-col>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <b-button-group vertical>
                                                        <b-button
                                                            variant="warning"
                                                            @click="openHazardMatch"
                                                        >
                                                            Change Selection
                                                        </b-button>
                                                    </b-button-group>
                                                </b-card-body>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                    <b-card 
                                        no-body 
                                        class="mb-1 overflow-hidden"
                                        v-if="selectedTechnicianFetched"
                                        header-bg-variant="vai-success-lighter"
                                        header-text-variant="black"
                                    >
                                        <template #header>
                                            <h5 class="mb-0">Technician Selected</h5>
                                        </template>
                                        <b-row no-gutters>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <table style="width:100%">
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Name:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{selectedTechnician.tech_first_name}} {{selectedTechnician.tech_last_name}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Company:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{selectedTechnician.tech_company}}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </b-card-body>
                                            </b-col>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <b-button-group vertical>
                                                        <b-button
                                                            variant="warning"
                                                            @click="openTech"
                                                        >
                                                            Change Selection
                                                        </b-button>
                                                        <b-button
                                                            variant="info"
                                                            target="_blank"
                                                            :href="getFormLink(selectedTechnician.id, 'technicians')"
                                                        >
                                                            View This Technician (New Tab)
                                                        </b-button>
                                                    </b-button-group>
                                                </b-card-body>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                    <b-card 
                                        no-body 
                                        class="overflow-hidden"
                                        v-if="createNewTech"
                                        header-bg-variant="vai-success-lighter"
                                        header-text-variant="black"
                                    >
                                        <template #header>
                                            <h5 class="mb-0">Creating New Technician</h5>
                                        </template>
                                        <b-row no-gutters>
                                            <b-col md="8">
                                                <b-card-body>
                                                    <table style="width:100%">
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Name:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{dataRep.event_json.data.technician_data.tech_first_name}} {{dataRep.event_json.data.technician_data.tech_last_name}} 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Company:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{dataRep.event_json.data.technician_data.tech_company}} 
                                                            </td>
                                                        </tr>
                                                        <tr v-if="dataRep.event_json.data.technician_data.tech_phone != null && dataRep.event_json.data.technician_data.tech_phone.length > 0">
                                                            <td class="align-text-top border-0">
                                                                <strong>Phone:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{dataRep.event_json.data.technician_data.tech_phone.join(', ')}} 
                                                            </td>
                                                        </tr>
                                                        <tr v-if="dataRep.event_json.data.technician_data.tech_email != null && dataRep.event_json.data.technician_data.tech_email.length > 0">
                                                            <td class="align-text-top border-0">
                                                                <strong>Email:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{dataRep.event_json.data.technician_data.tech_email.join(', ')}} 
                                                            </td>
                                                        </tr>
                                                        <tr v-if="dataRep.event_json.data.technician_data.certs.backflow_tester != undefined">
                                                            <td class="align-text-top border-0">
                                                                <strong>Treeo Cert:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{dataRep.event_json.data.technician_data.certs.backflow_tester.num}} (Date: {{dataRep.event_json.data.technician_data.certs.backflow_tester.date}})
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Gauge:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{dataRep.event_json.data.technician_data.test_kit_manufacturer}} {{dataRep.event_json.data.technician_data.test_kit_model}} {{dataRep.event_json.data.technician_data.test_kit_serial}} (Calibrated: {{dataRep.event_json.data.technician_data.test_kit_cal_date}}) 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </b-card-body>
                                            </b-col>
                                            <b-col>
                                                <b-card-body>
                                                    <b-button-group vertical>
                                                        <b-button
                                                            variant="warning"
                                                            @click="openTech"
                                                        >
                                                            Change Selection
                                                        </b-button>
                                                    </b-button-group>
                                                </b-card-body>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                    <b-card 
                                        no-body 
                                        class="overflow-hidden"
                                        v-if="selectedDeviceFetched"
                                        header-bg-variant="vai-success-lighter"
                                        header-text-variant="black"
                                    >
                                        <template #header>
                                            <h5 class="mb-0">Device Selected</h5>
                                        </template>
                                        <b-row no-gutters>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <table style="width:100%">
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Device Model:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{device.device_size}}" {{device.device_model}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="align-text-top border-0">
                                                                <strong>Device Serial:</strong>
                                                            </td>
                                                            <td class="align-text-top border-0">
                                                                {{device.device_serial}} 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </b-card-body>
                                            </b-col>
                                            <b-col md="6">
                                                <b-card-body>
                                                    <b-button-group vertical>
                                                        <b-button
                                                            variant="warning"
                                                            @click="openDevice"
                                                        >
                                                            Change Selection
                                                        </b-button>
                                                        <b-button
                                                            variant="info"
                                                            target="_blank"
                                                            :href="getFormLink(device.device_id, 'devices')"
                                                        >
                                                            View This Device (New Tab)
                                                        </b-button>
                                                    </b-button-group>
                                                </b-card-body>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                </b-list-group>
                            </div>
                            <div :class="(activeScreen=='Device') ? 'd-block' : 'd-none'">
                                <b-card no-body class="overflow-hidden mb-3">
                                    <template #header>
                                        <h5 class="mb-0">Provided Device Info</h5>
                                    </template>
                                    <b-row no-gutters>
                                        <b-col sm="12" md="6">
                                            <b-card-body>
                                                <table style="width:100%">
                                                    <tr>
                                                        <td class="align-text-top border-0">
                                                            <strong>Address:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.event_parent_data.site.service_address_number}} 
                                                            {{dataRep.event_json.data.event_parent_data.site.service_address_predirection}} {{dataRep.event_json.data.event_parent_data.site.service_address_street_name}} {{dataRep.event_json.data.event_parent_data.site.service_address_street_suffix}} {{dataRep.event_json.data.event_parent_data.site.service_address_postdirection}} 
                                                            {{dataRep.event_json.data.event_parent_data.site.service_address_building}} {{dataRep.event_json.data.event_parent_data.site.service_address_unit}} {{dataRep.event_json.data.event_parent_data.site.service_address_extra}} <br v-if="dataRep.event_json.data.event_parent_data.site.service_address_line_two != null"> {{dataRep.event_json.data.event_parent_data.site.service_address_line_two}} <br>
                                                            {{dataRep.event_json.data.event_parent_data.site.service_address_city}}, {{dataRep.event_json.data.event_parent_data.site.service_address_state}} {{dataRep.event_json.data.event_parent_data.site.service_address_zip_code}} {{(dataRep.event_json.data.event_parent_data.site.service_address_zip_code_plus_four != null) ? `-${dataRep.event_json.data.event_parent_data.site.service_address_zip_code_plus_four}`  : ""}}{{dataRep.event_json.data.event_parent_data.site.service_address_zip_code_plus_two}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-text-top border-0">
                                                            <strong>Meter Number:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.event_parent_data.connection.meter_number}} 
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-text-top border-0">
                                                            <strong>Model:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.event_parent_data.device.size}}" {{dataRep.event_json.data.event_parent_data.device.manufacturer}} {{dataRep.event_json.data.event_parent_data.device.type}} {{dataRep.event_json.data.event_parent_data.device.model}} 
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-text-top border-0">
                                                            <strong>Serial:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.event_parent_data.device.serial}} 
                                                        </td>
                                                    </tr>
                                                </table>
                                            </b-card-body>
                                        </b-col>
                                        <b-col id="input-device-map" sm="12" md="6" class="bg-dark text-light">
                                            Map Goes Here (When GPS Data Available)
                                        </b-col>
                                    </b-row>
                                </b-card>
                                <b-input-group size="lg">
                                    <b-form-input 
                                        placeholder="Search For This Device In Your Data"
                                        v-model="termSearchDevice"
                                        @keyup.enter.native="searchDevice()"
                                    >
                                    </b-form-input>
                                    <b-input-group-append>
                                        <b-button 
                                            size="sm" 
                                            variant="info"
                                            @click="searchDevice()"
                                        >
                                            <b-icon-search></b-icon-search>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <b-table stacked="sm" small :busy="deviceSearchPending" :items="deviceSearchResults" :fields="deviceResultFields">
                                    <template #table-busy>
                                        <div class="d-flex justify-content-center align-items-center mt-4">
                                            <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                                            <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                                            <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                                        </div>
                                        <div class="d-flex justify-content-center h3 mt-4">
                                            <span>Searching</span>
                                        </div>
                                    </template>
                                    <template #cell(match)="data">
                                        <table style="width:100%" class="table-no-styles">
                                            <colgroup>
                                                <col span="1" style="width: 30%;">
                                                <col span="1" style="width: 70%;">
                                            </colgroup>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Found:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    <span v-html="data.item.grp.match_content"></span> ({{data.item.grp.match_on}})
                                                </td>
                                            </tr>
                                            <tr v-if="data.item.grp.status != null" class="bg-danger text-light">
                                                <td class="align-text-top border-0">
                                                    <strong>Status:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.grp.status}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Address:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.service_address_line_one}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Meter Number:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.meter_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Device Model:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.device_size}}" {{data.item.device_mfg}} {{data.item.device_type}}  {{data.item.device_model}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Device Serial:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.device_serial}} 
                                                </td>
                                            </tr>
                                        </table>
                                    </template>
                                    <template #cell(match_content)="data">
                                        <span v-html="data.item.grp.match_content"></span>
                                    </template>
                                    <template #cell(status)="data">
                                        <div v-if="data.item.grp.status != null" variant="danger">
                                            {{data.item.grp.status}}
                                        </div>
                                    </template>
                                    <template #cell(actions)="data">
                                        <b-button-group vertical>
                                            <b-button
                                                variant="vai-success-relaxed"
                                                @click="selectDeviceID(data.item.grp.id)"
                                            >
                                                Use This Device
                                            </b-button>
                                            <b-button
                                                variant="info"
                                                target="_blank"
                                                :href="getFormLink(data.item.grp.id, 'devices')"
                                            >
                                                View This Device (New Tab)
                                            </b-button>
                                        </b-button-group>
                                        
                                    </template>
                                </b-table>
                            </div>
                            <div :class="(activeScreen=='Tech') ? 'd-block' : 'd-none'">
                                <b-card no-body class="overflow-hidden mb-3">
                                    <template #header>
                                        <h5 class="mb-0">Provided Tech Info</h5>
                                    </template>
                                    <b-row no-gutters>
                                        <b-col md="8">
                                            <b-card-body>
                                                <table style="width:100%">
                                                    <tr>
                                                        <td class="align-text-top border-0">
                                                            <strong>Name:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.technician_data.tech_first_name}} {{dataRep.event_json.data.technician_data.tech_last_name}} 
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-text-top border-0">
                                                            <strong>Company:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.technician_data.tech_company}} 
                                                        </td>
                                                    </tr>
                                                    <tr v-if="dataRep.event_json.data.technician_data.tech_phone != null && dataRep.event_json.data.technician_data.tech_phone.length > 0">
                                                        <td class="align-text-top border-0">
                                                            <strong>Phone:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.technician_data.tech_phone.join(', ')}} 
                                                        </td>
                                                    </tr>
                                                    <tr v-if="dataRep.event_json.data.technician_data.tech_email != null && dataRep.event_json.data.technician_data.tech_email.length > 0">
                                                        <td class="align-text-top border-0">
                                                            <strong>Email:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.technician_data.tech_email.join(', ')}} 
                                                        </td>
                                                    </tr>
                                                    <tr v-if="dataRep.event_json.data.technician_data.certs.backflow_tester != undefined">
                                                        <td class="align-text-top border-0">
                                                            <strong>Treeo Cert:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.technician_data.certs.backflow_tester.num}} (Date: {{dataRep.event_json.data.technician_data.certs.backflow_tester.date}})
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-text-top border-0">
                                                            <strong>Gauge:</strong>
                                                        </td>
                                                        <td class="align-text-top border-0">
                                                            {{dataRep.event_json.data.technician_data.test_kit_manufacturer}} {{dataRep.event_json.data.technician_data.test_kit_model}} {{dataRep.event_json.data.technician_data.test_kit_serial}} (Calibrated: {{dataRep.event_json.data.technician_data.test_kit_cal_date}}) 
                                                        </td>
                                                    </tr>
                                                </table>
                                            </b-card-body>
                                        </b-col>
                                        <b-col>
                                            <b-button class="mt-5" variant="info" @click="requestCreateTech">Create New Technician With This Information</b-button>
                                        </b-col>
                                    </b-row>
                                </b-card>
                                <b-input-group size="lg">
                                    <b-form-input 
                                        placeholder="Search For This Technician In Your Data"
                                        v-model="termSearchTech"
                                        @keyup.enter.native="searchTechnician()"
                                    >
                                    </b-form-input>
                                    <b-input-group-append>
                                        <b-button 
                                            size="sm" 
                                            variant="info"
                                            @click="searchTechnician()"
                                        >
                                            <b-icon-search></b-icon-search>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <b-table stacked="sm" small :busy="techSearchPending" :items="techSearchResults" :fields="deviceResultFields">
                                    <template #table-busy>
                                        <div class="d-flex justify-content-center align-items-center mt-4">
                                            <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                                            <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                                            <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                                        </div>
                                        <div class="d-flex justify-content-center h3 mt-4">
                                            <span>Searching</span>
                                        </div>
                                    </template>
                                    <template #cell(match)="data">
                                        <table style="width:100%" class="table-no-styles">
                                            <colgroup>
                                                <col span="1" style="width: 30%;">
                                                <col span="1" style="width: 70%;">
                                            </colgroup>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Found:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    <span v-html="data.item.grp.match_content"></span> ({{data.item.grp.match_on}})
                                                </td>
                                            </tr>
                                            <tr v-if="data.item.grp.status != null" class="bg-danger text-light">
                                                <td class="align-text-top border-0">
                                                    <strong>Status:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.grp.status}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Name:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.tech_full_name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Company:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.tech_company}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Phone:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.tech_phone.join(', ')}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Email:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.tech_email.join(', ')}} 
                                                </td>
                                            </tr>
                                            <tr v-if="data.item.certs.backflow_tester != undefined">
                                                <td class="align-text-top border-0">
                                                    <strong>Backflow Cert:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.certs.backflow_tester.num}} (Date: {{data.item.certs.backflow_tester.date}})
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-text-top border-0">
                                                    <strong>Gauge:</strong>
                                                </td>
                                                <td class="align-text-top border-0">
                                                    {{data.item.test_kit_manufacturer}} {{data.item.test_kit_model}} {{data.item.test_kit_serial}} (Calibrated: {{data.item.test_kit_cal_date}}) 
                                                </td>
                                            </tr>
                                        </table>
                                    </template>
                                    <template #cell(match_content)="data">
                                        <span v-html="data.item.grp.match_content"></span>
                                    </template>
                                    <template #cell(status)="data">
                                        <div v-if="data.item.grp.status != null" variant="danger">
                                            {{data.item.grp.status}}
                                        </div>
                                    </template>
                                    <template #cell(actions)="data">
                                        <b-button-group vertical>
                                            <b-button
                                                variant="vai-success-relaxed"
                                                @click="setTechnicianFromSearch(data.item)"
                                            >
                                                Use This Technician
                                            </b-button>
                                            <b-button
                                                variant="info"
                                                target="_blank"
                                                :href="getFormLink(data.item.grp.id, 'technicians')"
                                            >
                                                View This Technician (New Tab)
                                            </b-button>
                                        </b-button-group>
                                        
                                    </template>
                                </b-table>
                            </div>
                            <div :class="(activeScreen=='Hazard') ? 'd-block' : 'd-none'">
                                <b-card no-body class="overflow-hidden mb-3">
                                    <template #header>
                                        <h5 class="mb-0">Provided Hazard Info</h5>
                                    </template>
                                    <b-row no-gutters>
                                        <b-col cols="12">
                                            <b-card-body>
                                                <strong>Hazard Type:</strong> {{dataRep.event_json.data.event_parent_data.device.hazard_type}}
                                                <br>
                                                <strong>Selected Hazard Type:</strong> {{hazardMatch.value}}
                                                <br>
                                                <br>
                                                <br>
                                                <api-select
                                                    :dataName="'hazardTypeID'"
                                                    :inputLabel="'Select Hazard Type'"
                                                    :apiEndpoint="'/choices/hazard_types.json?choices=true'"
                                                    :required="false"
                                                    :value="hazardMatch.id"
                                                    :parentFormID="localFormID"
                                                    @onTouch="dynFieldTouched" 
                                                ></api-select>
                                                <br>
                                                <b-button
                                                    variant="vai-success-relaxed"
                                                    @click="backOrExit()"
                                                >
                                                    Done Selecting Hazard Type
                                                </b-button>
                                            </b-card-body>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </div>
                            <div :class="(activeScreen=='New Hazard') ? 'd-block' : 'd-none'"
                                v-if="hasRepOrInst"
                            >
                                <b-card no-body class="overflow-hidden mb-3">
                                    <template #header>
                                        <h5 class="mb-0">Provided New Device Hazard Info</h5>
                                    </template>
                                    <b-row no-gutters>
                                        <b-col cols="12">
                                            <b-card-body>
                                                <strong>Hazard Type:</strong> {{dataRep.event_json.data.event_parent_data.newDevice.hazard_type}}
                                                <br>
                                                <strong>Selected Hazard Type:</strong> {{newDeviceHazardMatch.value}}
                                                <br>
                                                <br>
                                                <br>
                                                <api-select
                                                    :dataName="'newHazardTypeID'"
                                                    :inputLabel="'Select Hazard Type'"
                                                    :apiEndpoint="'/choices/hazard_types.json?choices=true'"
                                                    :required="false"
                                                    :value="newDeviceHazardMatch.id"
                                                    :parentFormID="localFormID"
                                                    @onTouch="dynFieldTouched" 
                                                ></api-select>
                                                <br>
                                                <b-button
                                                    variant="vai-success-relaxed"
                                                    @click="backOrExit()"
                                                >
                                                    Done Selecting Hazard Type
                                                </b-button>
                                            </b-card-body>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </div>
                            <div :class="(activeScreen=='Changes') ? 'd-block' : 'd-none'">
                                <h5>Device Info Changes</h5>
                                <b-row>
                                    <b-col md="2" class="d-none d-sm-none d-md-block">
                                        <b>Field</b>
                                    </b-col>
                                    <b-col md="5" class="d-none d-sm-none d-md-block">
                                        <b>Current Value</b> <b-badge href="#" @click="selectAllCurrentDevice()" variant="info">Select All Current</b-badge>
                                    </b-col>
                                    <b-col md="5" class="d-none d-sm-none d-md-block">
                                        <b>New Value</b> <b-badge href="#" @click="selectAllNewDevice()" variant="info">Select All New</b-badge>
                                    </b-col>
                                </b-row>
                                <b-row v-for="(comp, index) in comparisonData" :key="index + `_comp_obj`">
                                    <b-col cols="12" :class="'d-block d-sm-block d-md-none d-lg-none d-xl-none ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                        <b-row v-if="!comp.isGPS">
                                            <b-col>
                                                <b>Field:</b> <code>{{comp.label}}</code>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="!comp.isGPS">
                                            <b-col>
                                                <b-input-group 
                                                    :size="'sm'"
                                                    prepend="Current"
                                                >
                                                    <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                                    <b-input-group-append is-text>
                                                        <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                            <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                        </b-form-checkbox>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="!comp.isGPS">
                                            <b-col>
                                                <b-input-group 
                                                    :size="'sm'"
                                                    prepend="New"
                                                >
                                                    <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                                    <b-input-group-append is-text>
                                                        <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                            <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                        </b-form-checkbox>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                        <hr>
                                    </b-col>
                                    <b-col md="2" v-if="!comp.isGPS" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                        <b>{{comp.label}}</b>
                                    </b-col>
                                    <b-col md="5" v-if="!comp.isGPS" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                        <b-input-group 
                                            :size="'sm'"
                                        >
                                            <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                            <b-input-group-append is-text>
                                                <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                    <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                </b-form-checkbox>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                    <b-col md="5" v-if="!comp.isGPS" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                        <b-input-group 
                                            :size="'sm'"
                                        >
                                            <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                            <b-input-group-append is-text>
                                                <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                    <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                </b-form-checkbox>
                                            </b-input-group-append>
                                        </b-input-group>
                                        
                                    </b-col>
                                    <b-col md="2" v-if="comp.isGPS" class="d-none d-sm-none d-md-block">
                                        {{comp.label}}
                                    </b-col>
                                </b-row>
                                <h5 class="mt-3">Technician Changes</h5>
                                <div v-if="!createNewTech">
                                    <b-row>
                                        <b-col md="2" class="d-none d-sm-none d-md-block">
                                            <b>Field</b>
                                        </b-col>
                                        <b-col md="5" class="d-none d-sm-none d-md-block">
                                            <b>Current Value</b> <b-badge href="#" @click="selectAllCurrentTech()" variant="info">Select All Current</b-badge>
                                        </b-col>
                                        <b-col md="5" class="d-none d-sm-none d-md-block">
                                            <b>New Value</b> <b-badge href="#" @click="selectAllNewTech()" variant="info">Select All New</b-badge>
                                        </b-col>
                                    </b-row>
                                    <b-row v-for="(comp, index) in technicianComparisonData" :key="index + `_comp_obj`">
                                        <b-col cols="12" :class="'d-block d-sm-block d-md-none d-lg-none d-xl-none ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                            <b-row >
                                                <b-col>
                                                    <b>Field:</b> <code>{{comp.label}}</code>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-input-group 
                                                        :size="'sm'"
                                                        prepend="Current"
                                                    >
                                                        <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                                        <b-input-group-append is-text>
                                                            <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                                <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                            </b-form-checkbox>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-input-group 
                                                        :size="'sm'"
                                                        prepend="New"
                                                    >
                                                        <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                                        <b-input-group-append is-text>
                                                            <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                                <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                            </b-form-checkbox>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-col>
                                            </b-row>
                                            <hr>
                                        </b-col>
                                        <b-col md="2" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                            <b>{{comp.label}}</b>
                                        </b-col>
                                        <b-col md="5" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                            <b-input-group 
                                                :size="'sm'"
                                            >
                                                <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                                <b-input-group-append is-text>
                                                    <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                        <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                    </b-form-checkbox>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-col>
                                        <b-col md="5" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                            <b-input-group 
                                                :size="'sm'"
                                            >
                                                <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                                <b-input-group-append is-text>
                                                    <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                        <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                    </b-form-checkbox>
                                                </b-input-group-append>
                                            </b-input-group>
                                            
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-else>
                                    <span>Creating New Technician</span>
                                </div>
                            </div>
                            
                        </b-col>
                    </b-row>
                    <b-modal 
                        v-model="showChangesModal"
                        title="Review Changes"
                        size="xl"
                        ok-title="Approve"
                        ok-variant="success"
                        @ok="acceptAndSubmit"
                    >
                        <b-card
                            no-body
                        >
                            <b-card-body>
                                <h5>Device Info Changes</h5>
                                <b-row>
                                    <b-col md="2" class="d-none d-sm-none d-md-block">
                                        <b>Field</b>
                                    </b-col>
                                    <b-col md="5" class="d-none d-sm-none d-md-block">
                                        <b>Current Value</b> <b-badge href="#" @click="selectAllCurrentDevice()" variant="info">Select All Current</b-badge>
                                    </b-col>
                                    <b-col md="5" class="d-none d-sm-none d-md-block">
                                        <b>New Value</b> <b-badge href="#" @click="selectAllNewDevice()" variant="info">Select All New</b-badge>
                                    </b-col>
                                </b-row>
                                <b-row v-for="(comp, index) in comparisonData" :key="index + `_comp_obj`">
                                    <b-col cols="12" :class="'d-block d-sm-block d-md-none d-lg-none d-xl-none ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                        <b-row v-if="!comp.isGPS">
                                            <b-col>
                                                <b>Field:</b> <code>{{comp.label}}</code>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="!comp.isGPS">
                                            <b-col>
                                                <b-input-group 
                                                    :size="'sm'"
                                                    prepend="Current"
                                                >
                                                    <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                                    <b-input-group-append is-text>
                                                        <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                            <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                        </b-form-checkbox>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="!comp.isGPS">
                                            <b-col>
                                                <b-input-group 
                                                    :size="'sm'"
                                                    prepend="New"
                                                >
                                                    <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                                    <b-input-group-append is-text>
                                                        <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                            <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                        </b-form-checkbox>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                        <hr>
                                    </b-col>
                                    <b-col md="2" v-if="!comp.isGPS" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                        <b>{{comp.label}}</b>
                                    </b-col>
                                    <b-col md="5" v-if="!comp.isGPS" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                        <b-input-group 
                                            :size="'sm'"
                                        >
                                            <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                            <b-input-group-append is-text>
                                                <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                    <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                </b-form-checkbox>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                    <b-col md="5" v-if="!comp.isGPS" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                        <b-input-group 
                                            :size="'sm'"
                                        >
                                            <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                            <b-input-group-append is-text>
                                                <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                    <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                </b-form-checkbox>
                                            </b-input-group-append>
                                        </b-input-group>
                                        
                                    </b-col>
                                    <b-col md="2" v-if="comp.isGPS" class="d-none d-sm-none d-md-block">
                                        {{comp.label}}
                                    </b-col>
                                </b-row>
                                <h5 class="mt-3">Technician Changes</h5>
                                <div v-if="!createNewTech">
                                    <b-row>
                                        <b-col md="2" class="d-none d-sm-none d-md-block">
                                            <b>Field</b>
                                        </b-col>
                                        <b-col md="5" class="d-none d-sm-none d-md-block">
                                            <b>Current Value</b> <b-badge href="#" @click="selectAllCurrentTech()" variant="info">Select All Current</b-badge>
                                        </b-col>
                                        <b-col md="5" class="d-none d-sm-none d-md-block">
                                            <b>New Value</b> <b-badge href="#" @click="selectAllNewTech()" variant="info">Select All New</b-badge>
                                        </b-col>
                                    </b-row>
                                    <b-row v-for="(comp, index) in technicianComparisonData" :key="index + `_comp_obj`">
                                        <b-col cols="12" :class="'d-block d-sm-block d-md-none d-lg-none d-xl-none ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                            <b-row >
                                                <b-col>
                                                    <b>Field:</b> <code>{{comp.label}}</code>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-input-group 
                                                        :size="'sm'"
                                                        prepend="Current"
                                                    >
                                                        <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                                        <b-input-group-append is-text>
                                                            <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                                <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                            </b-form-checkbox>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-input-group 
                                                        :size="'sm'"
                                                        prepend="New"
                                                    >
                                                        <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                                        <b-input-group-append is-text>
                                                            <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                                <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                            </b-form-checkbox>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-col>
                                            </b-row>
                                            <hr>
                                        </b-col>
                                        <b-col md="2" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                            <b>{{comp.label}}</b>
                                        </b-col>
                                        <b-col md="5" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')" >
                                            <b-input-group 
                                                :size="'sm'"
                                            >
                                                <b-form-input :class="(comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="Existing Data" :value="comp.existingDisplayData"></b-form-input>
                                                <b-input-group-append is-text>
                                                    <b-form-checkbox switch  :button-variant="(comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="false" :unchecked-value="true">
                                                        <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The Existing Data</span>
                                                    </b-form-checkbox>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-col>
                                        <b-col md="5" :class="'d-none d-sm-none d-md-block ' + ((comp.highlight) ? 'bg-vai-warning-main' : '')">
                                            <b-input-group 
                                                :size="'sm'"
                                            >
                                                <b-form-input :class="(!comp.useNew) ? 'bg-vai-secondary-light' : 'bg-vai-success-relaxed'" readonly aria-label="New Data" :value="comp.newDisplayData"></b-form-input>
                                                <b-input-group-append is-text>
                                                    <b-form-checkbox switch  :button-variant="(!comp.useNew) ? 'vai-secondary-light' : 'vai-success-relaxed'" class="mr-n2 mb-n1" v-model="comp.useNew" :value="true" :unchecked-value="false">
                                                        <span class="sr-only">Switch, When Checked Will Tell The System To Utilize The New Incomming Data</span>
                                                    </b-form-checkbox>
                                                </b-input-group-append>
                                            </b-input-group>
                                            
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-else>
                                    <span>Creating New Technician</span>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-modal>
                    <b-modal
                        v-model="showRejectModal"
                        title="Reject Submission"
                        size="lg"
                        :ok-title="sendRejectionButtonTitle"
                        ok-variant="warning"
                        :ok-disabled="!canSendRejection"
                        @ok="rejectRecord"
                    >
                        <div class="d-flex flex-column flex-md-row">
                            <div class="p-2 d-flex justify-content-center justify-content-md-start">
                                <span class="display-1">😦</span>
                            </div>
                            <div class="p-2 flex-grow-1">
                                <b-row>
                                    <b-col cols="12">
                                        <span class="h5">Tell {{dataRep.sent_by_user_name}} Why You Are Rejecting This Submission</span>
                                    </b-col>
                                    <b-col cols="12" class="my-2">
                                        <b-form-select v-model="rejectionSelection" :options="rejectionReasonOptions"></b-form-select>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-textarea
                                            id="rejection-textarea"
                                            v-model="rejectionNotes"
                                            placeholder="Notes are always helpful..."
                                            rows="3"
                                            max-rows="6"
                                        ></b-form-textarea>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </b-overlay>
        </b-sidebar>
        <b-row>
            <b-col v-if="!fetchingList" cols="12" class="text-center h3">You Have {{items.length}} Portal Submissions Pending Approval</b-col>
            <b-col v-else cols="12" class="text-center h3">Fetching Pending Portal Submissions</b-col>
        </b-row>
        <b-table
            id="portal-pending"
            :busy="fetchingList"
            :items="items"
            :fields="fields"
            outlined
            stacked="sm"
        >
            <template #table-busy>
                <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
                </div>
            </template>
            
            <template #cell(actions)="data">
                <b-button-group size="sm">
                    <b-button class='material-icons' variant="vai-success-main" @click="openSidebar(data)">assignment</b-button>
                </b-button-group>
            </template>
        </b-table>
        <b-modal id="confirm-done" title="Successful">
            <div class="text-center">
                <i class="material-icons display-2 text-success">add_task</i>
            </div>
            <div class="text-center" v-if="selectedDeviceFetched">
                <div class="text-center">
                    <b-link target="_blank" :to="getFormLink(selectedDeviceID, 'devices')">View Device In New Tab</b-link>
                </div>
            </div>
            <div class="text-center" v-for="(row, idx) in acceptanceResults" :key="idx + '_acceptance_row'">
                <div class="text-center">
                    <b-link v-if="row.eventType == 'BFPA Test'" target="_blank" :to="`/home/test/${row.eventID}`">View Test In New Tab</b-link>
                    <b-link v-else-if="row.eventType == 'Survey'" target="_blank" :to="`/home/survey/${row.eventID}`">View Survey In New Tab</b-link>
                    <b-link v-else target="_blank" :to="`/home/replacement/${row.eventID}`">View {{row.eventType}} In New Tab</b-link>
                </div>
            </div>
        </b-modal>
        <b-modal id="confirm-reject" title="Submission Rejected">
            <div class="text-center">
                <i class="material-icons display-2 text-danger">info</i>
            </div>
        </b-modal>
    </div>
</template>

<script>

/* KNOWN ISSUES:
    - All PDF's show Hide when one pdf is open, PDF Object Element Needs To Reference A Unique div for each buffer row
    - Replacements Probably Wont Work Here At all, because there are multiple devices referenced in a replacement event, not handled well by haz match, dyn model match
    - No method of specifying manually when Hazard Match or Dynamic Model match fails
*/ 
import mapboxgl from "mapbox-gl";

const butils = require('../../libs/basicUtils.js');
const pdfObject = require('pdfobject');

const _ = butils.underscore;

import dynForm from '@/components/DynamicForm.vue';
import Image from '@/components/subcomponents/Image.vue';
import APISelect from '@/components/FormSelectFetchAPIInput.vue'

export default {
    name: 'PortalPendingList',
    components:{
        'dynForm' : dynForm,
        'hover-image-with-modal': Image,
        'api-select': APISelect,
    },
    props:{

    },
    data(){
        return{
            // Map Stuff
            map: null,
            marker: null,

            // State Management
            activeScreen: "Main",
            loading: true,
            vaiMessage: 'Loading',
            deviceSectionOpen: false,
            technicianSectionOpen: false,

            // Rejection
            showRejectModal: false,
            rejectionSelection: null,
            rejectionNotes: null,
            rejectionReasonOptions: [
                { value: null, text: "Please Select An Option", disabled: true},
                { value: "Outside Jurisdiction", text: "Outside Jurisdiction"},
                { value: "Improper Test", text: "Improper Test"},
                { value: "Improper Certification or Gauge", text: "Improper Certification or Gauge"},
                { value: "Cannot Identify", text: "Cannot Identify"},
                { value: "Note", text: "Something Else"},
            ],

            // Records Management
            recordCount: 0,
            fields: [
                { key: 'event_name', label: 'Event Type' }, 
                { key: 'sent_by_user_name', label: 'From' }, 
                { key: 'sent_on', label: 'On' }, 
                'actions'
                ],
            items: [],
            viewing: null,

            // Device Management
            deviceMatchSelectionOpen: false,
            deviceSearchOpen: false,
            showMatchedDeviceInline: false,
            selectedDeviceID: null,
            selectedDeviceFetched: false,
            termSearchDevice: null,
            deviceSearchResults: [],
            deviceSearchPending: false,
            deviceResultFields: ['match', 'actions'],

            // Technician Management
            selectedTechnicianFetched: false,
            techMatchSelectionOpen: false,
            techMatchConfirmationOpen: false,
            techSearchOpen: false,
            termSearchTech: null,
            techSearchResults: [],
            techSearchPending: false,
            createNewTech: false,
            selectedTechnician: null,

            // Hazard and Dynamic Model Match
            hazMatchProm: null,
            dynMatchProm: null,
            sidebarOpen: false,
            attachedMediaOpen: false,
            bfpaTestReportOpen: false,
            dynModelMatch: null,
            hazardMatch: null,
            newDeviceDynModelMatch: null,
            newDeviceHazardMatch: null,
            missingHazardTypes: [],
            
            // Change Management
            changesCompleted: false,
            showChangesModal: false,
            technicianMatches: [],
            technicianComparisonData: [],
            
            pdfExists: false,
            fetchingList: true,
            submitPending: false,
            submitCompleted: false,
            matches: [],
            
            data: [],
            formID: null,
            device: null,
            connection: null,
            site: null,

            comparisonData: [],
            allMedia: [],
            
            acceptanceResults: [],

            dataRep: null,
            hasRepOrInst: false,
            localFormID: null
        }
    },
    methods:{
        dynFieldTouched(dataName){
            var fieldValue = this.$store.getters.getFormValue({parentFormID: this.localFormID, dataName: dataName});
            if(dataName == "hazardTypeID"){
                var existing = this.requestHazardMatch(fieldValue)
                existing.then((result)=>{
                    this.hazardMatch = result;
                })
            }else if(dataName == "newHazardTypeID"){
                var existing = this.requestHazardMatch(fieldValue)
                existing.then((result)=>{
                    this.newDeviceHazardMatch = result;
                })
            }
        },
        openChangesModal(){
            this.showChangesModal = true;
        },
        openRejectModal(){
            this.showRejectModal = true;
        },
        loadMap(lat, lng){
            if(this.map == null){
                mapboxgl.accessToken = "pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ";
                
                this.map = new mapboxgl.Map({
                    container: "input-device-map",
                    style: "mapbox://styles/trichards140/ckc2i9mdl049y1im92gdmzjsm",
                    zoom: 16,
                });
                var center = [-80.720875, 28.332783];
                if(lat != null && lng != null){
                    center = [lng, lat]
                }
                this.centerMap(center[1], center[0]);

                this.map.on('load', ()=>{
                    this.map.resize();
                });
                const nav = new mapboxgl.NavigationControl();
                this.map.addControl(nav, "top-right");

            }else{
                this.map.on('load', ()=>{
                    this.map.resize();
                });
                this.map.resize();
            }

            if(this.marker != null){
                if(lat != null && lng != null){
                    this.marker.remove();
                    this.createMarker(lat, lng, true);
                    if(this.marker != null){
                        this.marker.remove();
                    }
                    var m = new mapboxgl.Marker()
                        .setDraggable(false)
                        .setLngLat([lng, lat])
                        .addTo(this.map);
                    this.marker = m;

                    if(true){
                        this.centerMap(lat, lng);
                    }

                    }else{
                        this.marker.remove();
                    }
            }else if(lat != null && lng != null){
                this.createMarker(lat, lng, true);
            }
        },
        centerMap(lat, lng){
            var tmpCenter = [lng, lat];
            this.map.jumpTo({
                center: tmpCenter
            });
            this.map.resize();
        },
        createMarker(lat, lng, centerMapOnMarker){
            if(this.marker != null){
                this.marker.remove();
            }
            var m = new mapboxgl.Marker()
                .setDraggable(false)
                .setLngLat([lng, lat])
                .addTo(this.map);
            this.marker = m;

            if(centerMapOnMarker){
                this.centerMap(lat, lng);
            }
            // Set Move Listener
            this.marker.on('dragend', ()=>{
                var mlatlng = this.marker.getLngLat();
                this.markerMoveListener(mlatlng.lat, mlatlng.lng);
            });
        },
        toggleOverlay(){
            this.submitPending = !this.submitPending;
        },
        openSidebar(data){
            this.dataRep = null;
            this.missingHazardTypes = [];
            this.hasRepOrInst = false;
            this.createNewTech = false;
            this.changesCompleted = false;
            this.termSearchTech = null;
            this.techSearchResults = [];
            this.vaiMessage = "Loading";
            this.activeScreen = "Main";
            this.map = null;
            this.marker = null;
            this.deviceSearchResults = [];
            this.loading = true;
            this.selectedDeviceID = null;
            this.allMedia = [];
            this.pdfExists = false;
            this.comparisonData = [];
            this.acceptanceResults = [];
            this.submitPending = false;
            this.submitCompleted = false;
            this.bfpaTestReportOpen = false;
            this.showMatchedDeviceInline = false;
            this.selectedDeviceFetched = false;
            this.viewing = data.item;
            this.sidebarOpen = true;
            this.techMatchSelectionOpen = false;
            this.techSearchOpen = false;
            this.deviceMatchSelectionOpen = false;
            this.deviceSearchOpen = false;
            this.hazMatchProm = null;
            this.dynMatchProm = null;
            this.dynModelMatch = null;
            this.hazardMatch = null;
            this.newDeviceDynModelMatch = null;
            this.newDeviceHazardMatch = null;
            this.selectedTechnicianFetched = false;
            this.init();
            this.termSearchDevice = null;
            this.deviceSearchPending = false;
            this.techSearchPending = false;
            this.showChangesModal = false;
            this.showRejectModal = false;
            this.rejectionSelection = null;
            this.rejectionNotes = null;
        },
        closeSidebar(){
            this.sidebarOpen = false;
            this.loading = true;
            if(this.map != null){
                this.map.remove();
                this.map = null;
                this.marker = null;
            }
        },
        rejectRecord(){
            // TODO: Send Rejection
            this.submitPending = true;
            // TODO: Verify That All The Required Fields Are Filled
            var obj = {
                setID: this.viewing.set_id,
                reason: this.rejectionSelection,
                note: this.rejectionNotes
            }
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/portal/reject/${this.viewing.set_id}`, obj)
            .then((response)=>{
                this.submitPending = false;

                this.submitCompleted = true;

                // Open Modal, Refresh List, Close Sidebar
                this.$bvModal.show('confirm-reject');
                this.loadPendingList();
                this.closeSidebar();
            })
            .catch((err)=>{
                this.submitPending = false;
                this.submitCompleted = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else if(butils.hasAPIResponseFormat()){
                    console.log(err.response.data.errors)
                    butils.createToast(this, "Portal Rejection Failed", `${err.response.data.errors.toString()}`, "danger", 300);
                }else{
                    console.log(err)
                    butils.createToast(this, "Portal Rejection Failed", "An Unexpected Error Has Occured, Contact Support", "danger", 600);
                }
            })
        },
        requestCreateTech(){
            this.createNewTech = true;
            this.selectedTechnicianFetched = false;
            this.technicianComparisonData = [];
            this.selectedTechnician = null;
            this.closeTech();
        },
        approveChanges(){
            this.changesCompleted = true;
        }, 
        selectAllCurrentDevice(){
            this.setAllDeviceUseNew(false)
        },
        selectAllNewDevice(){
            this.setAllDeviceUseNew(true)
        },
        setAllDeviceUseNew(value){
            this.comparisonData.forEach((comp)=>{
                comp.useNew = value;
            })
        },
        selectAllCurrentTech(){
            this.setAllTechUseNew(false)
        },
        selectAllNewTech(){
            this.setAllTechUseNew(true)
        },
        setAllTechUseNew(value){
            this.technicianComparisonData.forEach((comp)=>{
                comp.useNew = value;
            })
        },
        openDevice(){
            this.activeScreen = 'Device';
            this.mapSetup();
        },
        closeDevice(){
            this.activeScreen = 'Main';
        },
        openHazardMatch(){
            this.activeScreen = 'Hazard';
        },
        closeHazardMatch(){
            this.activeScreen = 'Main';
        },
        openNewHazardMatch(){
            this.activeScreen = 'New Hazard';
        },
        closeNewHazardMatch(){
            this.activeScreen = 'Main';
        },
        openTech(){
            this.activeScreen = 'Tech';
        },
        closeTech(){
            this.activeScreen = 'Main';
        },
        openChanges(){
            this.activeScreen = 'Changes';
        },
        closeChanges(){
            this.activeScreen = 'Main';
        },
        backOrExit(){
            if(this.activeScreen == 'Main'){
                this.closeSidebar();
            }else if(this.activeScreen == 'Device'){
                this.closeDevice();
            }else if(this.activeScreen == 'Changes'){
                this.closeChanges();
            }else if(this.activeScreen == 'Tech'){
                this.closeTech();
            }else{
                this.activeScreen = 'Main';
            }
        },
        toggleAttachedMedia(){
            this.attachedMediaOpen = !this.attachedMediaOpen;
        },
        toggleShowDeviceInline(){
            this.showMatchedDeviceInline = !this.showMatchedDeviceInline;
        },
        toggleViewTechnicianMatchSelection(){
            this.techMatchSelectionOpen = !this.techMatchSelectionOpen;
        },
        toggleViewTechnicianMatchConfirmation(){
            this.techMatchConfirmationOpen = !this.techMatchConfirmationOpen;
        },
        toggleViewTechnicianSearch(){
            this.techSearchOpen = !this.techSearchOpen;
        },
        searchTechnician(){
            this.techSearchPending = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/technicians/search?match=` + this.termSearchTech)
            .then((response)=>{
                var results = response.data.result;
                var displayableResults = [];
                results.forEach(result=>{
                    let contentOfInterest = null;
                    if(result.sim_max_is == "Name"){
                        contentOfInterest = result.tech_full_name;
                    }else if(result.sim_max_is == "Certification"){
                        contentOfInterest = "No Match Preview Available";
                    }else if(result.sim_max_is == "Phone"){
                        contentOfInterest = result.tech_phone.join(', ');
                    }else if(result.sim_max_is == "Email"){
                        contentOfInterest = result.tech_email.join(', ');
                    }else if(result.sim_max_is == "Company"){
                        contentOfInterest = result.tech_company;
                    }
                    contentOfInterest = contentOfInterest.replace(new RegExp(this.termSearchTech, "gi"), (match) => `<mark>${match}</mark>`);

                    var cont = {
                        grp:{
                            id: result.id,
                            status: null,
                            match_on: result.sim_max_is,
                            similarity: result.max_sim,
                            match_content: contentOfInterest,
                        },
                        ...result
                    }
                    if(result.disabled != false){
                        cont.grp.status = "Disabled"
                        cont._cellVariants = { status: 'danger' }
                    }
                    displayableResults.push(cont);

                })
                this.techSearchResults = displayableResults;
                this.techSearchPending = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else if(butils.isError500(err)){
                    console.log(err)
                    butils.createToast(this, `Failed To Search For Technician: '${this.termSearchTech}'`, err.response.data.errors[0], "danger", 30);
                }
                this.techSearchPending = false;
            })
        },
        searchDevice(){
            this.deviceSearchPending = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/search/device/${this.termSearchDevice}`)
            .then((response)=>{
                var results = response.data.result;
                var displayableResults = [];
                results.forEach(result=>{
                    let contentOfInterest = null;
                    if(result.sim_max_is == "Service Address"){
                        contentOfInterest = result.service_address_line_one;
                    }else if(result.sim_max_is == "Serial"){
                        contentOfInterest = result.device_serial;
                    }else if(result.sim_max_is == "Meter"){
                        contentOfInterest = result.meter_number;
                    }
                    contentOfInterest = contentOfInterest.replace(new RegExp(this.termSearchDevice, "gi"), (match) => `<mark>${match}</mark>`);

                    var cont = {
                        grp:{
                            id: result.device_id,
                            status: null,
                            match_on: result.sim_max_is,
                            similarity: result.max_sim,
                            match_content: contentOfInterest,
                        },
                        ...result
                    }
                    if(result.inactive != false){
                        cont.grp.status = "Inactive"
                        cont._cellVariants = { status: 'danger' }
                    }
                    if(result.disabled != false){
                        cont.grp.status = "Disabled"
                        cont._cellVariants = { status: 'danger' }
                    }
                    displayableResults.push(cont);

                })
                this.deviceSearchResults = displayableResults;
                this.deviceSearchPending = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed Durring Device Lookup Search", "Something went wrong while fetching the matches for your search term, reload the page. Make sure that the submission was not already processed by someone else.", "danger", 30);
                }
                this.deviceSearchPending = false;
            })
        },
        selectDeviceID(deviceID){
            this.selectedDeviceID = deviceID;
            this.fetchDeviceData(this.selectedDeviceID)
            .then((ignore)=>{
                this.closeDevice();
            })
        },
        init(){
            this.loading = true;
            this.vaiMessage = "Fetching Submission Data";
            this.fetchDataForCurrent()
            .then((handledElsewhere)=>{
                // Fetch Dynamic Model Match and Hazard Match
                this.dynMatchProm = this.runDynModelMatch();
                this.vaiMessage = "Searching Hazard Type And Device Model For Matches";
                this.hazMatchProm = this.runHazardMatch();
                // Grab the default device form
                var defaultDeviceForm = this.$store.getters.getAvailableFormsByInspectionType({inspecting: 'devices'});
                this.formID = defaultDeviceForm[0].id;
                // Fetch Device Match
                this.vaiMessage = "Checking Device Matches";
                this.fetchMatchesForCurrent()
                .then((handledElsewhere)=>{
                    if(this.matches.length > 0){
                        if(this.matches.length == 1){
                            this.deviceMatchSelectionOpen = false;
                            this.deviceSearchOpen = false;
                            // Since there is only one match, we set that as the selectedDeviceID
                            this.selectedDeviceID = this.matches[0].device_id;
                            // We fetch the device data
                            this.vaiMessage = "Fetching Matched Device Data";
                            this.fetchDeviceData(this.selectedDeviceID)
                            .then((handledElsewhere)=>{
                                this.loading = false;
                            })
                        }else{
                            // More than one match, offer list of matches to user, and then offer search
                            this.deviceMatchSelectionOpen = true;
                            this.deviceSearchOpen = true;
                            this.loading = false;
                        }
                    }else{
                        // User must search to find a matching device
                        this.deviceSearchOpen = true;
                        this.loading = false;
                    }
                })
                this.vaiMessage = "Checking Technician Matches";
                // Fetch Technician Match
                var tech = this.runTechMatch();
            })
        },
        mapSetup(){
            // Load Map
            try {
                this.loadMap(this.dataRep.event_json.data.event_parent_data.device.gps.latitude, this.dataRep.event_json.data.event_parent_data.device.gps.longitude)
            } catch (error) {
                console.log(error)
            }
        },
        runDynModelMatch(){
            var givenDeviceData = this.dataRep.event_json.data.event_parent_data.device;
            var existingDevicePayload = {
                mfg: givenDeviceData.manufacturer,
                type: givenDeviceData.type,
                model: givenDeviceData.model
            }
            var mdlMatchProms = [];
            if(this.hasRepOrInst && this.dataRep.event_name == "BFPA Replacement"){
                // It has both an old and a new device we need to handle
                var existing = this.requestDynModelMatch(existingDevicePayload)
                existing.then((result)=>{
                    this.dynModelMatch = result;
                })
                mdlMatchProms.push(existing);

                var newDeviceData = this.dataRep.event_json.data.event_parent_data.newDevice;
                var newDevicePayload = {
                    mfg: newDeviceData.manufacturer,
                    type: newDeviceData.type,
                    model: newDeviceData.model
                }
                var newMdl = this.requestDynModelMatch(newDevicePayload)
                newMdl.then((result)=>{
                    this.newDeviceDynModelMatch = result;
                })
                mdlMatchProms.push(newMdl);
            }else{
                // Only Existing Device
                var existing = this.requestDynModelMatch(existingDevicePayload)
                existing.then((result)=>{
                    this.dynModelMatch = result;
                })
                mdlMatchProms.push(existing);
            }

            var everybody = Promise.all(mdlMatchProms)

            return everybody;
        },
        requestDynModelMatch(modelMatcherPayload){
            var prom = new Promise((resolve, reject)=>{
                butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/atils/dynamic_model_match`, modelMatcherPayload)
                .then((response)=>{
                    resolve(response.data.result);
                })
                .catch((err)=>{
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    }else{
                        console.log(err)
                        butils.createToast(this, "Failed To Fetch Dynamic Model Match", "Something went wrong while fetching the dynamic model match, reload the page. Make sure that the submission was not already processed by someone else.", "danger", 30);
                    }
                    reject("An Error Occured")
                })
            })
            return prom;
        },
        runHazardMatch(){
            var givenDeviceData = this.dataRep.event_json.data.event_parent_data.device;

            var hazMatchProms = [];
            if(this.hasRepOrInst && this.dataRep.event_name == "BFPA Replacement"){
                // It has both an old and a new device we need to handle
                var existing = this.requestHazardMatch(givenDeviceData.hazard_type)
                existing.then((result)=>{
                    this.hazardMatch = result;
                })
                hazMatchProms.push(existing);

                var newDeviceData = this.dataRep.event_json.data.event_parent_data.newDevice;

                var newHaz = this.requestHazardMatch(newDeviceData.hazard_type)
                newHaz.then((result)=>{
                    this.newDeviceHazardMatch = result;
                })
                hazMatchProms.push(newHaz);
            }else{
                // Only Existing Device
                var existing = this.requestHazardMatch(givenDeviceData.hazard_type)
                existing.then((result)=>{
                    this.hazardMatch = result;
                })
                hazMatchProms.push(existing);
            }

            var everybody = Promise.all(hazMatchProms)

            return everybody;
        },
        requestHazardMatch(hazardType){
            var prom = new Promise((resolve, reject)=>{
                butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/choices/match/hazard_types?match=${hazardType}`)
                .then((response)=>{
                    resolve(response.data.result)
                })
                .catch((err)=>{
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    }else{
                        // console.log(err)
                        // butils.createToast(this, `Failed To Match Hazard Type: '${hazardType}'`, `We Couldn't Find A Match For This Hazard Type - You Can Add This Value As An Alias For The Appropriate Hazard Type, Or Ignore This Warning If You Don't Care To Associate '${hazardType}' With An Existing Hazard Type.`, "warning", 60);
                    }
                    resolve({
                        id: null,
                        value: "NO MATCH FOUND"
                    })
                })
            })
            return prom;
        },
        fetchDeviceData(deviceID){
            this.selectedDeviceFetched = false;
            var request = butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/devices/${deviceID}?dref=true`)
            .then((response)=>{
                this.device = response.data.result.records[0];
                // We need the dynamic model match and hazard match to be done, so we ensure that they are
                Promise.all([this.dynMatchProm, this.hazMatchProm])
                .then(()=>{
                    // Then we can setup the device data comparison
                    this.setupDeviceDataComparison(deviceID)
                })
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed To Fetch Portal Submission Detailed Data", "Something went wrong while fetching the detailed record, reload the page. Make sure that the submission was not already processed by someone else.", "danger", 30);
                }
            })
            return request;
        },
        setupDeviceDataComparison(inpDeviceID){
            this.comparisonData = [];
            // TODO: Fix this when replacements are allowed, this probably wont work
            var givenDeviceData = this.dataRep.event_json.data.event_parent_data.device;
            
            // Prep Conversion Data
            /*
                Conversion Data Object Format:
                {
                    recipientType: 'device' OR 'connection' OR 'site'
                    recipientID: parent_id,
                    label: STRING Label,
                    newData: newDataValue
                    newDisplayData: humanReadableNewValue,
                    existingData: existingDataValue,
                    existingDisplayData: humanReadableNewValue,
                    useNew: false,
                    isGPS: false,
                    highlight: false,
                }
            */
            // Device Size
            var comp = {
                recipientType: 'device',
                recipientID: inpDeviceID,
                label: "Size",
                newData: givenDeviceData.size,
                newDisplayData: givenDeviceData.size + '"',
                existingData: this.device.device_size,
                existingDisplayData: this.device.device_size + '"',
                useNew: false,
                isGPS: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.comparisonData.push(comp);
            // Device Model
            var comp = {
                recipientType: 'device',
                recipientID: inpDeviceID,
                label: "Model",
                newData: this.dynModelMatch.model_id_matched,
                newDisplayData: this.dynModelMatch.device_model_matched,
                existingData: this.device.device_model_id,
                existingDisplayData: this.device.device_model,
                useNew: false,
                isGPS: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.comparisonData.push(comp);
            // Serial
            var comp = {
                recipientType: 'device',
                recipientID: inpDeviceID,
                label: "Serial",
                newData: givenDeviceData.serial,
                existingData: this.device.device_serial,
                useNew: false,
                isGPS: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.comparisonData.push(comp);
            // GPS Lat & Lng
            var comp = {
                recipientType: 'device',
                recipientID: inpDeviceID,
                label: "Latitude",
                newData: givenDeviceData.gps.latitude,
                existingData: this.device.device_latitude,
                useNew: false,
                isGPS: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.comparisonData.push(comp);

            var comp = {
                recipientType: 'device',
                recipientID: inpDeviceID,
                label: "Longitude",
                newData: givenDeviceData.gps.longitude,
                existingData: this.device.device_longitude,
                useNew: false,
                isGPS: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.comparisonData.push(comp);

            // GPS Acc
            var comp = {
                recipientType: 'device',
                recipientID: inpDeviceID,
                label: "GPS Acc.",
                newData: givenDeviceData.gps.acc,
                existingData: this.device.device_gps_accuracy,
                useNew: false,
                isGPS: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            
            this.comparisonData.push(comp);

            // TODO: Automatic GPS Matching Update - When Conditions Are Met, Pull The Entire Set of GPS Data into The System

            // Hazard Type
            var comp = {
                recipientType: 'device',
                recipientID: inpDeviceID,
                label: "Hazard",
                newData: this.hazardMatch.id,
                newDisplayData: this.hazardMatch.value,
                existingData: this.device.hazard_type_id,
                existingDisplayData: this.device.hazard_type,
                useNew: false,
                isGPS: false,
                highlight: false,
            };

            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null && comp.newData == null){
                // Both Null, Dont Highlight
            }else if(comp.existingData == null || comp.newData == null){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.comparisonData.push(comp);

            this.selectedDeviceFetched = true;
        },
        fetchConnectionData(){
            
        },
        fetchSiteData(){

        },
        displayPDF(row){
            // if its open, just close it
            if(row.pdfOpen){
                row.pdfOpen = false;
            }else{
                // if its closed, embed the pdf, then open
                pdfObject.embed(row.pdfFileURL, `#pdf-viewer-${row.id}`);
                row.pdfOpen = true;
            }
        },
        fetchDataForCurrent(){
            var setID = this.viewing.set_id;
            var request = new Promise((resolve, reject)=>{
                butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/portal/set/${setID}`)
                .then((response)=>{
                    var tmp = response.data.result;
                    // this.data = response.data.result;

                    var allPDFPromises = [];
                    // Collect All Media Into One Array
                    this.allMedia = [];
                    tmp.forEach((row)=>{
                        var media = row.event_json.data.media;
                        row.pdfOpen = false;
                        // Check Each Buffer Row For A PDF
                        let pdf = _.get(row, ['event_json','data','media','bfpa_test_report','file_id']);
                        if(pdf != undefined){
                            var pdfFetch = butils.customInstance.responseType.blob().get(`${process.env.VUE_APP_FILE_API_BASE_URL}/${pdf}`)
                            .then((response)=>{
                                var fileURL = URL.createObjectURL(response.data);
                                row.hasPDF = true;
                                row.pdfFileURL = fileURL;
                            })
                            .catch((err)=>{
                                console.log(err)
                                butils.createToast(this, "Failed To Fetch BFPA Test Official Report PDF", "An error occured in the api when attempting to fetch PDF, if this problem persists, contact support.", "warn");
                                row.hasPDF = false;
                            })
                            allPDFPromises.push(pdfFetch);
                        }else{
                            row.hasPDF = false;
                        }
                        row.featuredMedia = {
                            title: null,
                            from: null,
                            id: null,
                            url: null
                        };
                        // Pack up the media that might be available here
                        // The order of these statements, is in reverse precedence for showing in the task card
                        if(_.has(media, 'hazard_photo')){
                            if(media.hazard_photo != null){
                                var addMe = {
                                    title: "Hazard Photo",
                                    from: row.event_json.event.event_name,
                                    id: media.hazard_photo,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.hazard_photo}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }

                        if(_.has(media, 'condition_photo')){
                            if(media.condition_photo != null){
                                var addMe = {
                                    title: "Condition Photo",
                                    from: row.event_json.event.event_name,
                                    id: media.condition_photo,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.condition_photo}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }

                        if(_.has(media, 'survey_photo')){
                            if(media.survey_photo != null){
                                var addMe = {
                                    title: "Survey Photo",
                                    from: row.event_json.event.event_name,
                                    id: media.survey_photo,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.survey_photo}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }
                        if(_.has(media, 'photo_of_bfpa')){
                            if(media.photo_of_bfpa != null){
                                var addMe = {
                                    title: "Photo of BFPA",
                                    from: row.event_json.event.event_name,
                                    id: media.photo_of_bfpa,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.photo_of_bfpa}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }
                        if(_.has(media, 'photo_of_installed_bfpa_before_fill_dirt')){
                            if(media.photo_of_installed_bfpa_before_fill_dirt != null){
                                var addMe = {
                                    title: "New Device Photo",
                                    from: row.event_json.event.event_name,
                                    id: media.photo_of_installed_bfpa_before_fill_dirt,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.photo_of_installed_bfpa_before_fill_dirt}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }
                        if(_.has(media, 'overall_photo_after')){
                            if(media.overall_photo_after != null){
                                var addMe = {
                                    title: "Post-Install",
                                    from: row.event_json.event.event_name,
                                    id: media.overall_photo_after,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.overall_photo_after}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }
                        if(_.has(media, 'close_up_photo_before')){
                            if(media.close_up_photo_before != null){
                                var addMe = {
                                    title: "Removed Device",
                                    from: row.event_json.event.event_name,
                                    id: media.close_up_photo_before,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.close_up_photo_before}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }
                        if(_.has(media, 'overal_photo_before')){
                            if(media.overal_photo_before != null){
                                var addMe = {
                                    title: "Pre-Install",
                                    from: row.event_json.event.event_name,
                                    id: media.overal_photo_before,
                                    url: `${process.env.VUE_APP_FILE_API_BASE_URL}/thumb_${media.overal_photo_before}`
                                }
                                row.featuredMedia = addMe;
                                this.allMedia.push(addMe);
                            }
                        }
                    })
                    Promise.all(allPDFPromises)
                    .then(()=>{
                        var repMatch = null;
                        tmp.forEach((row)=>{
                            if(row.event_name == "BFPA Install or Replacement"){
                                if(row.event_json.data.install_or_replacement == "Replacement"){
                                    row.event_name = "BFPA Replacement";
                                }else{
                                    row.event_name = "BFPA Install"
                                    // TODO: Fix this temporary work-arround when we begin allowing BFPA Installs Sent Via Instant Portal
                                    row.event_json.data.event_parent_data.device = row.event_json.data.event_parent_data.newDevice;
                                }
                                repMatch = row;
                                this.hasRepOrInst = true;
                            }else if(row.event_name == "BFPA Test"){
                                row.event_json.data.testOf = null;
                            }
                        })
                        if(this.hasRepOrInst){
                            // Determine What This Test Is Attached To Without Fancy Lookup
                            tmp.forEach((row)=>{
                                if(row.event_name == "BFPA Test"){
                                    if(repMatch.event_name == "BFPA Install"){
                                        if(`${row.event_json.data.event_parent_data.device.model}${row.event_json.data.event_parent_data.device.serial}` == `${repMatch.event_json.data.event_parent_data.newDevice.model}${repMatch.event_json.data.event_parent_data.newDevice.serial}`){
                                            row.event_json.data.testOf = "New Device"
                                        }
                                    }else{
                                        if(`${row.event_json.data.event_parent_data.device.model}${row.event_json.data.event_parent_data.device.serial}` == `${repMatch.event_json.data.event_parent_data.device.model}${repMatch.event_json.data.event_parent_data.device.serial}`){
                                            row.event_json.data.testOf = "Previous Device"
                                        }else if(`${row.event_json.data.event_parent_data.device.model}${row.event_json.data.event_parent_data.device.serial}` == `${repMatch.event_json.data.event_parent_data.newDevice.model}${repMatch.event_json.data.event_parent_data.newDevice.serial}`){
                                            row.event_json.data.testOf = "New Device"
                                        }
                                    }
                                }
                            })
                        }
                        var tmpSorted = _.sortBy(tmp, (itm)=>{
                            if(_.has(itm.event_json.data, "testOf")){
                                if(itm.event_json.data.testOf == null){
                                    return 5;
                                }else if(itm.event_json.data.testOf == "Previous Device"){
                                    return 2;
                                }else if(itm.event_json.data.testOf == "New Device"){
                                    return 4;
                                }
                            }else{
                                if(itm.event_name == "Survey"){
                                    return 1;
                                }else{
                                    return 2;
                                }
                            }
                        })
                        this.data = tmpSorted;
                        if(repMatch != null){
                            this.dataRep = repMatch;
                        }else{
                            this.dataRep = this.data[0];
                        }
                        resolve();
                    })
                })
                .catch((err)=>{
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    }else{
                        console.log(err)
                        butils.createToast(this, "Failed To Fetch Portal Submission Detailed Data", "Something went wrong while fetching the detailed record, reload the page. Make sure that the submission was not already processed by someone else.", "danger", 30);
                    }
                    reject();
                })
            })
            return request;
        },
        fetchMatchesForCurrent(){
            console.log("Fetching Matches")
            var bufferID = this.viewing.buffer_id;
            var request = butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/portal/match/${bufferID}`)
            .then((response)=>{
                this.matches = response.data.result
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed To Fetch Matches", "Something went wrong while fetching the matches", "danger", 30);
                }
            })
            return request;
        },
        runTechMatch(){
            var dataTechnicianInfo = this.dataRep.event_json.data.technician_data;
            var techName = dataTechnicianInfo.tech_first_name + ' ' + dataTechnicianInfo.tech_last_name;
            var request = butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/technicians/search?match=` + techName)
            .then((response)=>{
                this.technicianMatches = response.data.result;
                if(this.technicianMatches.length == 1){
                    this.selectedTechnician = this.technicianMatches[0];
                    this.setTechnician(this.technicianMatches[0]);
                }else if(this.technicianMatches.length > 1){
                    this.techMatchSelectionOpen = true;
                }else{
                    // Must be Zero, Open The Search
                    this.techSearchOpen = true;
                }
                
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else if(butils.isError500(err)){
                    console.log(err)
                    butils.createToast(this, `Failed To Fetch Match For Technician: '${techName}'`, err.response.data.errors[0], "danger", 30);
                }
            })
            return request;
        },
        setTechnician(technician){
            this.selectedTechnician = technician;
            this.createNewTech = false;
            this.setupTechnicianComparisonData(this.selectedTechnician);
        },
        setTechnicianFromSearch(technician){
            this.selectedTechnician = technician;
            this.createNewTech = false;
            this.setupTechnicianComparisonData(this.selectedTechnician);
            this.closeTech();
        },
        setupTechnicianComparisonData(techRow){
            var dataTechnicianInfo = this.dataRep.event_json.data.technician_data;
            this.technicianComparisonData = [];
            /*
                Conversion Data Object Format:
                {
                    recipientID: parent_id,
                    label: STRING Label,
                    newData: newDataValue
                    newDisplayData: humanReadableNewValue,
                    existingData: existingDataValue,
                    existingDisplayData: humanReadableNewValue,
                    useNew: false,
                    highlight: false,
                }
            */
            // this.technicianComparisonData = 
            // Company
            var comp = {
                recipientID: techRow.id,
                label: "Company",
                newData: dataTechnicianInfo.tech_company,
                existingData: techRow.tech_company,
                useNew: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.technicianComparisonData.push(comp);

            // Certification Num
            var comp = {
                recipientID: techRow.id,
                label: "Cert Num",
                newData: dataTechnicianInfo.certifications.treeo.num,
                existingData: techRow.certifications.treeo.num,
                useNew: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.technicianComparisonData.push(comp);

            // Certification Date
            var comp = {
                recipientID: techRow.id,
                label: "Cert Date",
                newData: dataTechnicianInfo.certifications.treeo.date,
                existingData: techRow.certifications.treeo.date,
                useNew: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.technicianComparisonData.push(comp);

            // Test Kit MFG
            var comp = {
                recipientID: techRow.id,
                label: "Test Kit MFG",
                newData: dataTechnicianInfo.test_kit_manufacturer,
                existingData: techRow.test_kit_manufacturer,
                useNew: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.technicianComparisonData.push(comp);

            // Test Kit Model
            var comp = {
                recipientID: techRow.id,
                label: "Test Kit Model",
                newData: dataTechnicianInfo.test_kit_model,
                existingData: techRow.test_kit_model,
                useNew: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.technicianComparisonData.push(comp);

            // Test Kit Serial
            var comp = {
                recipientID: techRow.id,
                label: "Test Kit Serial",
                newData: dataTechnicianInfo.test_kit_serial,
                existingData: techRow.test_kit_serial,
                useNew: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.technicianComparisonData.push(comp);

            // Test Kit Cal Date
            var comp = {
                recipientID: techRow.id,
                label: "Test Kit Cal",
                newData: dataTechnicianInfo.test_kit_cal_date,
                existingData: butils.formatters.timestampToDateTime(techRow.test_kit_cal_date),
                useNew: false,
                highlight: false,
            };
            if(!_.has(comp, 'newDisplayData')){
                comp.newDisplayData = comp.newData;
            }
            if(!_.has(comp, 'existingDisplayData')){
                comp.existingDisplayData = comp.existingData;
            }
            if((comp.existingData == '' || comp.existingData == null) && (comp.newData != '' && comp.newData != null)){
                comp.useNew = true;
            }
            if(comp.existingData != null && comp.newData != null){
                if(comp.existingData.localeCompare(comp.newData, 'en', { sensitivity: 'base' }) != 0){
                    comp.highlight = true;
                }
            }else if(comp.existingData == null || comp.newData == null && !(comp.existingData == null && comp.newData == null)){
                // One or the other is null, but both are not null
                comp.highlight = true;
            }
            this.technicianComparisonData.push(comp);

            this.selectedTechnicianFetched = true;

        },
        getFormLink(inspectID, isType){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: isType});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + inspectID
            }
        },
        acceptAndSubmit(){
            this.submitPending = true;
            // TODO: Verify That All The Required Fields Are Filled
            var obj = {
                setID: this.viewing.set_id,
                deviceID: this.selectedDeviceID,
                deviceChanges: _.filter(this.comparisonData, (comp)=>{ return comp.useNew == true; }),
                technicianID: this.selectedTechnician.id,
                technicianChanges: _.filter(this.technicianComparisonData, (comp)=>{ return comp.useNew == true; }),
            }
            if(this.hazardMatch != null && this.hazardMatch.id != null){
                obj.hazardTypeID = this.hazardMatch.id;
            }else if(this.newDeviceHazardMatch != null && this.newDeviceHazardMatch.id != null){
                obj.newDeviceHazardTypeID = this.newDeviceHazardMatch.id;
            }
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/portal/accept/${this.viewing.set_id}`, obj)
            .then((response)=>{
                this.submitPending = false;
                
                // Set Result Data
                this.acceptanceResults = response.data.result;

                this.submitCompleted = true;

                // Open Modal, Refresh List, Close Sidebar
                this.$bvModal.show('confirm-done');
                this.loadPendingList();
                this.closeSidebar();
            })
            .catch((err)=>{
                this.submitPending = false;
                this.submitCompleted = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else if(butils.hasAPIResponseFormat()){
                    console.log(err.response.data.errors)
                    butils.createToast(this, "Portal Acceptance Failed", `${err.response.data.errors.toString()}`, "danger", 300);
                }else{
                    console.log(err)
                    butils.createToast(this, "Portal Acceptance Failed", "An Unexpected Error Has Occured, Contact Support", "danger", 600);
                }
            })
        },
        loadPendingList(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/portal/list`)
            .then((response)=>{
                response.data.result.forEach(elm => {
                    elm.sent_on = butils.formatters.timestampToDateTime(elm.sent_on, true, false);
                });
                this.items = response.data.result;
                this.fetchingList = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed To Fetch Portal Submissions", "Something went wrong while fetching the list of records submitted to your portal", "danger", 30);
                }
            })
        }
    },
    watch:{
        activeScreen: function (currentScreen, previousScreen){
            if(currentScreen == 'Device'){
                setTimeout(()=>{
                    this.map.resize();
                }, 500)
            }
        }
    },
    computed:{
        showAcceptButton: function(){
            if(this.activeScreen == 'Main'){
                if( (this.selectedTechnicianFetched || this.createNewTech) && this.selectedDeviceFetched ){
                    return true;
                }
            }else{
                return false;
            }
        },
        canSendRejection: function(){
            if(this.rejectionSelection != null && this.rejectionNotes != null){
                return true;
            }else{
                return false;
            }
        },
        sendRejectionButtonTitle: function(){
            if(this.rejectionSelection == null){
                return "Reason Required";
            }else if(this.rejectionNotes == null){
                return "Notes Required";
            }else{
                return "Send Rejection";
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.localFormID = butils.uuid();
        this.$store.commit('initForm',{ localFormID: this.localFormID, meta: meta });
        this.loadPendingList();
        var meta = {
                meta: {
                    name: 'Portal Submission Processing',
                    title: 'Portal Submission Processing',
                    formParentKey: null
                },
                fields: {}
            };
    },
    mounted(){
        this.$watch('comparisonData', function () {
            // console.log('a thing changed')
        }, {deep:true})
        this.$watch('technicianComparisonData', function () {
            // console.log('a thing changed')
        }, {deep:true})
        this.$watch('data', function () {
            // console.log('data changed')
        }, {deep:true})
        this.$watch('data', function () {
            // console.log('data changed')
        }, {deep:true})
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    destroyed(){

    }
}
</script>

<style scoped>
    .pdf-viewer-collapse
    .pdf-viewer{
        height: 70vh;
    }
    @-webkit-keyframes pop-in {
        0% { opacity: 0; -webkit-transform: scale(0.5); }
        100% { opacity: 1; -webkit-transform: scale(1); }
    }
    @-moz-keyframes pop-in {
        0% { opacity: 0; -moz-transform: scale(0.5); }
        100% { opacity: 1; -moz-transform: scale(1); }
    }
    @keyframes pop-in {
        0% { opacity: 0; transform: scale(0.5); }
        100% { opacity: 1; transform: scale(1); }
    }
    td.table-no-styles{
        border-style: none;
    }
    span.giant-emoji{
        font-size: 7.5rem;
    }
    #input-device-map{
        min-height:20vh;
    }
    /* img.featured-media{
        max-height: 20vh;
        width: auto;
    } */
</style>