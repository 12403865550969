<template>
    <div>
        <b-row>
            <b-col sm="4" lg="3" class="">
                <div class="d-flex flex-column">
                    <b-button class="vai-flat-btn" block variant="primary" @click="expandAttachments">
                        {{(isCollapsed) ? 'Show' : 'Hide'}} Attachments
                        <b-badge pill variant="light">{{attachments.length}} <span class="sr-only">attachments</span></b-badge>
                    </b-button>
                    <div v-if="!isCollapsed" class="py-1">
                        <upload-file-drop
                            title="Attachment"
                            :acceptsTypes="'*/*'"
                            :collapseOnUpload="true"
                            :useEmit="false"
                            :uploadCallback="attachmentUploaded"
                            :includeFileAPIResponse="true"
                            :allowReset="true"
                        >
                        </upload-file-drop>
                    </div>
                </div>
                <b-row no-gutters>
                    <b-col sm="12" md="6" lg="12" class="mt-2">
                        
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="8" lg="9">
                <div v-if="!isCollapsed" class="d-flex flex-column">
                    <b-input-group size="sm">
                        <b-form-input id="input" placeholder="Search Attachments" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>                    
                            <b-button 
                                text="Button" 
                                variant="success"
                                @click="performSearch"
                            >
                                <b-icon-search></b-icon-search>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-table
                        striped
                        :items="searchResults"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        stacked="md"
                        small
                    >
                        <template #cell(score)="data">
                            {{(data.item.score * 100).toFixed(1)}}%
                        </template>
                        <template #cell(actions)="data">
                            <b-button-toolbar>
                                <b-button-group>
                                    <b-button
                                        title="Download Attachment"
                                        class="vai-flat-btn px-2"
                                        size="sm"
                                        variant="info"
                                        @click="download(data.item)"
                                    >
                                        <b-icon icon="download" font-scale="1" scale="1"></b-icon>
                                    </b-button>
                                    <b-button
                                        title="Remove Attachment"
                                        class="vai-flat-btn px-2"
                                        size="sm"
                                        variant="danger"
                                        @click="removeFromTableFields(data.item)"
                                    >
                                        <b-icon icon="exclamation-triangle" font-scale="1" scale="1"></b-icon>
                                    </b-button>
                                </b-button-group>
                            </b-button-toolbar>
                            
                            <!-- Added a remove button to remove Attachment rows and prompts the user if action is valid or not  -->
                               
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>
        <b-modal
            id="ask-for-attachment-type"
            title="Attachment Info"
            v-model="showAttachmentInfoModal"
            @ok="startAttachmentUpload"
            :ok-disabled="!selectedAttachmentType || !attachmentFileName"
        >
            <h4>Attachment Type</h4>
            <b-input-group v-if="!creatingNewAttachmentType">
                <b-form-select
                    v-model="selectedAttachmentType"
                    :options="attachmentTypes"
                    aria-describedby="attachment-type-required"
                ></b-form-select>
                <template #append>
                    <b-button
                        @click="creatingNewAttachmentType = true"
                        variant="success"
                    >
                        <b-icon-plus-circle-fill></b-icon-plus-circle-fill> Create New
                    </b-button>
                </template>
            </b-input-group>
            <div v-if="creatingNewAttachmentType" class="p-2 mb-2 bg-vai-gray-200 shadow">
                <div class="fs-1-2-5 mt-1">Create New Attachment Type</div>
                <label for="new-attachment-type-name" class="mt-3">Attachment Type Name:</label>
                <b-form-input
                    id="new-attachment-type-name"
                    v-model="newAttachmentTypeName"
                    :state="checkNewAttachmentTypeUnique"
                    placeholder="Attachment Type Name"
                    aria-describedby="new-attachment-type-must-be-unique"
                ></b-form-input>
                <b-form-invalid-feedback 
                    id="new-attachment-type-must-be-unique"
                >
                    New Attachment Type Name Must Be Unique
                </b-form-invalid-feedback>
                <label for="new-attachment-type-desc" class="mt-2">Attachment Type Description:</label>
                <b-form-textarea
                    id="new-attachment-type-desc"
                    rows="2"
                    max-rows="6"
                    v-model="newAttachmentTypeDesc"
                    placeholder="Attachment Type Description (Optional)"
                ></b-form-textarea>
                <div class="my-1 mt-2">
                    <div class="d-flex flex-row justify-content-end">
                        <b-button
                            class="mr-2"
                            variant="warning"
                            @click="creatingNewAttachmentType = false"
                        > Cancel
                        </b-button>
                        <b-button
                            class="mr-1"
                            variant="success"
                            :disabled="checkNewAttachmentTypeUnique != null || newAttachmentTypeName == null || newAttachmentTypeName == ''"
                            @click="saveNewAttachmentType"
                        > Create New Attachment Type
                        </b-button>
                    </div>
                </div>
            </div>
            
            
            <h4 class="mt-3">File Name</h4>
            <b-input-group :append="`.${attachmentFileExt}`">
                <b-form-input
                    v-model="attachmentFileName"
                    aria-describedby="file-name-dot-warning file-name-required"
                ></b-form-input>
            </b-input-group>
            <b-form-text v-if="!!attachmentFileExt && attachmentFileName.includes('.')" 
                id="file-name-dot-warning" 
                text-variant="vai-danger-dark"
            >
                The file extension of {{`.${attachmentFileExt}`}} is already on the file.
            </b-form-text>
            <b-form-text v-if="!attachmentFileName" 
                id="file-name-required" 
                text-variant="danger"
            >
                File Name Is Required
            </b-form-text>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../libs/basicUtils.js');
const _ = butils.underscore;
var fileDownload = require('js-file-download');
import Fuse from 'fuse.js'

import uploadFile from '@/components/UploadFileDrop.vue';

export default {
    name: 'AttchmentList',
    components:{
        'upload-file-drop': uploadFile,
    },
    props:{
        parentID: String
    },
    data(){
        return{
            // Expansion Management
            isCollapsed: true,
            // Table Stuff
            sortBy: 'added_on',
            sortDesc: true,
            psSortBy: null,
            psSortDesc: true,
            waiting: false,
            rawAttachmentTypes: [],
            attachmentTypes: [],
            attachments: [],
            searchResults: [],
            fields: [
                {
                    key: 'attachment_type_name',
                    label:"Type",
                    sortable: true
                },
                {
                    key: 'note',
                    label:"Filename",
                    sortable: true
                },
                {
                    key: 'added_on',
                    label:"Added On",
                    sortable: true
                },
                {
                    key: 'added_by_name',
                    label:"Added by",
                    sortable: true
                },
                {
                    key: 'actions',
                    label:"Actions",
                    sortable: false
                },
            ],
            selectedAttachmentType: null,
            attachmentFileName: null,
            attachmentFileExt: null,
            fileID: null,
            showAttachmentInfoModal: false,
            creatingNewAttachmentType: false,
            newAttachmentTypeName: null,
            newAttachmentTypeDesc: '',
            searchTerm: null,
            fuse: null,
        }
    },
    methods:{
        expandAttachments(){
            this.isCollapsed = !this.isCollapsed;
        },
        performSearch(){
            if(this.searchTerm == null || this.searchTerm == ''){
                // Reset Attachment Table Content
                this.searchResults = this.attachments;
                this.fields = _.filter(this.fields, (fld)=>{ return fld.key != "score" });
                this.sortBy = this.psSortBy;
                this.sortDesc = this.psSortDesc;
            }else{
                if(_.find(this.fields, (fld)=>{ return fld.key == "score" }) == undefined){
                    this.fields.unshift({
                        key: 'score',
                        label: 'Search Score',
                        sortable: true
                    });
                    this.psSortBy = this.sortBy;
                    this.psSortDesc = this.sortDesc;
                    this.sortBy = "score";
                    this.sortDesc = true;
                }
                // A search term exits, peform the search
                var result = this.fuse.search(this.searchTerm);
                // This function will normalize a value
                function normalize(val, max, min){
                    return (val - min)/(max - min)
                }
                this.searchResults = result.map((row)=>{ 
                    return {
                        ...row.item,
                        score: normalize(row.score, 0, 1)
                    }
                })
            }
        },
        removeFromTableFields(row){
            const contentVNode = this.$createElement('div', { 
                domProps: {
                    innerHTML: `
                        The Following Attachment Will Be Deleted Forever<br><br>
                        <b>${row.note}</b><br><br>
                        Are You Sure?
                    ` 
                } 
            })
            this.$bvModal.msgBoxConfirm([contentVNode],
                {
                    title: "Confirm Deletion Of Attachment",
                    okVariant: "danger",
                    okTitle: "Yes, Delete Forever",
                    cancelTitle: "No, Cancel",
                    centered: true
                }
            )
            .then(value => {
                if(value){
                    butils.instance.delete(`${process.env.VUE_APP_API_BASE_URL}/atils/attachment/${row.id}`)
                    .then((response)=>{
                        this.attachments = _.filter(this.attachments, (atch)=>{ return atch.id != row.id })
                        this.performSearch();
                    })
                    .catch((err)=>{
                        console.log(err)
                        butils.createToast(this, "Failed To Remove Attachment")
                    })
                    
                }
            })
            .catch(err => {
                // An error occurred
            })
            
        },
        download(attachment){
            console.log(`User want to download ${attachment.id}`)
            butils.customInstance.timeoutAndBlob(120000).get(`${process.env.VUE_APP_FILE_API_BASE_URL}/${attachment.file_id}`)
            .then((response)=>{
                fileDownload(response.data, attachment.note);
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To Donwload Attachment Is Denied', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Downloading File', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        attachmentUploaded(fileID, fileAPIResponse){
            this.fileID = fileID;
            this.attachmentFileName = fileAPIResponse.uploadInfo.uploadFileName.split('.').slice(0, -1).join('.');
            this.attachmentFileExt = fileAPIResponse.original.link.split('.').slice(-1).join('.');
            this.showAttachmentInfoModal = true;
        },
        startAttachmentUpload(){
            var packed = {
                parent_id: this.parentID,
                file_id: this.fileID,
                attachment_type: this.selectedAttachmentType,
                note: `${this.attachmentFileName}${(!!this.attachmentFileExt ? `.${this.attachmentFileExt}` : '')}`
            }
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/atils/attachment`, packed)
            .then((response)=>{
                this.getAttachmentList();
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To Upload Attachment Is Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Uploading Attachment', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        getAttachmentTypes(){
            this.attachmentTypes = [];
            this.waiting = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/lists/attachment_types`)
            .then((response)=>{
                this.waiting = false;
                var raw = response.data.result; // Array of attachment types
                this.rawAttachmentTypes = raw;
                raw.forEach(element => {
                   this.attachmentTypes.push({ text: `${element.name} (${element.note})`, value: element.id})
                });
            }) 
            .catch((err)=>{
                this.waiting = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To List Attachment Types Is Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Listing Attachment Types', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        saveNewAttachmentType(){
            this.waiting = true;
            var packed = {
                name: this.newAttachmentTypeName,
                note: this.newAttachmentTypeDesc
            }
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/atils/attachment_types`, packed)
            .then((response)=>{
                this.selectedAttachmentType = response.data.result.attacment_type_id;
                this.getAttachmentTypes();
                this.creatingNewAttachmentType = false;
            }) 
            .catch((err)=>{
                this.waiting = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To Create Attachment Types Is Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Creating Attachment Types', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        getAttachmentList(){
            this.waiting = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/atils/attachments/${this.parentID}`)
            .then((response)=>{
                this.waiting = false;
                var raw = response.data.result; // Array of attachments
                raw.forEach((row)=>{
                    row.added_on = butils.formatters.timestampToDateTime(row.added_on, true, false);
                })
                this.attachments = raw;
                // Setup Fuse.js Search
                this.fuse = new Fuse(this.attachments, { includeScore: true, keys: [ 'note', 'attachment_type_name', 'added_on', 'added_by_name']})
                this.searchResults = raw;
            }) 
            .catch((err)=>{
                this.waiting = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To List Attachments Is Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Listing Attachments', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        }
    },
    watch:{
        searchTerm: function(newVal, oldVal){
            this.performSearch(newVal);
        }
    },
    computed:{
        checkNewAttachmentTypeUnique: function(){
            var match = _.find(this.rawAttachmentTypes, (at)=>{ return at.name.localeCompare(this.newAttachmentTypeName, 'en', { sensitivity: 'base' }) == 0 })
            if(match == undefined){
                return null;
            }else{
                return false;
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.getAttachmentTypes();
        this.getAttachmentList();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>

</style>