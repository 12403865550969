<template>
<b-button-toolbar justify>
    <b-button variant="warning" v-if="backOnDiscard" @click="runOnDiscard(); $router.go(-1)">Discard Changes and Exit</b-button>
    <b-button variant="warning" v-else @click="runOnDiscard();">Discard Changes and Exit</b-button>
    <b-button variant="success" @click="sendForm">Save Changes</b-button>
</b-button-toolbar>  
    
</template>

<script>
export default {
    name: 'formsubmitdiscard',
    components:{
    },
    props:{
        //bus: Object,
        dataName: String,
        inputLabel: String,
        parentFormID: String,
        sendForm: Function,
        runOnDiscard: Function,
        validator: {
            type: RegExp,
            default: () => {return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}
        },
        value: {
            type: String,
            default: () => {return ""}
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        appendComponent: {
            type: String,
            default: () => {return null} 
        },
        prependComponent: {
            type: String,
            default: () => {return null} 
        },
        backOnDiscard:{
            type: Boolean,
            default: () => {return true}
        }
    },
    data(){
        return{
            inputValue: '',
            originalValue: '',
            isDirty: false,
        }
    },
    methods: {
    },
    computed: {
        
    },
    mounted(){
    },
    created(){
    },
    beforeDestroy(){
    },
    beforeMount (){
    },
}
</script>

<style>

</style>


