<template>
    <div class="mt-3 stage-inner-container-padded">
        <b-row>
            <b-col cols="12" md="6">
                <h2>{{dynGreet}} {{userFirstName}}</h2>
            </b-col>
            <b-col cols="12" md="6">
                <h3 class="d-sm-none d-none d-md-block text-right">{{cDate}}</h3>
                <h3 class="d-block d-sm-block d-md-none">{{cDate}}</h3>
            </b-col>
            <b-col cols="12">
                <hr class="mt-1">
            </b-col>

            <b-col cols="12 ">
                <div class="text-center">
                    <img src="@/assets/images/logo/vai_logo_mark.png" class="img-fluid rounded mx-auto d-block" alt="Responsive image" >
                    <h4>Cross Connection Control Automation and Simplification</h4>
                </div>
            </b-col>

            <!--
            <b-col lg="6" md="12" sm="12" class="mt-2" v-if="$store.getters.getAccountConfigPortalAvailable">
               
                <div @click="expandAnnualReport" :class="((annualReportOpen) ? 'bg-vai-gray-400': 'bg-vai-gray-200')">
                    <div class="mt-2 p-2 d-flex flex-row">
                        <div class="w-100 text-center align-self-center">
                            <div class="d-flex flex-column">
                                <div class="w-100 text-center font-weight-bold lead">
                                    Year To Date Report
                                </div>
                                <div class="w-100 text-center small">
                                    FDEP Year To Date Report - Available Anytime
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <b-collapse id="executive-dashboard">
                    <executive-dashboard></executive-dashboard>
                </b-collapse>
            </b-col>
            <b-col v-if="!$store.getters.getAccountConfigPortalAvailable" cols="12" class="d-flex justify-content-center py-5 bg-light">
                <b-button
                    size="lg"
                    variant="info"
                    class="rounded-pill"
                    href="/home/workorder"
                >
                    Enter Work Order
                </b-button>
            </b-col>
            <b-col xl="6" lg="12" md="12" class="mt-2" v-if="$store.getters.getAccountConfigPortalAvailable">
                <portal-pending></portal-pending>
            </b-col>
            <b-col xl="6" lg="12" md="12" class="mt-2" v-else>
                <portal-submissions></portal-submissions>
            </b-col>

-->
        </b-row>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

import PortalSubmissionList from '@/views/applets/PortalSubmissionList.vue'
import PortalPendingList from '@/views/applets/PortalPendingList.vue'
import WOEntryButton from '@/views/applets/WOEntry.vue'
import ExecutiveDash from '@/views/applets/ExecutiveDash.vue'

import ComplianceDash from '@/components/ComplianceDashboard.vue'
import AttachmentList from '@/components/AttachmentList.vue'

export default {
    name: 'dashboard',
    components:{
        'portal-pending': PortalPendingList,
        'portal-submissions': PortalSubmissionList,
        'wo-entry': WOEntryButton,
        'executive-dashboard': ExecutiveDash,
        'compliance-dashboard': ComplianceDash,
        'attachment-list': AttachmentList
    },
    props:{
    },
    data(){
        return{
            cDate: null,
            cTime: null,
            dynGreet: null,
            dashUpdater: null,
            uploadSessionID: uuidv4(),
            annualReportOpen: false,
            
            
        }
    },
    methods: {
        expandAnnualReport(){
            this.annualReportOpen = !this.annualReportOpen;
            this.$root.$emit('bv::toggle::collapse', "executive-dashboard")
        },
        toggleCardCollapse(collapseID){
            this.$root.$emit('bv::toggle::collapse', collapseID)
        },
        updateDateAndTime(){
            this.cDate = this.currentDate;
            this.cTime = this.currentTime;
            this.dynGreet = this.dynTimeGreeting;
        },
        navigateTo(path){
            this.$router.push({path: path})
        },
        logSelect(device){
            console.log(device);
        }
    },
    computed: {
        currentDate: function(){
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var strDate = month + '-' + day + '-' + year;
            return strDate;
        },
        currentTime: function(){
            var date = new Date();
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        },
        dynTimeGreeting: function(){
            var currentTime = new Date().getHours();
            //console.log(currentTime);
            var dynGreeting = "";
            if(currentTime >= 0 && currentTime < 12){
                //Morning
                dynGreeting = "Good Morning";
            }else if(currentTime >= 12 && currentTime < 20){
                //Afternoon
                dynGreeting = "Good Afternoon";
            }else if(currentTime >= 20 && currentTime <= 23){
                //Night
                dynGreeting = "Good Evening";
            }
            return dynGreeting;
        },
        userFirstName: function(){
            return this.$store.getters.firstName;
        }
    },
    mounted(){
        this.updateDateAndTime();
    },
    created(){
    },
    beforeDestroy(){
        clearInterval(this.dashUpdater);
    },
    beforeMount (){
        //console.log(this.value)
    },
}
</script>

<style lang="scss" scoped>

.hoverable:hover{
    background-color: $vai-success-lighter;
}
.hoverable{
    cursor:pointer;
}

</style>

